import React from "react";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import {
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../../../../redux/frontWebRating/actions";
import { useIdleTimer } from "react-idle-timer";
import { useIntl } from "react-intl";
import { saveDetails } from "../../../apiHelpers/saveDetails";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Box, Divider, Grid } from "@material-ui/core";

function EmployeDetail(props) {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["rating"]);

  const {
    webRatingAnsweres,
    setFrontratingAnsweres_,
    showAlphigaQ,
    handleYes,
    handleNo,
  } = props;

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();

      let ratingCookie = cookies.rating;
      if (
        ratingCookie?.location !== props.webRatingAnsweres.location_id ||
        localStorage.getItem("is_frequent_rating")
      ) {
        const response = await saveDetails({
          ...props.webRatingAnsweres,
          page: 2,
          dropout_page: "p2",
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );
          props.resetFrontratingAnsweres_();
          history.push("/thankYou");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error(intl.formatMessage({ id: "you-have-already-submitted-rating" }));
      }
    },

    debounce: 500,
  });

  return (
    <Box
      style={{ padding: "2rem 1rem 1rem 1rem" }}
      className={classes.boxContainer}
    >
      <Grid container className={classes.container}>
        <Grid item xs={10} className={classes.qTitle}>
          <p className="mb-0">
            {intl.formatMessage({ id: "did-you-buy-medicine-in-the-pharmacy-today" })}
          </p>
        </Grid>
        <Grid item xs={2} className={classes.actionContainer}>
          <Box
            className={classes.action}
            onClick={handleNo}
            style={{ background: showAlphigaQ === "No" && "red" }}
          >
            <h5
              className={`${classes.actionText}`}
              style={{ color: showAlphigaQ === "No" && "white" }}
            >
              {intl.formatMessage({ id: "no" })}
            </h5>
          </Box>
          <Box
            className={classes.action}
            onClick={handleYes}
            style={{ background: showAlphigaQ === "Yes" && "green" }}
          >
            <h5
              className={`${classes.actionText}`}
              style={{ color: showAlphigaQ === "Yes" && "white" }}
            >
              {intl.formatMessage({ id: "yes" })}
            </h5>
          </Box>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" className={classes.divider} />

      {showAlphigaQ === "Yes" ? (
        <>
          <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.qTitle}>
              <p className="mb-0">
                <strong>
                  {intl.formatMessage({ id: "were-you-asked-any-of-the-following" })}
                </strong>
              </p>
            </Grid>
          </Grid>

          <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.qTitle}>
              <p className="mb-0 ml-4">
                {intl.formatMessage({ id: "who-is-the-medicine-for" })}
              </p>
            </Grid>
            <Grid item xs={2} className={classes.actionContainer}>
              <Box
                className={`${
                  webRatingAnsweres?.medicine_for === "No"
                    ? classes.actionFalse
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({ key: "medicine_for", value: "No" });
                }}
              >
                <h5
                  style={{
                    color: webRatingAnsweres?.medicine_for === "No" && "white",
                  }}
                  className={classes.actionText}
                  onClick={() => {
                    setFrontratingAnsweres_({
                      key: "medicine_for",
                      value: "No",
                    });
                  }}
                >
                  {intl.formatMessage({ id: "no" })}
                </h5>
              </Box>
              <Box
                className={`${
                  webRatingAnsweres?.medicine_for === "Yes"
                    ? classes.actionTrue
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({
                    key: "medicine_for",
                    value: "Yes",
                  });
                }}
              >
                <h5
                  style={{
                    color: webRatingAnsweres?.medicine_for === "Yes" && "white",
                  }}
                  className={classes.actionText}
                >
                  {intl.formatMessage({ id: "yes" })}
                </h5>
              </Box>
            </Grid>
          </Grid>

          <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.qTitle}>
              <p className="mb-0 ml-4">
                {intl.formatMessage({ id: "what-are-your-symptoms" })}
              </p>
            </Grid>
            <Grid item xs={2} className={classes.actionContainer}>
              <Box
                className={`${
                  webRatingAnsweres?.symptoms === "No"
                    ? classes.actionFalse
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({ key: "symptoms", value: "No" });
                }}
              >
                <h5
                  style={{
                    color: webRatingAnsweres?.symptoms === "No" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "no" })}
                </h5>
              </Box>
              <Box
                className={`${
                  webRatingAnsweres?.symptoms === "Yes"
                    ? classes.actionTrue
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({ key: "symptoms", value: "Yes" });
                }}
              >
                <h5
                  style={{
                    color: webRatingAnsweres?.symptoms === "Yes" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "yes" })}
                </h5>
              </Box>
            </Grid>
          </Grid>

          <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.qTitle}>
              <p className="mb-0 ml-4">
                {intl.formatMessage({ id: "how-long-you-had-the-symptoms" })}
              </p>
            </Grid>
            <Grid item xs={2} className={classes.actionContainer}>
              <Box
                className={`${
                  webRatingAnsweres?.symptoms_duration === "No"
                    ? classes.actionFalse
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({
                    key: "symptoms_duration",
                    value: "No",
                  });
                }}
              >
                <h5
                  style={{
                    color:
                      webRatingAnsweres?.symptoms_duration === "No" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "no" })}
                </h5>
              </Box>
              <Box
                className={`${
                  webRatingAnsweres?.symptoms_duration === "Yes"
                    ? classes.actionTrue
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({
                    key: "symptoms_duration",
                    value: "Yes",
                  });
                }}
              >
                <h5
                  style={{
                    color:
                      webRatingAnsweres?.symptoms_duration === "Yes" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "yes" })}
                </h5>
              </Box>
            </Grid>
          </Grid>

          <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.qTitle}>
              <p className="mb-0 ml-4">
                {intl.formatMessage({ id: "what-action-you-have-already-taken" })}
              </p>
            </Grid>
            <Grid item xs={2} className={classes.actionContainer}>
              <Box
                className={`${
                  webRatingAnsweres?.action_taken === "No"
                    ? classes.actionFalse
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({
                    key: "action_taken",
                    value: "No",
                  });
                }}
              >
                <h5
                  style={{
                    color: webRatingAnsweres?.action_taken === "No" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "no" })}
                </h5>
              </Box>
              <Box
                className={`${
                  webRatingAnsweres?.action_taken === "Yes"
                    ? classes.actionTrue
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({
                    key: "action_taken",
                    value: "Yes",
                  });
                }}
              >
                <h5
                  style={{
                    color: webRatingAnsweres?.action_taken === "Yes" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "yes" })}
                </h5>
              </Box>
            </Grid>
          </Grid>

          <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.qTitle}>
              <p className="mb-0 ml-4">
                {intl.formatMessage({ id: "are-you-taking-any-other-medication" })}
              </p>
            </Grid>
            <Grid item xs={2} className={classes.actionContainer}>
              <Box
                className={`${
                  webRatingAnsweres?.other_medication === "No"
                    ? classes.actionFalse
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({
                    key: "other_medication",
                    value: "No",
                  });
                }}
              >
                <h5
                  style={{
                    color:
                      webRatingAnsweres?.other_medication === "No" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "no" })}
                </h5>
              </Box>
              <Box
                className={`${
                  webRatingAnsweres?.other_medication === "Yes"
                    ? classes.actionTrue
                    : ""
                } ${classes.action}`}
                onClick={() => {
                  setFrontratingAnsweres_({
                    key: "other_medication",
                    value: "Yes",
                  });
                }}
              >
                <h5
                  style={{
                    color:
                      webRatingAnsweres?.other_medication === "Yes" && "white",
                  }}
                  className={`${classes.actionText}`}
                >
                  {intl.formatMessage({ id: "yes" })}
                </h5>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeDetail);
