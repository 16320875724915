import React from "react";
import { TextField, useTheme } from "@material-ui/core";
import { useStyles } from "./styles";
import { connect } from "react-redux";
import {
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../../../../redux/frontWebRating/actions";
import { useIdleTimer } from "react-idle-timer";
import { saveDetails } from "../../../apiHelpers/saveDetails";
import { useHistory } from "react-router-dom";

import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const EmplyeService = (props) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["rating"]);

  const history = useHistory();
  const { webRatingData, webRatingAnsweres, setFrontratingAnsweres_ } = props;
  const selectedEmployee = webRatingData?.employee.find(
    (item) => item.id === webRatingAnsweres.employee_id
  );

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();

      let ratingCookie = cookies.rating;
      if (
        ratingCookie?.location !== webRatingAnsweres.location_id ||
        localStorage.getItem("is_frequent_rating")
      ) {
        const response = await saveDetails({
          ...webRatingAnsweres,
          page: 3,
          dropout_page: "p3",
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );
          props.resetFrontratingAnsweres_();
          history.push("/thankYou");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error(
          intl.formatMessage({ id: "you-have-already-submitted-rating" })
        );
      }
    },

    debounce: 500,
  });

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--my_color",
      theme.palette.primary.main
    );
  }, []);
  const handleStandout = (event) => {
    setChecked(event);
    setFrontratingAnsweres_({
      key: "is_standout",
      value: event,
    });
  };
  const handleFeedback = (event) => {
    setFrontratingAnsweres_({ key: "feedback", value: event.target.value });
  };
  return (
    <>
      <div className={classes.headingBox}>
        <h1 className={classes.heading}>
          {selectedEmployee
            ? intl.formatMessage(
                {
                  id: "did-employee-name-stand-out-for-delivering-exceptional-service",
                },
                { employeeName: selectedEmployee.name }
              )
            : intl.formatMessage({
                id: "did-we-stand-out-for-delivering-exceptional-service",
              })}
        </h1>
      </div>
      <div className={classes.employeContainer}>
        {selectedEmployee ? (
          <div className={classes.imageBox}>
            <img
              src={selectedEmployee?.image}
              alt="employee"
              style={{
                width: "187px",
                height: "187px",
                borderRadius: "5%",

                objectFit: "cover ",
              }}
            />
            <div className={classes.nameBox}>
              <p className={classes.nameText}>{selectedEmployee?.name}</p>
            </div>
          </div>
        ) : null}
        <div className={classes.serviceContainer}>
          <div
            style={{ all: "revert", width: "100%" }}
            className="mb-2 d-flex justify-content-center align-items-center"
          >
            <button
              type="button"
              style={{ height: "42px" }}
              onClick={() => handleStandout(true)}
              className={`${classes.buttonExceptional} ${classes.buttonYes} ${
                checked ? classes.buttonYesActive : ""
              }`}
            >
              {intl.formatMessage({ id: "yes" })}
            </button>
            <button
              type="button"
              style={{ height: "42px" }}
              onClick={() => handleStandout(false)}
              className={`${classes.buttonExceptional} ${classes.buttonNo} ${
                !checked ? classes.buttonNoActive : ""
              }`}
            >
              {intl.formatMessage({ id: "no" })}
            </button>
          </div>
          {/* <div
            className={classes.optionContainer}
            style={{
              marginBottom: "2%",
              justifyContent: !selectedEmployee && "center",
            }}
          >
            <label className={switchStyle.switch}>
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  handleStandout(e);
                  console.log(e.target.checked);
                }}
              />
              <span
                className={`${switchStyle.slider} ${switchStyle.round}`}
              ></span>
            </label>
          </div> */}
          <TextField
            id="outlined-multiline-static"
            placeholder={intl.formatMessage({
              id: "do-you-have-any-comments-or-feedback-about-your-experience-with-us-today",
            })}
            multiline
            style={{ width: "100%" }}
            rows={10}
            value={webRatingAnsweres.feedback}
            onChange={handleFeedback}
            variant="outlined"
            inputProps={{
              className: classes.textareaColor,
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmplyeService);
