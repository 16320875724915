import React, { useState } from "react";
import { useStyles } from "./styles";
import { Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { saveDetails } from "../../../apiHelpers/saveDetails";
import {
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../../../../redux/frontWebRating/actions";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

function EmployePersonalDetail(props) {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["rating"]);

  const {
    setFrontratingAnsweres_,
    webRatingAnsweres,
    webRatingData,
    resetFrontratingAnsweres_,
  } = props;

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();
      let ratingCookie = cookies.rating;
      if (
        ratingCookie?.location !== webRatingAnsweres.location_id ||
        localStorage.getItem("is_frequent_rating")
      ) {
        const response = await saveDetails({
          ...webRatingAnsweres,
          page: 2,
          dropout_page: "n2",
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );
          resetFrontratingAnsweres_();
          history.push("/thankYou");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error(
          intl.formatMessage({ id: "you-have-already-submitted-rating" })
        );
      }
    },

    debounce: 500,
  });

  const handleNameChange = (e) => {
    setFrontratingAnsweres_({ key: "customer_name", value: e.target.value });
  };
  const [Emailvalidation, setEmailvalidation] = useState(false);
  const handleEmailChange = (e) => {
    const regEx = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
    if (!regEx.test(e.target.value)) {
      setEmailvalidation(true);
    } else {
      setEmailvalidation(false);
    }
    setFrontratingAnsweres_({ key: "customer_email", value: e.target.value });
  };
  const handlePhoneChange = (e) => {
    setFrontratingAnsweres_({ key: "customer_phone", value: e.target.value });
  };
  const handleFeedbackChange = (e) => {
    setFrontratingAnsweres_({ key: "feedback", value: e.target.value });
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headingBox}>
          {!webRatingData.hideContact ? (
            <>
              <h1 className={classes.heading}>
                {intl.formatMessage({
                  id: "can-we-get-in-touch-with-you-about-your-experience-today",
                })}
              </h1>
              <p className={classes.subHeading}>
                {intl.formatMessage({
                  id: "leave-an-email-or-phone-number-if-you-want-us-to-get-in-touch-otherwise-just-leave-it-blank-then-press-next",
                })}
              </p>{" "}
            </>
          ) : (
            <h1 className={classes.heading}>
              {intl.formatMessage({ id: "anything-else-you-d-like-to-share" })}
            </h1>
          )}
        </div>
        <div className={classes.root}>
          <form noValidate autoComplete="off">
            {!webRatingData.hideContact ? (
              <>
                <TextField
                  className={classes.textfield}
                  id="outlined-basic"
                  placeholder={intl.formatMessage({ id: "name" })}
                  variant="outlined"
                  onChange={handleNameChange}
                  value={webRatingAnsweres.customer_name}
                  inputProps={{
                    className: classes.textfieldColor,
                  }}
                />

                <TextField
                  className={classes.textfield}
                  id="outlined-basic"
                  placeholder={intl.formatMessage({ id: "email" })}
                  variant="outlined"
                  type="email"
                  onChange={handleEmailChange}
                  value={webRatingAnsweres.customer_email}
                  inputProps={{
                    className: classes.textfieldColor,
                  }}
                />
                {Emailvalidation && (
                  <h6 style={{ color: "red", fontSize: "10px" }}>
                    {intl.formatMessage({ id: "enter-valid-email" })}
                  </h6>
                )}
                <TextField
                  className={classes.textfield}
                  id="outlined-basic"
                  placeholder={intl.formatMessage({ id: "phone-number" })}
                  variant="outlined"
                  type="number"
                  maxLength={10}
                  onChange={handlePhoneChange}
                  value={webRatingAnsweres.customer_phone}
                  inputProps={{
                    className: classes.textfieldColor,
                  }}
                />
              </>
            ) : null}
            <TextField
              id="outlined-multiline-static"
              placeholder={intl.formatMessage({ id: "message" })}
              multiline
              className={classes.textfield}
              rows={4}
              variant="outlined"
              onChange={handleFeedbackChange}
              value={webRatingAnsweres.feedback}
              inputProps={{
                className: classes.textfieldColor,
              }}
            />
          </form>
          <Typography className={classes.tc}>
            {intl.formatMessage({
              id: "by-signing-up-the-form-you-agree-to-our-terms-and-privacy",
            })}
          </Typography>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployePersonalDetail);
