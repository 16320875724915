import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useIntl } from "react-intl";
import EmojiImageHeaderFeedback from "./EmojiImageHeaderFeedback";
import CheckFrontLogin from "./CheckFrontLogin";
import Footer from "./Footer";
import axios from "axios";

function Negativefeedbackform(props) {
  const intl = useIntl();
  const history = useHistory();
  CheckFrontLogin();

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [feedback, setfeedback] = useState("");

  const [phoneError, setphoneError] = useState("");
  const [emailError, setemailError] = useState("");

  const submitNegativeFeedback = (e) => {
    e.preventDefault();

    setemailError("");
    setphoneError("");

    let isValidate = 1;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (email !== "" && !pattern.test(email)) {
      setemailError(intl.formatMessage({ id: "enter-valid-email-address" }));
      isValidate = 0;
    }

    if (phone !== "" && phone.length !== 10) {
      setphoneError(intl.formatMessage({ id: "phone-number-should-be-10-digit" }));
      isValidate = 0;
    }

    if (isValidate === 0) {
      return false;
    }

    let form_data = new FormData();
    form_data.append("company_id", localStorage.getItem("front_company_id"));
    form_data.append("location_id", localStorage.getItem("front_location_id"));
    form_data.append("skills", localStorage.getItem("selectedNegativeSkill"));
    form_data.append("rating", localStorage.getItem("rating"));
    form_data.append("employees", "");
    form_data.append("is_standout", 0);
    form_data.append("customer_name", name);
    form_data.append("customer_email", email);
    form_data.append("customer_phone", phone);
    form_data.append("feedback", feedback);

    const baseUrl = process.env.REACT_APP_BASE_URL;
    let url = "rating/create";
    
    axios
      .post(`${baseUrl}/${url}`, form_data,{headers: {'Content-Type'  : 'multipart/form-data'}})
      .then((res) => {
        if (res.status === 201) {
          history.push("/front/Ratting/thank-you-for-feedback");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectPreviousTab = () => {
    history.push("/front/Ratting/negative-feedback-step-1");
  };

  return (
    <React.Fragment>
      <div className="wrapper has-fix-bar">
        <form
          method="post"
          onSubmit={submitNegativeFeedback}
          autoComplete="off"
        >
          <section className="feedback-stepper-wrapper feedback-form">
            <div className="container">
              <div className="top-header-wrapper">
                <div className="experience-ans-tip">
                  {/* fetching rating from session  */}
                  <EmojiImageHeaderFeedback {...props} />
                </div>
                <div className="pagination-wrapper">
                  <span>2</span>/<span>2</span>
                </div>
              </div>
              <h1 className="h1-heading">{intl.formatMessage({ id: "can-we-get-in-touch-with-you" })}</h1>
              <input type="hidden" name="skill_id" defaultValue="null" />
              <div className="white-box contact-form-wrapper">
                <div className="form-group-row">
                  <div className="form-group half-width">
                    <input
                      type="text"
                      id="username"
                      name="name"
                      placeholder={intl.formatMessage({ id: "enter-your-name" })}
                      className="form-control form-control-web-ratting"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  <div className="form-group half-width">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder={intl.formatMessage({ id: "enter-your-email" })}
                      className="form-control form-control-web-ratting"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                    <span style={{ color: "red" }}>{emailError}</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      id="tel"
                      name="tel"
                      placeholder={intl.formatMessage({ id: "enter-your-telephone-number" })}
                      className="form-control form-control-web-ratting"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <span style={{ color: "red" }}>{phoneError}</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control sm form-control-web-ratting"
                      name="feedback"
                      id="feedback"
                      rows={4}
                      placeholder={intl.formatMessage({ id: "write-your-feedback" })}
                      value={feedback}
                      onChange={(e) => setfeedback(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <Link
                      style={{ color: "black", fontWeight: "bold" }}
                      to="https://www.elior.co.uk/privacy-policy"
                      target="_blank"
                      title={intl.formatMessage({ id: "privacy-policy" })}
                      className="font-bold text-gray-900"
                    >
                      {intl.formatMessage({ id: "privacy-policy" })}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="bottom-fix-btnbar">
                <button
                  onClick={() => redirectPreviousTab()}
                  className="btn border-btn icon-btn sm rating-feedback-prev-btn"
                  title={intl.formatMessage({ id: "prev" })}
                  style={{ border: "1px solid #955275", padding: "0px" }}
                >
                  <em>
                    <svg
                      id="_32213"
                      data-name={32213}
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.519"
                      height="13.19"
                      viewBox="0 0 7.519 13.19"
                    >
                      <path
                        id="Path_71"
                        data-name="Path 71"
                        d="M97.409,7.248l5.672,5.671a.924.924,0,0,0,1.306-1.306L99.369,6.595l5.019-5.018A.924.924,0,0,0,103.081.27L97.409,5.942a.924.924,0,0,0,0,1.306Z"
                        transform="translate(-97.139 0)"
                        fill="#955275"
                      />
                    </svg>
                  </em>
                  {intl.formatMessage({ id: "prev" })}
                </button>
                <div className="pagination-wrapper">
                  <span>2</span>/<span>2</span>
                </div>
                <button
                  className="btn icon-btn icon-right sm"
                  type="submit"
                  name="submit"
                  style={{
                    backgroundColor: "#955275",
                    color: "#fff",
                    border: "1px solid #955275",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    lineHeight: "12px",
                  }}
                >
                  {intl.formatMessage({ id: "complete" })}
                  <em>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.464"
                      height="12.154"
                      viewBox="0 0 12.464 12.154"
                    >
                      <path
                        id="Path_68"
                        data-name="Path 68"
                        d="M2591.784,1801.123a1.206,1.206,0,0,1-.879-.47l-.248-.289q-1.467-1.713-2.933-3.427a1.236,1.236,0,0,1-.3-1.081,1.206,1.206,0,0,1,.72-.824,1,1,0,0,1,.4-.081,1.253,1.253,0,0,1,.938.442q.783.919,1.57,1.835l.39.455c.094.109.175.2.312.2s.22-.1.314-.21l5.338-6.439q.342-.413.683-.828.375-.457.753-.913a1.667,1.667,0,0,1,.5-.44.668.668,0,0,1,.287-.086c.048,0,.146,0,.229.187v.339a3.4,3.4,0,0,1-.5.941c-.048.072-.1.144-.142.216q-3.181,4.954-6.368,9.905a1.124,1.124,0,0,1-1,.566Z"
                        transform="translate(-2587.4 -1788.971)"
                        fill="#fff"
                      />
                    </svg>
                  </em>
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Negativefeedbackform;
