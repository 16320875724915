// Importing necessary modules and components
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import ReactHTMLParser from "react-html-parser";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeUpdateContext } from "../../../App";
import {
  clearAnswersAndTags,
  clearContactDetails,
  clearSurvey,
  setIsSurveyWithoutLogin,
  setSurvey,
  setSurveyTheme,
  setGoogleRatingsDetails,
} from "../../../redux/survey/actions";
import { useQuery } from "../../../webRatingNew/FrontWebLogin";
import { postData } from "../../helpers/fetch_services";
import { useStyles } from "./LoginCss";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

// Main component function
function App(props) {
  const intl = useIntl();
  // Hooks for navigation and query parameters
  const history = useHistory();
  const query = useQuery();

  // Function to validate password input
  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = intl.formatMessage({ id: "please-enter-your-password" });
    } else if (value.length < 4) {
      error = intl.formatMessage({
        id: "value-must-be-longer-than-3-characters",
      });
    }
    return error;
  };

  // Function to validate email input
  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = intl.formatMessage({ id: "please-enter-your-email-address" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
      error = intl.formatMessage({ id: "invalid-email-address" });
    }
    return error;
  };

  // Context and Redux states
  const { changeTheme } = useContext(ThemeUpdateContext);
  const {
    setSurveyTheme_,
    setSurvey_,
    setIsSurveyWithoutLogin_,
    clearContactDetails_,
    clearAnswersAndTags_,
    clearSurveyWithoutLogin,
    setGoogleRatingsDetails_,
  } = props;

  // Local state declarations
  const [email] = useState("");
  const [password] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    intl.formatMessage({ id: "loading" })
  );
  const [noErrors, setNoErrors] = useState(true);

  const [backgroundImg, setBgImage] = useState("");
  const classes = useStyles({ backgroundImg });
  const [companyLogo, setCompanyLogo] = useState("");
  const [customErrorMessage, setCustomErrorMessage] = useState("");

  // Function to detect Safari or iPhone
  const isSafariOrIphone = () => {
    const userAgent = navigator.userAgent;
    return (
      (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) ||
      /iPhone/.test(userAgent)
    );
  };

  // Function to set branding details
  const setBrandDetails = async (company_id = null) => {
    const companyId = localStorage.getItem("company_id") ?? company_id;
    const tagId = localStorage.getItem("tag_id") ?? null;
    const surveyId = localStorage.getItem("survey") ?? null;
    const clientConnectId = localStorage.getItem("client_connect_id") ?? null;

    if (companyId || tagId || surveyId) {
      setLoading(true);

      const body = {};
      if (clientConnectId) {
        body.client_connect_id = clientConnectId;
      } else if (surveyId) {
        body.survey_id = surveyId;
      } else if (tagId) {
        body.tag_id = tagId;
      } else if (companyId) {
        body.company_id = companyId;
      }
      const result = await postData("api/getBrandDetail", body);

      if (result?.status === 200) {
        setBgImage(result?.data?.backgroundImg);

        setCompanyLogo(
          result?.data?.logo
            ? result?.data?.logo
            : "/images/webRating/serve-first.png"
        );
        localStorage.setItem("logo", result?.data?.logo);
        // setting theme in redux store
        setSurveyTheme_({
          primaryColor:
            result?.data?.primaryColor !== "undefined"
              ? result?.data?.primaryColor
              : "#36473F",
          secondaryColor:
            result?.data?.secondaryColor !== "undefined"
              ? result?.data?.secondaryColor
              : "#8FA280",
          backgroundColor:
            result?.data?.backgroundColor !== "undefined"
              ? result?.data?.backgroundColor
              : "#36473F",
          highlightColor:
            result?.data?.highightColor !== "undefined"
              ? result?.data?.highightColor
              : "#8FA280",
          fontColor:
            result?.data?.fontColor !== "undefined"
              ? result?.data?.fontColor
              : "#3a3a3a",
        });
        // setting current theme
        changeTheme({
          primaryColor:
            result?.data?.primaryColor !== "undefined"
              ? result?.data?.primaryColor
              : "#36473F",
          secondaryColor:
            result?.data?.secondaryColor !== "undefined"
              ? result?.data?.secondaryColor
              : "#8FA280",
          backgroundColor:
            result?.data?.backgroundColor !== "undefined"
              ? result?.data?.backgroundColor
              : "#36473F",
          highlightColor:
            result?.data?.highightColor !== "undefined"
              ? result?.data?.highightColor
              : "#8FA280",
          fontColor:
            result?.data?.fontColor !== "undefined"
              ? result?.data?.fontColor
              : "#3a3a3a",
        });

        setLoading(false);
      }
    }
  };
  const [loginLoading, setLoginLoading] = useState(false);
  // Function handling user login
  const onUserLogin = async ({ email = null, password = null }) => {
    if (email && password) {
      setLoginLoading(true);
      const companyId = localStorage.getItem("company_id");
      let body = {
        email,
        password,
      };
      if (companyId) {
        body["company_id"] = companyId;
        body["source"] = "frontsurvey";
      }
      const result = await postData("auth/login", body);

      if (result && result.success) {
        localStorage.setItem("jws_token", result.token);
        localStorage.setItem("user_id", result.user._id);
        localStorage.setItem("user_name", result.user.name);
        localStorage.setItem("user_email", result.user.email);

        const { company_id = null } = result?.user;
        if (company_id) localStorage.setItem("company_id", company_id);

        await setBrandDetails();
        clearAnswersAndTags_();
        clearContactDetails_();
        clearSurveyWithoutLogin();
        history.push("/survey/location");
      } else {
        toast.error(
          result?.message || intl.formatMessage({ id: "internal-server-error" })
        );
      }
      setLoginLoading(false);
    }
  };

  // Function to get survey details without login
  const getSurveyWithoutLogin = async ({
    location_id,
    survey_id,
    company_id,
    tag_id,
    audit,
    email,
  }) => {
    try {
      // Set loading state to true to indicate data fetching is in progress
      setLoading(true);
      // Fetch survey data from the server
      const result = await postData("frontEmployeeFeedback/getSurvey", {
        survey_id,
        audit,
        email,
        location_id,
      });

      if (result.success) {
        // If there's an error message in the response, set custom error message and stop loading
        if (result.error) {
          setCustomErrorMessage(result.message);
          setLoading(false);
          return;
        } else {
          setCustomErrorMessage("");
        }

        // Store location, audit, email, and survey details in local storage
        if (location_id) {
          localStorage.setItem("location", location_id);
        }
        if (audit) {
          localStorage.setItem("audit", audit);
          localStorage.setItem("email", email);
        }

        localStorage.setItem("survey", survey_id);

        // If there's a client connect ID in the response, store it in local storage
        if (result?.data?.clientConnect?._id) {
          localStorage.setItem(
            "client_connect_id",
            result?.data?.clientConnect?._id
          );
        }

        // Extract survey details from the response
        const survey = result.data;
        localStorage.setItem("company_id", survey?.company_id);
        await setBrandDetails(survey.company_id);
        if (
          !location_id &&
          !survey.is_global &&
          !survey.tag_id &&
          survey.location_id.length === 1 &&
          !audit
        ) {
          localStorage.setItem("location", ...survey.location_id);
        }
        if (
          !location_id &&
          !survey.is_global &&
          (survey.tag_id || survey.location_id.length > 1) &&
          !audit
        ) {
          setSurvey_(survey);
          setIsSurveyWithoutLogin_(true);
          setGoogleRatingsDetails_({
            locationGoogleReviewUrl: survey?.locationGoogleReviewUrl,
            locationGoogleQrImage: survey?.locationGoogleQrImage,
          });
          history.push({
            pathname: "/survey/location",
            state: { surveyWithoutLogin: true },
          });
        } else if (result?.data?.isClientConnect) {
          setSurvey_(survey);
          setGoogleRatingsDetails_({
            locationGoogleReviewUrl: survey?.locationGoogleReviewUrl,
            locationGoogleQrImage: survey?.locationGoogleQrImage,
          });
          history.push({
            pathname: "/survey/client_connect",
            state: { surveyWithoutLogin: true },
          });
        } else {
          setSurvey_(survey);
          setGoogleRatingsDetails_({
            locationGoogleReviewUrl: survey?.locationGoogleReviewUrl,
            locationGoogleQrImage: survey?.locationGoogleQrImage,
          });
          history.push({
            pathname: "/survey/welcome",
            state: { surveyWithoutLogin: true },
          });
        }
      } else {
        if (result?.message) {
          setNoErrors(false);
          setLoadingMessage(result.message);
        }
        if (result?.code === 500) {
          history.push("/sameDaySurvey");
        }
      }
    } catch (e) {
      console.log(e);
      history.push("/sameDaySurvey");
      setLoading(false);
    }
  };

  // Effect hook to initialize component states and fetch data
  useEffect(() => {
    // Clear contact details and local storage on component mount
    clearContactDetails_();
    localStorage.clear();
    // Fetch branding details
    setBrandDetails();

    // Extract query parameters from URL
    const company_id = query.get("company_id") ?? null;
    const tag_id = query.get("tag_id") ?? null;
    const location_id = query.get("location_id") ?? null;
    const survey_id = query.get("survey_id") ?? null;
    const audit = query.get("audit") ?? null;
    const email = query.get("email") ?? null;
    const creator_id = query.get("creator_id") ?? null;
    const source = query.get("source") ?? null;
    const callcenter = query.get("callcenter") ?? null;

    // Check if the user agent is Safari or iPhone
    if (isSafariOrIphone()) {
      // Add a style tag to the document's head
      const style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML = `
        input, select, textarea {
          font-size: 16px !important;
        }
      `;
      document.head.appendChild(style);
    }

    // remove if any existing location id in local storage
    if (!location_id) {
      localStorage.removeItem("location");
    }

    // Set company_id, tag_id, creator_id, and source in local storage
    if (company_id) {
      localStorage.setItem("company_id", company_id);
      localStorage.setItem("company_url", company_id);
    }

    if (tag_id) {
      localStorage.setItem("tag_id", tag_id);
    } else {
      localStorage.removeItem("tag_id");
    }

    if (creator_id) {
      localStorage.setItem("creator_id", creator_id);
    } else {
      localStorage.removeItem("creator_id");
    }

    if (source && ["qrcode", "web"]?.includes(source)) {
      localStorage.setItem("source", "qrcode");
    } else {
      localStorage.setItem("source", "weblink");
    }

    if (callcenter) {
      localStorage.setItem("callcenter", callcenter);
    } else {
      localStorage.removeItem("callcenter");
    }

    setBrandDetails();
    // Fetch survey details without login if survey_id exists
    if (survey_id) {
      getSurveyWithoutLogin({
        location_id,
        survey_id,
        company_id,
        tag_id,
        audit,
        email,
      });
    }
  }, []);

  return (
    <LoadingOverlay active={loading} spinner={noErrors} text={loadingMessage}>
      <div className={classes.container}>
        {loading ? (
          ""
        ) : (
          <>
            {customErrorMessage ? (
              <div
                className="card px-0 px-md-3 py-0 py-md-2"
                style={{ maxWidth: "80%", lineHeight: "2" }}
              >
                <div className="card-body" style={{ color: "black" }}>
                  <div style={{ fontSize: "1.5rem" }}>
                    {ReactHTMLParser(customErrorMessage)}
                  </div>
                </div>
              </div>
            ) : (
              <Formik
                initialValues={{
                  email,
                  password,
                }}
                onSubmit={onUserLogin}
              >
                {({ errors, touched }) => (
                  <Form className={classes.loginContainer}>
                    <div style={{ width: "100%" }}>
                      {!loading ? (
                        <img
                          src={
                            companyLogo
                              ? companyLogo
                              : "/images/survey/sf-high.png"
                          }
                          className={classes.logo}
                          alt="logo"
                          style={{ marginBottom: 40 }}
                        />
                      ) : null}
                      <div style={{ marginBottom: 20 }}>
                        <Field
                          className={classes.loginInput}
                          placeholder={intl.formatMessage({
                            id: "enter-your-email",
                          })}
                          name="email"
                          validate={validateEmail}
                        />
                        {errors.email && touched.email && (
                          <div
                            className="invalid-feedback d-block text-left"
                            style={{ fontSize: "1rem" }}
                          >
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div>
                        <Field
                          className={classes.loginInput}
                          placeholder={intl.formatMessage({ id: "password" })}
                          name="password"
                          validate={validatePassword}
                          type="password"
                        />
                        {errors.password && touched.password && (
                          <div
                            className="invalid-feedback d-block text-left"
                            style={{ fontSize: "1rem" }}
                          >
                            {errors.password}
                          </div>
                        )}
                      </div>
                      <div>
                        <button
                          className={classes.button}
                          disabled={loginLoading}
                        >
                          {intl.formatMessage({ id: "sign-in" })}
                          {loginLoading ? (
                            <Spinner
                              size="sm"
                              animation="border"
                              variant="light"
                              className="ms-2"
                            />
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </>
        )}
      </div>
    </LoadingOverlay>
  );
}

// Redux mapStateToProps and mapActionsToProps functions
const mapStateToProps = (state) => {
  return { survey_theme: state.survey.survey_theme };
};
const mapActionsToProps = (dispatch) => {
  return {
    setSurvey_: (data) => dispatch(setSurvey(data)),
    setSurveyTheme_: (data) => dispatch(setSurveyTheme(data)),
    setIsSurveyWithoutLogin_: (data) => dispatch(setIsSurveyWithoutLogin(data)),
    clearAnswersAndTags_: () => dispatch(clearAnswersAndTags()),
    clearSurveyWithoutLogin: () => dispatch(clearSurvey()),
    clearContactDetails_: () => dispatch(clearContactDetails()),
    setGoogleRatingsDetails_: (data) => dispatch(setGoogleRatingsDetails(data)),
  };
};

// Connecting Redux to the component
export default connect(mapStateToProps, mapActionsToProps)(App);
