import React, { useState } from "react";
import { connect } from "react-redux";
import { addAnswer } from "../../../../redux/survey/actions";
import { Box, Tooltip } from "@material-ui/core";
import emoji1 from "../../../../assets/images/web_rating/emoji_1.gif";
import emoji2 from "../../../../assets/images/web_rating/emoji_2.gif";
import emoji3 from "../../../../assets/images/web_rating/emoji_3.gif";
import emoji4 from "../../../../assets/images/web_rating/emoji_4.gif";
import emoji5 from "../../../../assets/images/web_rating/emoji_5.gif";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/styles";
import { useIntl } from "react-intl";
// Array of emoji image sources
const emojiImage = [emoji1, emoji2, emoji3, emoji4, emoji5];

const EmojiType = (props) => {
  const intl = useIntl();
  // Destructuring the properties passed as props
  const {
    headTonext,
    question,
    answeres,
    addAnswer_,
    survey_theme,
    onSend,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    clearAnsweres_,
    currentQuestionIndex,
    hideQuestion,
    allQuestions,
    finishSurvey,
  } = props;
  // Setting up the local state for the component
  const [checked, setChecked] = useState("");
  const [isFinishSurvey, setIsFinishSurvey] = useState(false);
  // State to control the tooltip
  const [openTooltip, setOpenTooltip] = useState(null);

  // Handler function for handling user input
  const handleChange = (
    ratingId,
    value,
    text,
    routeToIndex,
    hideIndex,
    finishedSurvey
  ) => {
    // Setting the checked rating and checkedText state
    setChecked(ratingId);
    if (surveyType === "tablet" && currentQuestionIndex === 0) {
      clearAnsweres_();
      // Calculating the percentage based on the value
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;
      // Filtering the questions based on the journey type
      if (percentage >= 70) {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    // Function to hide a question
    if (!finishedSurvey && hideIndex?.length) {
      hideQuestion(hideIndex);
    }
    setIsFinishSurvey(finishedSurvey);
    // Open the tooltip for the clicked emoji
    setOpenTooltip(ratingId);
    if (question?.required && !ratingId) {
      toast.error(intl.formatMessage({ id: "this-question-is-required" }));
      return;
    } else {
      addAnswer_({
        id: question._id,
        value: ratingId,
        type: "id",
      });
      // Navigate to the next question if the selected option has a route_to_index
      if (!finishedSurvey && routeToIndex) {
        //Function to navigate to the next question in the survey.
        headTonext(routeToIndex, question._id);
      }
      // Callback function invoked when an answer is submitted.
      onSend(text);
      // Finish the survey if the current question is the last question
      if (currentQuestionIndex + 1 >= allQuestions?.length) {
        finishSurvey(true, question);
      }
    }
  };
  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  // Skip the current question and finish the survey if it's the last question
  const onSkipQuestion = () => {
    onSend("");
    if (currentQuestionIndex + 1 >= allQuestions?.length) {
      finishSurvey(true, question);
    }
  };

  const CustomTooltip = withStyles({
    tooltip: {
      color: "white",
      backgroundColor: survey_theme?.primaryColor,
      fontSize: "0.8rem",
      top: "0.7rem",
    },
    arrow: {
      color: survey_theme?.primaryColor,
    },
  })(Tooltip);

  return (
    <>
      <div className="px-2 bg-white border-t border-gray-200">
        <div className="flex items-center justify-center space-x-3 h-18 px-2">
          {question?.options
            ?.sort((a, b) => a?.value - b?.value)
            ?.map((item, index) => {
              return (
                <>
                  <Box
                    key={index}
                    className="ratingItem"
                    style={{
                      width: "20%",
                      borderRadius: "50%",
                      border:
                        answeres[question._id]?.value === item._id ||
                        checked === item?._id
                          ? "2px solid gainsboro"
                          : "2px solid white",
                      margin: "0 auto",
                    }}
                  >
                    <CustomTooltip
                      title={item?.optionText || ""}
                      placement="top"
                      open={openTooltip === item._id}
                      onClose={() => handleTooltipClose()}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <img
                        alt="emoji"
                        src={emojiImage[item.value - 1]}
                        onClick={() => {
                          handleChange(
                            item._id,
                            item.value,
                            item.value - 1,
                            item?.route_to_index,
                            item?.hide_questions,
                            item?.finish_survey === true
                          );
                        }}
                        style={{ padding: "2px" }}
                        className="emoji"
                      />
                    </CustomTooltip>
                  </Box>
                </>
              );
            })}
          <div>
            {!question?.required ? (
              <button
                onClick={() => onSkipQuestion()}
                type="button"
                className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
              >
                {intl.formatMessage({ id: "skip" })}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
  };
};
// Connects the EmojiType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(EmojiType);
