import * as React from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import UserAvatar from "../../../assets/images/web_rating/user.png";
import { DatePicker, Input } from "rsuite";
import "./client-connect.css";
import { useIntl } from "react-intl";
import moment from "moment";

export default function ClientConnectDirector({
  value,
  onChange,
  leadershipUser,
  clientConnect,
  isVisitInformation,
  selectedTimeOut = null,
  setSelectedTimeOut,
  isSurveyCompetition = false,
  competitionInfoText,
  customValues,
  updateCustomValues,
  customValuesErrors,
}) {
  const intl = useIntl();
  const falbackPlaceholder = intl.formatMessage({
    id: "enter-value-here",
  });

  return (
    <Paper elevation={3} className="mt-2">
      <Card sx={{ maxWidth: 345 }}>
        {leadershipUser ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              {intl.formatMessage({ id: "message-from-leadership" })}
            </Typography>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  src={leadershipUser?.image}
                  variant="circular"
                  style={{ width: 50, height: 50 }}
                  onError={(e) => {
                    e.target.src = UserAvatar;
                  }}
                />
              }
              title={leadershipUser?.name}
              subheader={
                <>
                  <Typography variant="body2" color="textPrimary">
                    {leadershipUser?.companyName}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {leadershipUser?.email}
                  </Typography>
                </>
              }
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {intl.formatMessage({ id: "hi" })}, {clientConnect?.username}
              </Typography>
              {leadershipUser?.message ? (
                ReactHtmlParser(leadershipUser.message)
              ) : (
                <>
                  <Typography variant="body2">
                    {intl.formatMessage({ id: "message-from-leadership-text" })}
                  </Typography>
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: "client-connect-director-another-note",
                    })}
                  </Typography>
                </>
              )}
            </CardContent>
            <CardActions>
              <TextField
                minRows={2}
                multiline
                value={value}
                onChange={onChange}
                size="medium"
                placeholder={intl.formatMessage({ id: "your-message-here" })}
                variant="outlined"
              />
            </CardActions>
          </>
        ) : null}
        {isVisitInformation ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              {intl.formatMessage({ id: "visit-information" })}
            </Typography>
            <CardContent>
              <label>{intl.formatMessage({ id: "visit-time-out" })} *:</label>
              <DatePicker
                format="HH:mm"
                ranges={[]}
                value={selectedTimeOut}
                onChange={(time) => setSelectedTimeOut(time)}
                className="form-control p-0"
                block
                style={{
                  color: "darkgray",
                  fontSize: "14px",
                }}
                placement={
                  leadershipUser && isVisitInformation
                    ? "topStart"
                    : "bottomStart"
                }
              />
            </CardContent>
          </>
        ) : null}

        {/* Show Survey Competition Information */}
        {isSurveyCompetition ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              {intl.formatMessage({ id: "contact-information" })}
            </Typography>
            <CardContent>
              {competitionInfoText ? (
                <div
                  style={{ marginBottom: "15px" }}
                  className="competition-info"
                >
                  {ReactHtmlParser(competitionInfoText || "")}
                </div>
              ) : (
                <div style={{ marginBottom: "15px" }}>
                  {intl.formatMessage({
                    id: "client-connect-director-contact-information-text",
                  })}
                </div>
              )}

              {customValues?.fields?.map((item) => (
                <div className="mt-2" key={item.fieldKey}>
                  <h6 className="fw-normal">
                    {item.fieldName}
                    {item.isRequired ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : null}
                  </h6>
                  <div className="mt-1">
                    {item.fieldType === "date" ? (
                      <DatePicker
                        format="DD/MM/YYYY"
                        ranges={[]}
                        value={customValues?.[item.fieldKey]}
                        onChange={(value) =>
                          updateCustomValues(
                            moment(value).format("YYYY-MM-DD"),
                            item
                          )
                        }
                        className="form-control p-0"
                        block
                        style={{
                          color: "darkgray",
                          fontSize: "14px",
                        }}
                        placeholder={item.placeholder || falbackPlaceholder}
                      />
                    ) : item.fieldType === "time" ? (
                      <DatePicker
                        format="HH:mm"
                        ranges={[]}
                        value={customValues?.[item.fieldKey]}
                        onChange={(value) =>
                          updateCustomValues(
                            moment(value).format("HH:mm"),
                            item
                          )
                        }
                        className="form-control p-0"
                        block
                        style={{
                          color: "darkgray",
                          fontSize: "14px",
                        }}
                        placeholder={item.placeholder || falbackPlaceholder}
                      />
                    ) : item.fieldType === "datetime" ? (
                      <DatePicker
                        format="DD/MM/YYYY HH:mm"
                        ranges={[]}
                        value={customValues?.[item.fieldKey]}
                        onChange={(value) =>
                          updateCustomValues(
                            moment(value).format("YYYY-MM-DD HH:mm"),
                            item
                          )
                        }
                        className="form-control p-0"
                        block
                        style={{
                          color: "darkgray",
                          fontSize: "14px",
                        }}
                        placeholder={item.placeholder || falbackPlaceholder}
                      />
                    ) : item.fieldType === "number" ? (
                      <Input
                        type="number"
                        id="outlined-basic"
                        variant="outlined"
                        placeholder={item.placeholder || falbackPlaceholder}
                        value={customValues?.[item.fieldKey]}
                        onChange={(e) => updateCustomValues(e, item)}
                      />
                    ) : item.fieldType === "phone" ? (
                      <div className="mt-1 custom-input-box">
                        <span className="prefix">+44</span>
                        <Input
                          id="outlined-basic"
                          variant="outlined"
                          value={customValues?.[item.fieldKey]}
                          type="number"
                          onChange={(e) => updateCustomValues(e, item)}
                          className="contact-number pl-2"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          placeholder={item.placeholder || falbackPlaceholder}
                        />
                      </div>
                    ) : (
                      <Input
                        id="outlined-basic"
                        variant="outlined"
                        placeholder={item.placeholder || falbackPlaceholder}
                        value={customValues?.[item.fieldKey]}
                        onChange={(e) => {
                          updateCustomValues(e, item);
                        }}
                      />
                    )}
                  </div>
                  <div className="mt-1">
                    {customValuesErrors?.[item.fieldKey]?.error ? (
                      <p className="text-danger">
                        {customValuesErrors?.[item.fieldKey]?.message}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </CardContent>
          </>
        ) : (
          ""
        )}
      </Card>
    </Paper>
  );
}
