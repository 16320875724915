import { TextField, makeStyles } from "@material-ui/core";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import UserAvatar from "../../../assets/images/web_rating/user.png";
import { DatePicker, Input } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { useIntl } from "react-intl";
import "./style.css";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    borderBlockColor: theme.palette.primary.light,
  },
  input1: {
    borderColor: theme.palette.primary.light,
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

export default function ClientConnectDirector({
  value,
  onChange,
  leadershipUser,
  clientConnect,
  isVisitInformation,
  isSurveyCompetition = false,
  selectedTimeOut = null,
  setSelectedTimeOut,
  competitionInfoText,
  customValues,
  updateCustomValues,
  customValuesErrors,
}) {
  const intl = useIntl();
  const classes = useStyles();

  const falbackPlaceholder = intl.formatMessage({
    id: "enter-value-here",
  });

  return (
    <div className="pb-3">
      {leadershipUser ? (
        <>
          <div className="text-center h3 mb-5 pt-2">
            {intl.formatMessage({ id: "message-from-leadership" })}
          </div>

          <div className="h5 mb-4">
            {intl.formatMessage({ id: "hi" })}, {clientConnect?.username}
          </div>

          {leadershipUser?.message ? (
            ReactHtmlParser(leadershipUser.message)
          ) : (
            <>
              <div style={{ lineHeight: 2, fontSize: 21 }}>
                {intl.formatMessage({
                  id: "i-am-constantly-seeking-ways-to-better-our-offerings-for-our-clientele",
                })}
              </div>

              <div style={{ lineHeight: 2, fontSize: 21 }}>
                {intl.formatMessage({
                  id: "on-another-note-if-there-s-a-specific-topic-you-d-like-our-team-to-address-don-t-hesitate-to-share",
                })}
              </div>
            </>
          )}

          <div className="mt-5 d-flex align-items-center">
            <div style={{ borderRadius: "50%", overflow: "hidden" }}>
              <img
                width="65px"
                src={leadershipUser.image}
                onError={(e) => {
                  e.target.src = UserAvatar;
                }}
                alt="user"
                style={{ objectFit: "contain" }}
              />
            </div>

            <div className="ms-4">
              <div className="font-weight-bold">{leadershipUser?.name}</div>
              <div>{leadershipUser?.companyName}</div>
              <div className="small">{leadershipUser?.email}</div>
            </div>
          </div>

          <div className="mt-5">
            <TextField
              rows={4}
              multiline
              value={value}
              onChange={onChange}
              size="medium"
              placeholder={intl.formatMessage({ id: "your-message-here" })}
              className={classes.textfield}
              variant="outlined"
              InputProps={{ classes: { input: classes.input1 } }}
            />
          </div>
        </>
      ) : null}
      {isVisitInformation ? (
        <>
          <div className="text-center h4 mb-3 pt-4">
            {intl.formatMessage({ id: "visit-information" })}
          </div>
          <label>{intl.formatMessage({ id: "visit-time-out" })} *:</label>

          <DatePicker
            format="HH:mm"
            ranges={[]}
            value={selectedTimeOut}
            onChange={(time) => setSelectedTimeOut(time)}
            className="form-control p-0"
            block
            style={{
              color: "darkgray",
              fontSize: "14px",
            }}
          />
        </>
      ) : null}

      {/* Show Survey Competition Information */}
      {isSurveyCompetition ? (
        <>
          <div className="text-center h4 mb-3 pt-4">
            {intl.formatMessage({ id: "contact-information" })}
          </div>
          {competitionInfoText ? (
            <div
              className="mb-3"
              style={{ fontSize: "1rem", color: "black !important" }}
            >
              {ReactHtmlParser(competitionInfoText || "")}
            </div>
          ) : (
            <div
              className="mb-3"
              style={{ fontSize: "1rem", color: "black !important" }}
            >
              {intl.formatMessage({
                id: "to-provide-enhanced-assistance-we-kindly-ask-for-your-details-including-your-name-email-address-and-contact-number",
              })}
            </div>
          )}

          {customValues?.fields?.map((item) => (
            <div className="mt-2" key={item.fieldKey}>
              <h6 className="fw-normal">
                {item.fieldName}
                {item.isRequired ? (
                  <span style={{ color: "red" }}>*</span>
                ) : null}
              </h6>
              <div className="mt-1">
                {item.fieldType === "date" ? (
                  <DatePicker
                    format="DD/MM/YYYY"
                    ranges={[]}
                    value={customValues?.[item.fieldKey]}
                    onChange={(value) =>
                      updateCustomValues(
                        moment(value).format("YYYY-MM-DD"),
                        item
                      )
                    }
                    className="form-control p-0"
                    block
                    style={{
                      color: "darkgray",
                      fontSize: "14px",
                    }}
                    placeholder={item.placeholder || falbackPlaceholder}
                  />
                ) : item.fieldType === "time" ? (
                  <DatePicker
                    format="HH:mm"
                    ranges={[]}
                    value={customValues?.[item.fieldKey]}
                    onChange={(value) =>
                      updateCustomValues(moment(value).format("HH:mm"), item)
                    }
                    className="form-control p-0"
                    block
                    style={{
                      color: "darkgray",
                      fontSize: "14px",
                    }}
                    placeholder={item.placeholder || falbackPlaceholder}
                  />
                ) : item.fieldType === "datetime" ? (
                  <DatePicker
                    format="DD/MM/YYYY HH:mm"
                    ranges={[]}
                    value={customValues?.[item.fieldKey]}
                    onChange={(value) =>
                      updateCustomValues(
                        moment(value).format("YYYY-MM-DD HH:mm"),
                        item
                      )
                    }
                    className="form-control p-0"
                    block
                    style={{
                      color: "darkgray",
                      fontSize: "14px",
                    }}
                    placeholder={item.placeholder || falbackPlaceholder}
                  />
                ) : item.fieldType === "number" ? (
                  <Input
                    type="number"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={item.placeholder || falbackPlaceholder}
                    value={customValues?.[item.fieldKey]}
                    onChange={(e) => updateCustomValues(e, item)}
                  />
                ) : item.fieldType === "phone" ? (
                  <div className="mt-1 custom-input-box">
                    <span className="prefix">+44</span>
                    <Input
                      id="outlined-basic"
                      variant="outlined"
                      value={customValues?.[item.fieldKey]}
                      type="number"
                      onChange={(e) => updateCustomValues(e, item)}
                      className="contact-number pl-2"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      placeholder={item.placeholder || falbackPlaceholder}
                    />
                  </div>
                ) : (
                  <Input
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={item.placeholder || falbackPlaceholder}
                    value={customValues?.[item.fieldKey]}
                    onChange={(e) => {
                      updateCustomValues(e, item);
                    }}
                  />
                )}
              </div>
              <div className="mt-1">
                {customValuesErrors?.[item.fieldKey]?.error ? (
                  <p className="text-danger">
                    {customValuesErrors?.[item.fieldKey]?.message}
                  </p>
                ) : null}
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}
