import React from "react";
import { useHistory } from "react-router-dom";
import CheckFrontLogin from "./CheckFrontLogin";
import Footer from "./Footer";
import { useIntl } from "react-intl";

import image_emoji_1 from '../assets/images/web_rating/emoji_1.gif';
import image_emoji_2 from '../assets/images/web_rating/emoji_2.gif';
import image_emoji_3 from '../assets/images/web_rating/emoji_3.gif';
import image_emoji_4 from '../assets/images/web_rating/emoji_4.gif';
import image_emoji_5 from '../assets/images/web_rating/emoji_5.gif';


export default function Index(props) {
  const intl = useIntl();
  const history = useHistory();
  CheckFrontLogin();

  const redirectNextPage = (path, rating, emojiImagePath) => {
    localStorage.setItem("rating", rating);
    localStorage.setItem("emojiImagePath", emojiImagePath);

    history.push(path);
  };

  const handleResize = () => {
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 1170) {
      history.push("/front/mobile-home");
    } else {
      history.push("/front/home");
    }
  };

  React.useEffect(() => {
    handleResize();
  },[]);
  // check if to show the employee page or to skip it

  return (
    <React.Fragment>
      <div className="wrapper">
        <section className="feedback-experience-wrapper">
          <div className="container">
            <h1 className="h1-heading-home">
              {intl.formatMessage({ id: "please-tell-us-how-your-experience-was-today" })}
            </h1>
            <ul className="reverse-rating-emoji">
              <li>
                <div
                  className="ratings"
                  rating={1}
                  id="rating1"
                  onClick={() =>
                    redirectNextPage(
                      "/front/Ratting/negative-feedback-step-1",
                      1,
                      image_emoji_1
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_1}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={2}
                  id="rating2"
                  onClick={() =>
                    redirectNextPage(
                      "/front/Ratting/negative-feedback-step-1",
                      2,
                      image_emoji_2
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_2}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={3}
                  id="rating3"
                  onClick={() =>
                    redirectNextPage(
                      "/front/Ratting/negative-feedback-step-1",
                      3,
                      image_emoji_3
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_3}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={4}
                  id="rating4"
                  onClick={() =>
                    redirectNextPage(
                      "/front/Ratting/positive-feedback-step-1",
                      4,
                      image_emoji_4
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_4}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={5}
                  id="rating5"
                  onClick={() =>
                    redirectNextPage(
                      "/front/Ratting/positive-feedback-step-1",
                      5,
                      image_emoji_5
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_5}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" defaultChecked />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
