import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  addAnswer,
  addNote,
  addWriteIn,
} from "../../../../redux/survey/actions";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";
import SignaturePad from "react-signature-canvas";
import { FaTrashAlt } from "react-icons/fa";
import { useIntl } from "react-intl";

function SignatureType(props) {
  const intl = useIntl();
  const { question, addAnswer_, survey_theme, onSend } = props;
  const signatureRef = useRef();
  const [debounceTimer, setDebounceTimer] = useState(null); // Timer for debouncing

  const submitAnswer = () => {
    if (question?.required && !signatureRef?.current?.toDataURL()) {
      toast.error(intl.formatMessage({ id: "this-question-is-required" }));
      return;
    } else {
      const signData = signatureRef?.current?.getTrimmedCanvas()?.toDataURL();
      onSend(signData);
      addAnswer_({
        id: question._id,
        value: signData,
        isFile: true,
      });
    }
  };

  // Handler when user starts drawing
  const handleBegin = () => {
    if (debounceTimer) clearTimeout(debounceTimer); // Clear any ongoing debounce if the user starts drawing again
  };

  // Handler when user stops drawing
  const handleEnd = () => {
    if (signatureRef.current.isEmpty()) {
      toast.error(intl.formatMessage({ id: "please-complete-your-signature" }));
      return;
    }

    // Start a debounce timer to submit the signature after 1 seconds of inactivity
    const timer = setTimeout(() => {
      submitAnswer();
    }, 1000); // Adjust debounce time as needed (1000 ms = 1 seconds)
    setDebounceTimer(timer); // Save the timer so it can be cleared if necessary
  };

  const clearSignature = () => {
    signatureRef.current.clear();
    if (debounceTimer) clearTimeout(debounceTimer); // Clear the debounce timer if signature is cleared
  };

  const onSkipQuestion = () => {
    onSend("");
  };

  return (
    <>
      <div className="px-0">
        <div className="space-x-3">
          <SignaturePad
            ref={signatureRef}
            canvasProps={{
              height: 450,
              className:
                "signature-canvas-block block w-full px-3 py-2 text-base text-black transition-all duration-200 bg-white border border-transparent shadow-lg",
            }}
            onBegin={handleBegin} // Detect when drawing starts
            onEnd={handleEnd} // Handle when drawing ends with debounce
          />
        </div>
        <div className="d-flex align-items-center justify-content-end mt-2">
          <button
            onClick={clearSignature}
            type="submit"
            className="p-2 text-white transition-all duration-200 shadow-lg hover:bg-black focus:bg-black mr-1 d-flex align-items-center"
            style={{
              backgroundColor: survey_theme?.secondaryColor,
              borderRadius: 10,
            }}
          >
            <FaTrashAlt className="mr-1" />
            {intl.formatMessage({ id: "clear" })}
          </button>
        </div>
      </div>
      <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2">
        <div className="px-4 bg-white border-t border-gray-200">
          <div className="flex items-center space-x-3 h-18">
            {!question?.required ? (
              <button
                onClick={() => onSkipQuestion()}
                type="button"
                className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
              >
                {intl.formatMessage({ id: "skip" })}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
// Redux mapping function to map state and dispatch to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
  };
};
// Connect SignatureType component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(SignatureType);
