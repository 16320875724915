import { TextField } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

const TextArea = ({ onChange, answer, error }) => {
  const intl = useIntl();
  return (
    <>
      <TextField
        onChange={(e) => onChange(e.target.value)}
        value={answer?.value}
        minRows={3}
        maxRows={3}
        datatype
        multiline
        label={intl.formatMessage({ id: "enter-your-response" })}
        size="medium"
        variant="outlined"
      />

      {error ? (
        <div className="mt-2 small font-weight-bold text-danger">
          {error.message}
        </div>
      ) : null}
    </>
  );
};

export default TextArea;
