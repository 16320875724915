import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
// import { Bar , Line} from "react-chartjs-2";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import "../assets/css/ReportingPageStyles.css";
import { makeStyles } from "@material-ui/core";
import logo_alpha from "../assets/images/report_page/alpha_pharmacy.jpeg";
import image_emoji_group from "../assets/images/report_page/report-page-emoji.png";

import { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  save: {
    backgroundColor: "#6E2580",
    color: "white",
    margin: "10px",
    marginTop: "5px",
    textTransform: "none",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#6E2480",
    },
  },
}));
export default function ReportPage() {
  const intl = useIntl();
  const classes = useStyles();
  const inputRef = useRef(null);

  const printDocument = () => {
    html2canvas(inputRef.current, { allowTaint: true }).then(function (canvas) {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0; // give some top padding to first page

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Reporting-page.pdf");
    });
  };

  return (
    <React.Fragment>
      <Button className={classes.save} onClick={printDocument}>
        {intl.formatMessage({ id: "download-pdf" })}
      </Button>
      <div className="divToPrint" ref={inputRef}>
        <Grid container>
          <Grid
            item
            md={8}
            sm={6}
            xs={12}
            className="bg-white border border-gray-200 divide-y divide-gray-200 order-one"
          >
            <div className="heading">
              <p className="text-sm font-semibold leading-tight text-white uppercase">
                {intl.formatMessage({ id: "focus-for-the-month" })}
              </p>
            </div>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg
                    className="flex-shrink-0 w-5 h-5 mr-2 "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-sm">
                    {intl.formatMessage({ id: "the-focus-currently-is-on" })}
                    <span className="font-semibold">'Covid-19'</span>, {intl.formatMessage({ id: "1-number-of-customers-have-stated-this-as-an-opportunity" })}
                  </span>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            className="bg-white border border-gray-200 divide-y divide-gray-200 order-two"
            order={{ md: 2, sm: 1, xs: 1 }}
          >
            <div className="reporting-page-px-4 reporting-page-px-5 md:text-right">
              <img
                className="w-auto h-10 md:ml-auto"
                src={logo_alpha}
                alt="alpha_pharmacy"
              />
              <h1 className="mt-1 text-lg font-bold leading-tight uppercase">
                {intl.formatMessage({ id: "from-04-sep-2021-to-06-sep-2021" })}
              </h1>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={8} sm={6} xs={12}>
            <Grid container>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                className="bg-white border border-gray-200 divide-y divide-gray-200"
                style={{ height: "210px" }}
              >
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    {intl.formatMessage({ id: "net-promoter-score" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5">
                  <div className="rp-flex rp-items-start">
                    <div className="rp-flex rp-items-center rp-justify-center rp-flex-shrink-0 rp-w-12 rp-h-12 reporting-page-bg-green-100 reporting-page-rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="rp-ml-4">
                      <p className="rp-text-4xl rp-font-bold">100.0%</p>
                      <p className="rp-mt-1 rp-text-lg rp-leading-tight">
                        {intl.formatMessage({ id: "promoter" })}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                className="bg-white border border-gray-200 divide-y divide-gray-200"
                style={{ height: "210px" }}
              >
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    {intl.formatMessage({ id: "total-responses-for-july-2021" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5">
                  <div className="rp-flex rp-items-start">
                    <div className="rp-flex rp-items-center rp-justify-center rp-flex-shrink-0 rp-w-12 rp-h-12 reporting-page-bg-green-100 reporting-page-rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="rp-ml-4">
                      <p className="rp-text-4xl rp-font-bold">1</p>
                      <p className="rp-mt-1 rp-text-sm rp-leading-tight">
                        {intl.formatMessage({ id: "0-from-last-month" })}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Grid container>
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                className="bg-white border border-gray-200 divide-y divide-gray-200"
              >
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    {intl.formatMessage({ id: "score-during-period" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5">
                  <p
                    className="rp-text-2xl rp-font-bold"
                    style={{ marginBottom: "0px" }}
                  >
                    100.0%
                  </p>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                className="bg-white border border-gray-200 divide-y divide-gray-200"
              >
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    {intl.formatMessage({ id: "ytd-score" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5">
                  <p
                    className="rp-text-2xl rp-font-bold"
                    style={{ marginBottom: "0px" }}
                  >
                    33.33%
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                className="bg-white border border-gray-200 divide-y divide-gray-200"
              >
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    {intl.formatMessage({ id: "rank-in-the-country" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5">
                  <p
                    className="rp-text-2xl rp-font-bold"
                    style={{ marginBottom: "0px" }}
                  >
                    -
                  </p>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                className="bg-white border border-gray-200 divide-y divide-gray-200"
              >
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    {intl.formatMessage({ id: "top-performer" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5 uppercase">
                  <p
                    className="rp-text-2xl rp-font-bold"
                    style={{ marginBottom: "0px" }}
                  >
                    {intl.formatMessage({ id: "logan" })}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            className="bg-white border border-gray-200 divide-y divide-gray-200"
          >
            <Grid container>
              <Grid item md={12} sm={12} xs={12} style={{ height: "233.5px" }}>
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    {intl.formatMessage({ id: "customer-feedback" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5">
                  <p className="rp-text-xs"></p>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="heading">
                  <p className="text-sm font-semibold leading-tight text-white uppercase">
                    <svg
                      className="flex-shrink-0 w-5 h-5 mr-2 "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {intl.formatMessage({ id: "did-you-know" })}
                  </p>
                </div>
                <div className="reporting-page-px-4 reporting-page-px-5">
                  <p className="rp-text-xs">
                    {intl.formatMessage({ id: "net-promoter-score-info" })}
                    &nbsp;
                    <Link
                      target="_blank"
                      to="/#"
                      onClick={(e) => e.preventDefault()}
                      className="font-semibold rp-text-purple-600 rp-underline underline"
                    >
                      {intl.formatMessage({ id: "link" })}
                    </Link>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <div className="heading">
              <p className="text-sm font-semibold leading-tight text-white uppercase">
                {intl.formatMessage({ id: "trended-results" })}
              </p>
            </div>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <div className="rp-border-dashed rp-h-17 rp-border-gray-200">
                {/* <Line
                            data={{
                                // Name of the variables on x-axies for each bar
                                labels:['04 sept','05 sept','06 sept'],
                                datasets: [
                                {
                                    // Label for bars
                                    label: "count/date",
                                    // Data or value of your each variable
                                    data: [5,10,5],
                                    // Color of each bar
                                    backgroundColor: [
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    ],
                                    // Border color of each bar
                                    borderColor: [
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    ],
                                    borderWidth: 0.5,
                                },
                                ],
                            }}
                            // Height of graph
                            height={400}
                            options={{
                                plugins: {legend: { display: false, } } ,
                                maintainAspectRatio: false,
                                scales: {},
                                legend: {
                                labels: {
                                    display: false,
                                    fontSize: 15,
                                },
                                },
                            }}
                        /> */}
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          className="rp-bg-grey border rp-border-gray-200 divide-y divide-gray-200"
          style={{ height: "30px" }}
        ></div>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}></Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div
              className="reporting-page-px-4 reporting-page-px-5 rp-sm:pt-5 rp-sm:text-right"
              align="right"
            >
              <h1 className="rp-text-lg rp-font-bold rp-leading-tight rp-uppercase">
                {intl.formatMessage({ id: "performance" })}
              </h1>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            className="bg-white border border-gray-200 divide-y divide-gray-200"
          >
            <div className="heading">
              <p className="text-sm font-semibold leading-tight text-white uppercase">
                {intl.formatMessage({ id: "trended-net-promoter-score" })}
              </p>
            </div>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <div className="rp-border-dashed rp-h-334 rp-border-gray-200">
                {/* <Line
                            data={{
                                // Name of the variables on x-axies for each bar
                                labels:['04 sept','05 sept','06 sept'],
                                datasets: [
                                {
                                    // Label for bars
                                    label: "count/date",
                                    // Data or value of your each variable
                                    data: [5,10,5],
                                    // Color of each bar
                                    backgroundColor: [
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    ],
                                    // Border color of each bar
                                    borderColor: [
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    "#00922F",
                                    ],
                                    borderWidth: 0.5,
                                },
                                ],
                            }}
                            // Height of graph
                            height={400}
                            options={{
                                plugins: {legend: { display: false, } } ,
                                maintainAspectRatio: false,
                                scales: {},
                                legend: {
                                labels: {
                                    display: false,
                                    fontSize: 15,
                                },
                                },
                            }} />*/}
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            className="bg-white border border-gray-200 divide-y divide-gray-200"
          >
            <div className="heading">
              <p className="text-sm font-semibold leading-tight text-white uppercase">
                {intl.formatMessage({ id: "responses-breakdown" })}
              </p>
            </div>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <div className="rp-border-dashed rp-h-334 rp-border-gray-200 reporting-page-px-4 reporting-page-px-5">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className="rp-score-header"
                        style={{ paddingLeft: "6%" }}
                      >
                        0.0%
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className="rp-score-header"
                        style={{ paddingLeft: "25%" }}
                      >
                        100%
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <img src={image_emoji_group} alt="report-page-emoji" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        className="rp-score-header"
                        style={{ paddingRight: "2%" }}
                      >
                        0<br />
                        &nbsp;&nbsp;0.0%
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        className="rp-score-header"
                        style={{ marginLeft: "2.5%" }}
                      >
                        0<br />
                        &nbsp;&nbsp;0.0%
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        className="rp-score-header"
                        style={{ marginLeft: "3%" }}
                      >
                        0<br />
                        &nbsp;&nbsp;0.0%
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        className="rp-score-header"
                        style={{ marginLeft: "3.5%" }}
                      >
                        0<br />
                        &nbsp;&nbsp;0.0%
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        className="rp-score-header"
                        style={{ marginLeft: "3.5%" }}
                      >
                        1<br />
                        &nbsp;&nbsp;100%
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      textAlign: "center",
                      margin: "38px 0",
                      float: "left",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{ fontSize: "20px" }}
                      className="rp-sm-font-size"
                    >
                      {intl.formatMessage({ id: "net-promoter-score" })} = 100.0% - 0.0% = 100%
                    </span>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            className="bg-white border border-gray-200 divide-y divide-gray-200"
          >
            <div className="heading">
              <p className="text-sm font-semibold leading-tight text-white uppercase">
                {intl.formatMessage({ id: "your-location-vs-all-location" })}
              </p>
            </div>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <div className="rp-border-dashed rp-h-334 rp-border-gray-200">
                {/* <Bar
                  data={{
                    // Name of the variables on x-axies for each bar
                    labels: ['5-sept','10-sept','5-sept'],
                    datasets: [
                            {
                                label: "Blue",
                                backgroundColor: "#4E79A7",
                                data: [3,7,8]
                            },
                            {
                                label: "Red",
                                backgroundColor: "#F28E2B",
                                data: [4,3,2]
                            },
                            
                    ],
                  }}
                  // Height of graph
                  height={400}
                  options={{
                    plugins: {legend: { display: true,} } ,
                    maintainAspectRatio: false,
                    scales: {},
                    legend: {
                      display : false ,
                      labels: {
                        display : true ,
                        fontSize: 15,
                      },
                    },
                  }}
                /> */}
              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}></Grid>
        </Grid>

        <div
          className="rp-bg-grey border rp-border-gray-200 divide-y divide-gray-200"
          style={{ height: "30px" }}
        ></div>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <div className="reporting-page-px-4 reporting-page-px-5 ">
              <img
                src={logo_alpha}
                className="rp-w-auto rp-h-10"
                alt="alpha_pharmacy"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className="reporting-page-px-4 reporting-page-px-5 rp-sm:pt-5 rp-md:text-right">
              <h1 className="rp-text-lg rp-font-bold rp-leading-tight rp-uppercase">
                {intl.formatMessage({ id: "performance" })}
              </h1>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <TableContainer>
              <Table style={{ border: "1px solid #E0E0E0" }} size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        borderRight: "1px solid #E0E0E0",
                        textTransform: "uppercase",
                      }}
                    >
                      {intl.formatMessage({ id: "category" })}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        borderRight: "1px solid #E0E0E0",
                        textTransform: "uppercase",
                      }}
                    >
                      {intl.formatMessage({ id: "result" })}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        borderRight: "1px solid #E0E0E0",
                        textTransform: "uppercase",
                      }}
                    >
                      {intl.formatMessage({ id: "comment" })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      1
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      Covid-19
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      100%
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      2
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      Customer Service
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      100%
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <p className="text-sm font-semibold leading-tight uppercase">
                <svg
                  className="flex-shrink-0 w-5 h-5 mr-2 "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
                <span style={{ color: "#00922f", textTransform: "uppercase" }}>
                  {intl.formatMessage({ id: "did-you-know" })}
                </span>
              </p>
              <p className="mt-2 rp-text-xs">
                {intl.formatMessage({
                  id: "that-by-adding-your-teams-to-the-ServeFirst-reporting-platform",
                })}
                <Link
                  onClick={(e) => e.preventDefault()}
                  to="/#"
                  className="font-semibold rp-text-purple-600 rp-underline underline"
                >
                  {" "}
                  {intl.formatMessage({ id: "click-the-link-to-see-how" })}
                </Link>
              </p>
            </div>
          </Grid>
        </Grid>

        <div
          className="rp-bg-grey border rp-border-gray-200 divide-y divide-gray-200"
          style={{ height: "30px" }}
        ></div>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <div className="reporting-page-px-4 reporting-page-px-5 ">
              <img
                src={logo_alpha}
                className="rp-w-auto rp-h-10"
                alt="alpha_pharmacy1"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className="reporting-page-px-4 reporting-page-px-5 rp-sm:pt-5 rp-md:text-right">
              <h1 className="rp-text-lg rp-font-bold rp-leading-tight rp-uppercase">
                {intl.formatMessage({ id: "performance" })}
              </h1>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{ border: "1px solid #E0E0E0" }}
          >
            <div className="rp-px-4 rp-py-3">
              <p
                className="rp-font-bold rp-text-purple-600"
                style={{ marginBottom: "0px" }}
              >
                {intl.formatMessage({ id: "action-plan" })}
              </p>
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TableContainer>
              <Table style={{ border: "1px solid #E0E0E0" }} size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="rp-text-xs"
                      style={{ borderRight: "1px solid #E0E0E0", textTransform: "uppercase" }}
                    >
                      {intl.formatMessage({ id: "attribute-name" })}
                    </TableCell>
                    <TableCell
                      className="rp-text-xs"
                      style={{ borderRight: "1px solid #E0E0E0", textTransform: "uppercase" }}
                    >
                      {intl.formatMessage({ id: "action" })}
                    </TableCell>
                    <TableCell className="rp-text-xs" style={{ textTransform: "uppercase" }}>
                      {intl.formatMessage({ id: "no-of-times-raised" })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      1
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      2
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      3
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      4
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      5
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      6
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      7
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      8
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      9
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      10
                    </TableCell>
                    <TableCell
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    ></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <p className="text-sm font-semibold leading-tight uppercase">
                <svg
                  className="flex-shrink-0 w-5 h-5 mr-2 "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
                <span style={{ color: "#00922f", textTransform: "uppercase" }}>
                  {intl.formatMessage({ id: "did-you-know" })}
                </span>
              </p>
              <p className="mt-2 rp-text-xs">
                {intl.formatMessage({
                  id: "you-can-create-bespoke-action-plans",
                })}{" "}
                <Link
                  onClick={(e) => e.preventDefault()}
                  to="/#"
                  className="font-semibold rp-text-purple-600 rp-underline underline"
                >
                  {" "}
                  {intl.formatMessage({ id: "click-the-link-to-see-how" })}
                </Link>
              </p>
            </div>
          </Grid>
        </Grid>
        <div
          className="rp-bg-grey border rp-border-gray-200 divide-y divide-gray-200"
          style={{ height: "30px" }}
        ></div>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}></Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div
              className="reporting-page-px-4 reporting-page-px-5 rp-sm:pt-5 rp-sm:text-right"
              align="right"
            >
              <h1 className="rp-text-lg rp-font-bold rp-leading-tight rp-uppercase">
                {intl.formatMessage({ id: "performance" })}
              </h1>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <TableContainer>
              <Table style={{ border: "1px solid #E0E0E0" }} size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="rp-text-xs"
                      style={{ borderRight: "1px solid #E0E0E0" }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      className="rp-text-xs"
                      style={{
                        borderRight: "1px solid #E0E0E0",
                        textTransform: "uppercase",
                      }}
                    >
                      {intl.formatMessage({ id: "name" })}
                    </TableCell>
                    <TableCell
                      className="rp-text-xs"
                      style={{
                        borderRight: "1px solid #E0E0E0",
                        textTransform: "uppercase",
                      }}
                    >
                      {intl.formatMessage({ id: "focus" })}
                    </TableCell>
                    <TableCell
                      className="rp-text-xs"
                      style={{
                        borderRight: "1px solid #E0E0E0",
                        textTransform: "uppercase",
                      }}
                    >
                      {intl.formatMessage({ id: "average" })}
                    </TableCell>
                    <TableCell
                      className="rp-text-xs"
                      style={{ textTransform: "uppercase" }}
                    >
                      {intl.formatMessage({ id: "count" })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      1
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      Pearl Chemist
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      Covid-19
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      100.0%
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #E0E0E0" }}>
                      1
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <div className="reporting-page-px-4 reporting-page-px-5">
              <p className="text-sm font-semibold leading-tight uppercase">
                <svg
                  className="flex-shrink-0 w-5 h-5 mr-2 "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
                <span style={{ color: "#00922f", textTransform: "uppercase" }}>
                  {intl.formatMessage({ id: "did-you-know" })}
                </span>
              </p>
              <p className="mt-2 rp-text-xs">
                {intl.formatMessage({
                  id: "adding-your-teams-to-the-ServeFirst-reporting-platform",
                })}
                <Link
                  to="/#"
                  onClick={(e) => e.preventDefault()}
                  className="font-semibold rp-text-purple-600 rp-underline underline"
                >
                  {" "}
                  {intl.formatMessage({ id: "click-the-link-to-see-how" })}
                </Link>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
