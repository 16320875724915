import * as pdfjsLib from "pdfjs-dist";

// Ensure the PDF worker is correctly set
pdfjsLib.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.min.mjs`;

export async function checkFileType(url) {
  try {
    const response = await fetch(url);
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("image")) {
      return "image";
    } else if (contentType && contentType.includes("video")) {
      return "video";
    } else {
      // If content type is neither image nor video, you can handle it accordingly
      console.log("Unsupported content type:", contentType);
      return "unsupported";
    }
  } catch (error) {
    console.error("Error checking file type:", error);
    return "error";
  }
}

export const isCompanyLogin = () => {
  const companyLogin = localStorage.getItem("user_id");
  return companyLogin ? true : false;
};

/**
 * Generates a thumbnail for the first page of a PDF.
 * @param {File|Blob} pdfFile - The PDF file to generate a thumbnail for.
 * @returns {Promise<string>} - A promise that resolves to the base64 image data URL.
 */
export const generatePdfThumbnail = async (pdfFile, intl) => {
  try {
    let pdfBlob;

    // Check if the input is a File object
    if (pdfFile instanceof File) {
      pdfBlob = pdfFile; // Use the File directly
    } else {
      // Otherwise, assume it's a URL and fetch the PDF
      const response = await fetch(pdfFile);
      if (!response.ok) {
        throw new Error(intl.formatMessage({ id: "failed-to-fetch-the-pdf-file" }));
      }
      pdfBlob = await response.blob(); // Get the PDF file as a Blob
    }

    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onload = async function () {
        try {
          const typedArray = new Uint8Array(this.result);
          const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
          const firstPage = await pdf.getPage(1);

          const viewport = firstPage.getViewport({ scale: 1 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await firstPage.render({
            canvasContext: context,
            viewport,
          }).promise;

          const imageDataUrl = canvas.toDataURL();
          resolve(imageDataUrl); // Resolve with the generated thumbnail
        } catch (error) {
          console.error(`${intl.formatMessage({ id: "error-generating-pdf-thumbnail" })}:`, error);
          reject(error);
        }
      };

      fileReader.readAsArrayBuffer(pdfBlob);
    });
  } catch (error) {
    console.error(`${intl.formatMessage({ id: "error-in-thumbnail-generation-function" })}:`, error);
    throw new Error(intl.formatMessage({ id: "failed-to-generate-pdf-thumbnail" }));
  }
};
