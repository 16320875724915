import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import { InputLabel, MenuItem, Select } from "@mui/material";

const Dropdown = (props) => {
  // Destructure props for easier access
  const { question, answeres, addAnswer_ } = props;

  // Function to retrieve answer for the current question
  let answer = () => {
    // Check if question ID exists in answers
    if (!(question._id in answeres)) {
      return { value: "" }; // If not, return empty value
    } else {
      if (!("value" in answeres[question._id])) {
        return { value: "" }; //  If answer doesn't have a value property, return empty value
      } else {
        return answeres[question._id]; // Otherwise, return the answer
      }
    }
  };

  const handleChange = async (e) => {
    const value = e?.target?.value;
    // Add answer to Redux state
    await addAnswer_({
      id: question._id,
      value: value,
      type: "id",
    });
  };

  return (
    <div className="col-md-3 col-sm-12 col-xs-12">
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Please select</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={answer().value}
          onChange={handleChange}
          sx={{
            height: "45px", // Decrease the height of the select input
            ".MuiOutlinedInput-notchedOutline": {
              borderRadius: "4px",
            },
            "& .MuiSelect-select": {
              paddingTop: "5px", // Adjust padding to center the text
              paddingBottom: "5px",
            },
          }}
        >
          {question.options.map((option) => (
            <MenuItem value={option._id}>{option.text}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Map Redux dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
