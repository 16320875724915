import { Box, Grid, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import CheckBoxCard from "../../../common/components/CheckBoxCard";
import RadioCard from "../../../common/components/RadioCard";
import "../NewRadioButton.css";
import TextArea from "../TextArea";
import { useIntl } from "react-intl";

const Sequence = (props) => {
  const intl = useIntl();
  // Destructure props for easier access
  const {
    question,
    answeres,
    addAnswer_,
    pageNo,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    setTotalPage,
    isGreyOut,
    clearAnsweres_,
    headTonext,
  } = props;
  //  State to hold the selected value of checkboxes
  const [checked, setChecked] = useState([]);
  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [hideIndex, setHideIndex] = useState([]);

  const handleuserPref = (id) => {
    let value = typeof id === "string" ? id : id?.target?.value;
    let updatedChecked = [...checked];

    if (surveyType === "tablet" && pageNo === 1) {
      clearAnsweres_();
    }

    if (checked.includes(value)) {
      if (checked.length === question.min_options) {
        setMinError(true);
      } else {
        updatedChecked = updatedChecked.filter((item) => item !== value);
      }
    } else {
      if (checked.length === question.max_options) {
        setMaxError(true);
      } else {
        updatedChecked.push(value);
      }
    }
    setChecked(updatedChecked);

    // Calculate hide questions based on the updated checked list
    const hidequestions = question?.options
      ?.filter((opt) => updatedChecked?.includes(opt?._id))
      ?.flatMap((opt) => opt?.hide_questions || []);

    setHideIndex(hidequestions);
  };
  // Effect to reset error states when number of checked options changes
  useEffect(() => {
    if (checked.length >= question.min_options) {
      setMinError(false);
    }
    if (checked.length <= question.max_options) {
      setMaxError(false);
    }
  }, [checked]);

  // Function to load data from Redux store when component mounts
  const loadData = () => {
    if (question._id in answeres) {
      let prevAnswer = answeres[question._id] ?? { value: [] };
      setChecked(prevAnswer.value);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const setData = () => {
    if (surveyType === "tablet" && pageNo === 1) {
      let value = 0;
      question.options.forEach((element) => {
        if (checked.includes(element._id)) {
          value += Number(element.value);
        }
      });

      const percentage = (Number(value) / Number(question?.maxScore)) * 100;

      if (percentage >= 70) {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    let isScoreExclude = question.options
      .filter((item) => checked.includes(item._id))
      .filter((item) => item.score_exclusion_selection);
    // Add answer to Redux state
    addAnswer_({
      id: question._id,
      value: checked,
      type: "id",
      score_exclude: isScoreExclude?.length ? true : false,
    });

    headTonext(undefined, hideIndex, checked);
  };
  useEffect(() => {
    setData();
  }, [checked]);

  // Filter options to find those with iconClass defined
  let withIcon = question.options.filter((item) => item.iconClass);
  // Filter options to find those allowing write-in and matching the current answer value
  let allowWritein = question.options
    .filter((item) => checked.includes(item._id))
    .filter((item) => item.write_in);

  return (
    <Box style={{ cursor: isGreyOut && "not-allowed" }}>
      {withIcon.length ? (
        <Grid container>
          {question?.options.map((item, i) => (
            <Grid item xs={6} md={4} sm={3} lg={3} key={item._id}>
              <CheckBoxCard
                checked={checked}
                item={item}
                onClick={() => handleuserPref(item._id)}
                type="sequence"
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <RadioGroup
          className="radioInline"
          style={{ flexDirection: "column" }}
          row
          aria-label="position"
          name="position"
        >
          {question.options.map((option) => (
            <RadioCard
              isGreyOut={isGreyOut}
              key={(Math.random() * 1000).toFixed(0)}
              answer={checked}
              option={option}
              handleChange={handleuserPref}
              multi
              type="sequence"
            />
          ))}
        </RadioGroup>
      )}
      {allowWritein.length ? (
        <Box>
          <TextArea question={question} type="write_in" />
        </Box>
      ) : null}
      {minError && (
        <p style={{}}>
          {intl.formatMessage(
            { id: "minimum-selection-is" },
            { min: question.min_options }
          )}
        </p>
      )}
      {maxError && (
        <p style={{}}>
          {intl.formatMessage(
            { id: "maximum-selection-is" },
            { max: question.max_options }
          )}
        </p>
      )}
    </Box>
  );
};
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Sequence);
