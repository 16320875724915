import React, { useState  } from "react";
import { useHistory } from "react-router-dom";
import EmojiImageHeaderFeedback from "./EmojiImageHeaderFeedback";
import { postData } from "../components/FetchServices";
import Footer from "./Footer";
import "../assets/css/FeedbackRatingCustomStyles.css";
import user_image from '../assets/images/web_rating/user.png';
import axios from "axios";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import Slider from "react-slick";
import "../assets/css/EmployeeWebRatingStyles.css";
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  sliderBtnStyle: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid #955275`,
    color: '#955275',
  },
}));

function EmployeeFeedbackStep3(props) {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [employeeData, setemployeeData] = useState([]);
  const readEmployeeRecords = async () => {
    const body = {
      location_id: localStorage.getItem("employeeFeedbackSelectedLocation"),
      auth_user_id: localStorage.getItem("userId"),
    };
    let result = await postData("frontEmployeeFeedback/employeeList", body);
    if (result) {
      setemployeeData(result.data);
    }
  };

  React.useEffect(() => {
    readEmployeeRecords();
  },[])
  

  const getEmployeeDetail = (row) => {
    if (row !== undefined) {
      localStorage.setItem("employeeID", row.employeeData._id);
    } else {
      localStorage.setItem("employeeID", "");
    }
    saveFeedback()
  };
   
  
  const saveFeedback = () => {
    //e.preventDefault();
    let form_data = new FormData();
    form_data.append("company_id", localStorage.getItem("company_id"));
    form_data.append("location_id",localStorage.getItem("employeeFeedbackSelectedLocation"));//come from home page selected location 
    form_data.append("user_id",localStorage.getItem('userId'));//login user id
    form_data.append("skill_id", localStorage.getItem("selectedSkill"));
    form_data.append("employee_id", (localStorage.getItem('employeeID'))?localStorage.getItem('employeeID'):0);
    form_data.append("rating", localStorage.getItem("rating"));
    form_data.append("is_anonymous",(localStorage.getItem('is_anonymous'))?localStorage.getItem('is_anonymous'):0);
    form_data.append("feedback", localStorage.getItem('employeeFeedback'));
   // if (localStorage.getItem("employeeID") !== null && localStorage.getItem("employeeID") !== "" ) {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      let url = "frontEmployeeFeedback/saveFeedback";
      axios
        .post(`${baseUrl}/${url}`, form_data,{headers: {'Content-Type'  : 'multipart/form-data','Authorization' : localStorage.getItem('jws_token')}})
        .then((res) => {
          if (res.status === 201) {            
              localStorage.removeItem("employeeFeedbackSelectedLocation");
              localStorage.removeItem("selectedSkill");
              localStorage.removeItem("rating");
              localStorage.removeItem("employeeID");
              localStorage.removeItem("is_anonymous");
              localStorage.removeItem("employeeFeedback");
              history.push("/employee-feedback-thank-you");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    
    //  } else {
    //   setrequireEmployeeID(true);
    // }
  };
  const redirectPreviousTab = () => {
    history.push("/employee-feedback-step-2");
  };

  const settings = {
    infinite: true,
    dots: false,
    speed: 1000,
    slidesPerRow: 4,
    rows: 2,
    responsive: [
      {
      breakpoint: 768,
      settings: {
         slidesToShow: 1           
      }
     }
    ],
  };
  let sliderRef = React.useRef();

  const [isShowSlider,setisShowSlider]=useState((window.innerWidth<1170)?false:true);  
  React.useEffect(() => {
      const handleResize = () => {
        if(window.innerWidth<1170){
           setisShowSlider(false)
        } else{
          setisShowSlider(true)
        }

      }
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
  }, [])
  //console.log(window.innerWidth)
  const EmployeeSelectionBlock=()=>{
      if(isShowSlider===true){
        //web view
          return (
            <div className="person-slider-wrapper slick-initialized slick-slider">
              <div className="slick-list draggable">
                <div className="slick-track emp-feedback-slick-track" >
            <Slider {...settings} ref={sliderRef}>
                  {employeeData.map((rowdata, key) => {
                    let imagepath =
                      "https://sf-ratings-profile-image.s3.ap-south-1.amazonaws.com/employee/" +
                      rowdata.image;
                    let demoImagePath = user_image;
                    let tempData = {
                      imagepath: imagepath,
                      demoImagePath: demoImagePath,
                      employeeData: rowdata,
                    };

                    return (
                      <div
                        key={key}
                        className="slick-slide emp-feedback-slick-slide"
                        data-slick-index={0}
                        aria-hidden="false"
                        tabIndex={0}
                      >
                        <div>
                          <div
                            className="slide-item"
                            onClick={() => getEmployeeDetail(tempData)}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div className="custom-radio-outer">
                              <em>
                                <img src={demoImagePath} alt="image1" />
                              </em>                              
                              <input type="radio" name="person" tabIndex={0} />
                              <label style={{ height: 20 }}>
                                {rowdata.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}      
                  </Slider>    
                  </div>
                </div>
              </div>
          );
      }else{
        //mobile view
        return (
          <div className="person-slider-wrapper">
          
            {employeeData.map((rowdata, key) => {
            let imagepath =
              "https://sf-ratings-profile-image.s3.ap-south-1.amazonaws.com/employee/" +
              rowdata.image;
              let demoImagePath = user_image;
              let tempData = {
                imagepath: imagepath,
                demoImagePath: demoImagePath,
                employeeData: rowdata,
              };

            return (
                  <div
                    className="slide-item"
                    onClick={() => getEmployeeDetail(tempData)}
                    style={{ width: "100%", display: "inline-block" }}
                    key={key}
                  >
                    <div className="custom-radio-outer">
                      <em>
                        <img src={demoImagePath} alt="image1" />
                      </em>                              
                      <input type="radio" name="person" tabIndex={0} />
                      <label style={{ height: 20 }}>
                        {rowdata.name}
                      </label>
                    </div>
                  </div>
                
            );
          })}
        
          </div>    
        );
      }  
  } 

  return (
    <React.Fragment>
      <div className="wrapper has-fix-bar">
        <section className="feedback-stepper-wrapper">
          <div className="container">
            <div className="top-header-wrapper">
              <div className="experience-ans-tip">
                {/* fetching rating from session  */}
                <EmojiImageHeaderFeedback />
              </div>
              <div className="pagination-wrapper">
                <span>1</span>/<span>3</span>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <h1 className="h1-heading emp-feedback-h1-heading">
                    {intl.formatMessage({ id: "employee-web-rating-feedback-step-3-heading" })}
                </h1>
                {
                (isShowSlider===true && employeeData.length>0)?
                  <div className="emp-feedback-slide-btn-parent">
                    <IconButton onClick={()=>sliderRef?.current?.slickPrev()} className={classes.sliderBtnStyle} size='small'>
                      <ArrowBackIcon/>
                    </IconButton>&nbsp;&nbsp;
                    <IconButton onClick={()=>sliderRef?.current?.slickNext()} className={classes.sliderBtnStyle} size='small'>
                      <ArrowForwardIcon/>
                    </IconButton>
                </div>
                :''
                }
                
            </div>
                  <EmployeeSelectionBlock/>
                {(employeeData.length===0)?
                  (<div align="center" style={{marginTop:'100px',marginBottom:'100px'}}>
                  <h1 className="h1-heading ">
                    {intl.formatMessage({ id: "no-employees-found" })}
                  </h1> 
                  </div>)
                :null  
                }
            <div className="bottom-fix-btnbar">
              <button
                className="btn border-btn icon-btn sm rating-feedback-prev-btn"
                title={intl.formatMessage({ id: "previous" })}
                onClick={() => redirectPreviousTab()}
                style={{ border: "1px solid #955275", padding: "0px" }}
              >
                <em>
                  <svg
                    id="_32213"
                    data-name={32213}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.519"
                    height="13.19"
                    viewBox="0 0 7.519 13.19"
                  >
                    <path
                      id="Path_71"
                      data-name="Path 71"
                      d="M97.409,7.248l5.672,5.671a.924.924,0,0,0,1.306-1.306L99.369,6.595l5.019-5.018A.924.924,0,0,0,103.081.27L97.409,5.942a.924.924,0,0,0,0,1.306Z"
                      transform="translate(-97.139 0)"
                      fill="#955275"
                    />
                  </svg>
                </em>
                {intl.formatMessage({ id: "previous" })}
              </button>
              <div className="pagination-wrapper">
                <span>3</span>/<span>3</span>
              </div>
              <button
                  className="btn icon-btn icon-right sm"
                  type="submit"
                  name="submit"
                  style={{
                    backgroundColor: "#955275",
                    color: "#fff",
                    border: "1px solid #955275",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    lineHeight: "12px",
                  }}
                  onClick={(e) => saveFeedback()}
                >
                  {intl.formatMessage({ id: "complete" })}
                  <em>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.519"
                      height="13.191"
                      viewBox="0 0 7.519 13.191"
                    >
                      <g
                        id="_32213"
                        data-name={32213}
                        transform="translate(-97.138 0)"
                      >
                        <path
                          id="Path_71"
                          data-name="Path 71"
                          d="M104.387,7.248,98.715,12.92a.924.924,0,1,1-1.306-1.306L102.428,6.6,97.409,1.577A.924.924,0,0,1,98.716.27l5.672,5.672a.924.924,0,0,1,0,1.306Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </em>
                </button>
             
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default EmployeeFeedbackStep3;
