import React, { useState } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormButton from "../../../common/components/FormButton";
import Add from "@material-ui/icons/Add";
import TextArea from "../TextArea";
import Upload from "../Upload";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  box: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
    width: "100%",
    marginBottom: 0,
  },
}));
function NotesAndImage(props) {
  const intl = useIntl();
  // Desstructing the props object to get access to individual properties
  const { question, answeres, isGreyOut, locations, survey } = props;
  let answer = answeres[question._id] ?? { value: "", note: "", image: "" }; // Extracts the answer related to this question or creates an empty
  // State variables for handling user input and image uploads
  const [showNote, setShowNote] = useState(answer?.note?.trim() ? true : false);
  const [showImage, setShowImage] = useState(
    answer?.image?.length ? true : false
  );

  const theme = useTheme(); // Get the current theme
  const isMob = useMediaQuery(theme.breakpoints.down("sm")); // Check if device is mobile or not

  const selectedLocationId = localStorage.getItem("location") || "";
  const selectedLocation = locations.find(
    (location) => location._id === selectedLocationId
  );
  let mediaSecurity = false;
  if (locations?.length) {
    mediaSecurity = selectedLocation?.mediaSecurity;
  } else {
    mediaSecurity = survey?.survey_without_login?.mediaSecurity;
  }

  const classes = useStyles();
  return (
    <Box
      style={{
        marginTop: 10,
      }}
      classes={classes.buttonContainer}
    >
      <Box className={classes.box}>
        {question?.allow_comment ? (
          <FormButton
            variant="outlined"
            style={{ marginRight: "10px" }}
            title={intl.formatMessage({ id: "add-comments" })}
            onClick={() => !isGreyOut && setShowNote(!showNote)}
            rightIcon={<Add style={{ fontSize: isMob ? "12px" : "16px" }} />}
          />
        ) : null}
        {question?.allow_file_upload && !mediaSecurity ? (
          <FormButton
            variant="outlined"
            title={intl.formatMessage({ id: "upload-image" })}
            onClick={() => !isGreyOut && setShowImage(!showImage)}
            rightIcon={<Add style={{ fontSize: isMob ? "12px" : "16px" }} />}
          />
        ) : null}
      </Box>
      {showNote && (
        <TextArea
          styles={{ marginTop: isMob ? "8px" : "15px" }}
          question={question}
          type="note"
        />
      )}
      {showImage && (
        <Upload
          styles={{ marginTop: isMob ? "8px" : "15px" }}
          question={question}
          type="note"
        />
      )}
    </Box>
  );
}
// Function that binds the dispatch to action creators
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
    locations: state.survey.locations,
    survey: state.survey,
  };
};
// Connecting  the NotesAndImage component to the Redux store
export default connect(mapStateToProps)(NotesAndImage);
