import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import "../components/question.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  addAnswer,
  addWriteIn,
  clearAnsweres,
} from "../../../../redux/survey/actions";
import { useIntl } from "react-intl";

const DropdownType = (props) => {
  const intl = useIntl();
  // Destructuring props
  const {
    finishSurvey,
    headTonext,
    question,
    answeres,
    addAnswer_,
    clearAnsweres_,
    survey_theme,
    onSend,
    addWriteIn_,
    allQuestionsOriginal,
    setAllQuestions,
    surveyType,
    currentQuestionIndex,
    hideQuestion,
    allQuestions,
  } = props;

  // State variables
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState("");
  const [checkedText, setCheckedText] = useState("");
  const [comment, setComment] = useState("");
  const [isFinishSurvey, setIsFinishSurvey] = useState(false);
  const [routeToIndex, setRouteToIndex] = useState("");

  // Function to handle radio button change
  const handleChange = (ratingId, text) => {
    setComment("");
    setChecked(ratingId);
    setCheckedText(text);

    addAnswer_({
      id: question._id,
      value: ratingId,
      type: "id",
    });
  };

  const onSubmitAnswer = () => {
    if (question?.required && !checked) {
      toast.error(intl.formatMessage({ id: "this-question-is-required" }));
      return;
    } else {
      const selectedOption = question?.options?.find(
        (opt) => opt?._id === checked
      );
      if (selectedOption?.write_in) {
        if (!comment?.trim()) {
          toast.error(intl.formatMessage({ id: "comment-is-required" }));
          return;
        }
      }
      if (comment) {
        addWriteIn_({
          id: question._id,
          write_in: comment,
          type: "text",
        });
      }
      // Navigate to the next question if the selected option has a route_to_index
      if (!isFinishSurvey && routeToIndex) {
        headTonext(routeToIndex, question._id);
      }
      // Finish the survey if the selected option has the finish_survey flag set to true
      if (isFinishSurvey) {
        finishSurvey(isFinishSurvey, question);
      }
      // Finish the survey if the current question is the last question
      if (currentQuestionIndex + 1 >= allQuestions?.length) {
        finishSurvey(true, question);
      }
      onSend(checkedText, comment);
      setOpen(false);
    }
  };

  // Skip the current question and finish the survey if it's the last question
  const onSkipQuestion = () => {
    onSend(checkedText);
    setOpen(false);
    if (currentQuestionIndex + 1 >= allQuestions?.length) {
      finishSurvey(true, question);
    }
  };

  return (
    <>
      <div className="px-4 bg-white border-t border-gray-200">
        <div className="flex items-center space-x-3 h-18">
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            style={{ color: survey_theme?.primaryColor }}
            data-toggle="modal"
            data-target="#selectSkills"
          >
            <svg
              className="w-5 height-1 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
              <path
                fillRule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clipRule="evenodd"
              />
            </svg>
            {intl.formatMessage({ id: "select" })}
          </button>
          {!question?.required ? (
            <button
              onClick={() => onSkipQuestion()}
              type="button"
              className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            >
              {intl.formatMessage({ id: "skip" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {open ? (
        <div>
          <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="mobile-view-modal"
            id="mobile-view-modal"
          >
            <DialogTitle id="mobile-view-modal-title">
              <div className="modal-header">
                <h5 className="mr-12 text-lg font-bold truncate">
                  {intl.formatMessage({ id: "select" })}
                </h5>
                {/* close button */}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setOpen(false)}
                  aria-label="Close"
                >
                  <span className="sr-only">
                    {intl.formatMessage({ id: "close" })}
                  </span>
                </button>
              </div>
            </DialogTitle>

            <DialogContent id="mobile-view-modal-body">
              <div className="divide-y divide-gray-200">
                {question?.options?.map((option, k) => {
                  return (
                    <div
                      key={k}
                      className="flex items-center justify-between px-3 py-3 bg-white"
                    >
                      <label
                        htmlFor={`skill-${k}`}
                        className="flex-1 block text-base font-medium"
                      >
                        {option.text}
                        {option?.optionText ? (
                          <p
                            style={{
                              color: survey_theme?.primaryColor,
                              fontSize: "12px",
                            }}
                          >
                            {option?.optionText}
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        type="radio"
                        id={`skill-${k}`}
                        name="radioType"
                        value={option._id}
                        className="w-5 h-5 border-gray-300 rounded-full"
                        onChange={() => {
                          handleChange(
                            option._id,
                            option.value,
                            option.text,
                            option?.route_to_index,
                            option?.hide_questions,
                            option?.score_exclusion_selection,
                            option?.finish_survey === true
                          );
                        }}
                        checked={checked === option._id}
                      />
                    </div>
                  );
                })}
              </div>
            </DialogContent>

            <DialogActions id="mobile-view-modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  style={{
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => setOpen(false)}
                  className="flex items-center justify-center flex-1 w-full  text-base font-semibold text-gray-600 transition-all duration-200 bg-gray-300 rounded-full shadow-lg hover:text-white focus:text-white hover:bg-gray-600 focus:bg-gray-600"
                >
                  {intl.formatMessage({ id: "discard" })}
                </button>
                <button
                  style={{
                    backgroundColor: survey_theme?.primaryColor,
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => onSubmitAnswer()}
                  className="flex items-center justify-center flex-1 w-full text-base font-semibold text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                >
                  {intl.formatMessage({ id: "save" })}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
// Redux mapping function to map state to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connects the RadioType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(DropdownType);
