import React from "react";
import { useIntl } from "react-intl";

export default function AiSuggestions({ loading, aiSuggestions, userName = null }) {
  const intl = useIntl();
  const suggestions = aiSuggestions ? aiSuggestions.replace("\n\n", "\n").split("\n") : [];

  return (
    <>
      <h5 className="mb-0">
        <strong>{intl.formatMessage({ id: "hello" })} {userName ?? "User"}</strong>
      </h5>

      {loading ? (
        <div className="text-center mt-3">
          {intl.formatMessage({ id: "analyzing-data-please-wait" })}
        </div>
      ) : (
        <>
        {suggestions.length ? (
          <ul className="mb-0" style={{ listStyleType: "none" }}>
          {suggestions.map((text) =>
            text && text.length > 2 ? (
              <li className="py-1 my-1" key={Math.round(Math.random() * 1e10)}>
              {text}
              </li>
            ) : null
          )}
          </ul>
        ) : null}
        </>)
    }
    </>
  );
}
