import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addAnswer,
  addNote,
  addWriteIn,
} from "../../../../redux/survey/actions";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";
import "rsuite/dist/styles/rsuite-default.css";
import { useIntl } from "react-intl";

function NumberType(props) {
  const intl = useIntl();
  const { question, answeres, addAnswer_, survey_theme, onSend } = props;

  const [text, setText] = useState("");

  let answer = answeres[question._id] ?? { value: text };

  const submitAnswer = () => {
    const integerRegex = /^\d*$/;
    const isInteger = integerRegex.test(text);

    if (question?.required && !text) {
      toast.error(intl.formatMessage({ id: "this-question-is-required" }));
      return;
    } else {
      if (text && !question?.allow_decimal && !isInteger) {
        toast.error(
          intl.formatMessage({
            id: "please-enter-a-number-without-any-decimal-points",
          })
        );
        return;
      }
      // Check if the entered number exceeds the maximum value
      if (
        text &&
        Number(question?.max_number_value) &&
        Number(question?.max_number_value) > 0 &&
        Number(text) > question?.max_number_value
      ) {
        toast.error(
          intl.formatMessage(
            { id: "please-enter-a-number-less-than-or-equal-to" },
            { max_number_value: question?.max_number_value }
          )
        );
        return;
      }
      onSend(answer?.value);
      addAnswer_({ id: question._id, value: Number(text), type: "text" });
    }
    setText("");
  };

  return (
    <div className="px-2 bg-white border-t border-gray-200">
      <div className="flex items-center justify-center space-x-3 h-18 px-2">
        <input
          type="number"
          value={text}
          onChange={(e) => setText(e?.target?.value)}
          id="feedback"
          placeholder={intl.formatMessage({ id: "write-here" })}
          className="block w-full px-4 py-3 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
          autoComplete="off"
        />

        <button
          onClick={submitAnswer}
          type="submit"
          className="flex items-end justify-center flex-shrink-0 p-3 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
          style={{
            backgroundColor: survey_theme?.primaryColor,
          }}
        >
          <svg
            className="w-6 h-6 rotate-90"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  );
}
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
  };
};
// Connects the NumberType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(NumberType);
