import React, { useEffect, useState } from "react";
import "../components/question.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  addAnswer,
  addWriteIn,
  clearAnsweres,
} from "../../../../redux/survey/actions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useIntl } from "react-intl";

const CheckBoxType = (props) => {
  const intl = useIntl();
  // Destructuring props
  const {
    question,
    survey_theme,
    addAnswer_,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    clearAnsweres_,
    onSend,
    addWriteIn_,
    currentQuestionIndex,
    hideQuestion,
    finishSurvey,
    allQuestions,
  } = props;
  // State variables
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [selectedText, setSelectedText] = useState([]);
  const [comment, setComment] = useState("");
  const [hideIndex, setHideIndex] = useState([]);

  // Filter options that allow write-ins
  let allowWritein = question.options
    .filter((item) => checked.includes(item._id))
    .filter((item) => item.write_in);

  const handleuserPref = (option, e) => {
    const value = option?._id;
    if (surveyType === "tablet" && currentQuestionIndex === 0) {
      clearAnsweres_();
    }

    let updatedOptions = [...checked];
    let updatedTexts = [...selectedText];

    if (option?.exclusiveSelection && e?.target?.checked) {
      // If exclusiveSelection is true, uncheck other checkboxes
      updatedOptions = [value];
      updatedTexts = [option?.text];
    } else {
      if (updatedOptions?.includes(value)) {
        // If the checkbox is already checked, uncheck it
        updatedOptions.splice(updatedOptions.indexOf(value), 1);
        updatedTexts.splice(updatedOptions.indexOf(value), 1);
      } else {
        if (
          !question.max_options ||
          updatedOptions.length < parseInt(question.max_options, 10)
        ) {
          // If exclusiveSelection checkbox is checked, uncheck it
          const exclusiveIndex = updatedOptions.findIndex(
            (id) =>
              question?.options.find((opt) => opt._id === id)
                ?.exclusiveSelection
          );
          if (exclusiveIndex !== -1) {
            updatedOptions.splice(exclusiveIndex, 1);
            updatedTexts.splice(exclusiveIndex, 1);
          }
          updatedOptions.push(value);
          updatedTexts.push(option?.text);
        } else {
          toast.error(
            intl.formatMessage({ id: "maximum-selection-is" }, { max_options: question.max_options })
          );
          return;
        }
      }
    }
    setChecked(updatedOptions);
    const hidequestions = question?.options
      ?.filter((opt) => updatedOptions?.includes(opt?._id))
      ?.flatMap((opt) => opt?.hide_questions || []);

    setHideIndex(hidequestions);
    setSelectedText(updatedTexts);
  };

  const setData = () => {
    // Check if the survey type is "tablet" and it's the first question
    if (surveyType === "tablet" && currentQuestionIndex === 0) {
      let value = 0;
      question.options.forEach((element) => {
        if (checked.includes(element._id)) {
          value += Number(element.value);
        }
      });
      // Calculate the percentage based on the current question's value and max score
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;
      // Filter questions based on percentage for positive or neutral journey type
      if (percentage >= 70) {
        // Filter questions excluding the current question and those with negative journey type
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        // Filter questions excluding the current question and those with positive journey type
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
  };
  useEffect(() => {
    setData();
  }, [checked]);

  const onSubmitAnswer = () => {
    if (question?.required && !checked?.length) {
      toast.error(intl.formatMessage({ id: "this-question-is-required" }));
      return;
    } else {
      if (
        !question?.options.find((opt) => checked?.includes(opt?._id))
          ?.exclusiveSelection &&
        checked?.length < question?.min_options
      ) {
        toast.error(
          intl.formatMessage(
            { id: "minimum-selection-is" },
            { min_options: question.min_options }
          )
        );
      } else {
        const selectedOptions =
          question?.options?.filter((opt) => checked?.includes(opt?._id)) || [];
        if (selectedOptions?.some((opt) => opt?.write_in)) {
          if (!comment?.trim()) {
            toast.error(intl.formatMessage({ id: "comment-is-required" }));
            return;
          }
        }

        let isScoreExclude = question.options
          .filter((item) => checked.includes(item._id))
          .filter((item) => item.score_exclusion_selection);

        addAnswer_({
          id: question._id,
          value: checked,
          type: "id",
          score_exclude: isScoreExclude?.length ? true : false,
        });
        addWriteIn_({
          id: question._id,
          write_in: comment,
          type: "text",
        });
        onSend(selectedText?.join(","), comment);
        setOpen(false);
        hideQuestion(hideIndex);
        // if the current question is the last question, finish the survey
        if (allQuestions?.length === currentQuestionIndex + 1) {
          finishSurvey(true, question);
        }
      }
    }
  };

  const onSkipQuestion = () => {
    onSend(selectedText?.join(","));
    setOpen(false);
    // if the current question is the last question, finish the survey
    if (allQuestions?.length === currentQuestionIndex + 1) {
      finishSurvey(true, question);
    }
  };

  return (
    <>
      <div className="px-4 bg-white border-t border-gray-200">
        <div className="flex items-center space-x-3 h-18">
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            style={{ color: survey_theme?.primaryColor }}
            data-toggle="modal"
            data-target="#selectSkills"
          >
            <svg
              className="w-5 height-1 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
              <path
                fillRule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clipRule="evenodd"
              />
            </svg>
            {intl.formatMessage({ id: "select" })}
          </button>
          {!question?.required ? (
            <button
              onClick={onSkipQuestion}
              type="button"
              className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            >
              {intl.formatMessage({ id: "skip" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {open ? (
        <div>
          <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="mobile-view-modal"
            id="mobile-view-modal"
          >
            <DialogTitle id="mobile-view-modal-title">
              <div className="modal-header">
                <h5 className="mr-12 text-lg font-bold truncate">
                  {intl.formatMessage({ id: "select" })}
                </h5>
                {/* close button */}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setOpen(false)}
                  aria-label="Close"
                >
                  <span className="sr-only">
                    {intl.formatMessage({ id: "close" })}
                  </span>
                </button>
              </div>
            </DialogTitle>

            <DialogContent id="mobile-view-modal-body">
              <div className="divide-y divide-gray-200">
                {question?.options?.map((option, k) => {
                  return (
                    <div
                      key={k}
                      className="flex items-center justify-between px-3 py-3 bg-white"
                    >
                      <label
                        htmlFor={`skill-${k}`}
                        className="flex-1 block text-base font-medium"
                      >
                        {option.text}
                      </label>
                      <input
                        type="checkbox"
                        id={`skill-${k}`}
                        name="checkboxType"
                        value={option._id}
                        className="w-5 h-5 border-gray-300 rounded-full"
                        onChange={(e) => handleuserPref(option, e)}
                        checked={checked.includes(option._id)}
                        disabled={
                          !checked.includes(option._id) &&
                          question?.max_options &&
                          checked?.length > question?.max_options
                        }
                      />
                    </div>
                  );
                })}
                {allowWritein?.length ? (
                  <TextField
                    id="outlined-required"
                    label={intl.formatMessage({ id: "comment" })}
                    variant="outlined"
                    className="ps-1 pe-1"
                    value={comment}
                    onChange={(e) => setComment(e?.target?.value)}
                    autoComplete="off"
                  />
                ) : null}
              </div>
            </DialogContent>
            <DialogActions id="mobile-view-modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  style={{
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => setOpen(false)}
                  className="flex items-center justify-center flex-1 w-full  text-base font-semibold text-gray-600 transition-all duration-200 bg-gray-300 rounded-full shadow-lg hover:text-white focus:text-white hover:bg-gray-600 focus:bg-gray-600"
                >
                  {intl.formatMessage({ id: "discard" })}
                </button>
                <button
                  style={{
                    backgroundColor: survey_theme?.primaryColor,
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => onSubmitAnswer()}
                  className="flex items-center justify-center flex-1 w-full text-base font-semibold text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                >
                  {intl.formatMessage({ id: "save" })}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
// Redux mapping function to map state to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connects the CheckBoxType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(CheckBoxType);
