// import { addLocaleData } from 'react-intl';
import enLang from "./entries/en-US";
import esLang from "./entries/es-ES";
import enRtlLang from "./entries/en-US-rtl";
import trLang from "./entries/tr-TR";

// import {createIntl, createIntlCache, RawIntlProvider} from 'react-intl'

// // This is optional but highly recommended
// // since it prevents memory leak
// const cache = createIntlCache()

// const intl = createIntl({
//   locale: 'fr-FR',
//   messages: {}
// }, cache)

const AppLocale = {
  en: enLang,
  es: esLang,
  enrtl: enRtlLang,
  tr: trLang,
};
// addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.es.data);
// addLocaleData(AppLocale.enrtl.data);

export default AppLocale;

// export const preferredLangArr = [
//   { value: 'en-US', label: 'English' },
//   { value: 'tr-TR', label: 'Turkish' },
//   { value: 'ro-RO', label: 'Romanian' },
//   { value: 'cs-CZ', label: 'Czech' },
//   { value: 'nl-NL', label: 'Dutch' },
//   { value: 'fr-FR', label: 'French' },
//   { value: 'es-ES', label: 'Spanish' },
//   { value: 'pt-PT', label: 'Portuguese' },
// ];

const localeStorageKey = "locale_lang";

export const updateLocale = (locale) => {
  localStorage.setItem(localeStorageKey, locale);
  window.dispatchEvent(new Event("storage"));
};

export const getLocale = () => {
  const locale = localStorage.getItem(localeStorageKey);
  if (!locale) {
    localStorage.setItem(localeStorageKey, "en");
    return "en";
  }
  return locale;
};
