import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import { useIntl } from "react-intl";

function ThankyouPage(props) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="wrapper">
        <section className="thankyou-wrapper">
          <div className="container">
            <h1 className="h1-heading">{intl.formatMessage({ id: "thank-you-for-your-feedback" })}</h1>
            <div className="image-block">
              <img src="/thankyou-thumb.svg" alt="thank-you-thumb" />
            </div>
            <button
              onClick={() => history.push("/employee-feedback-home")}
              className="btn"
              style={{
                backgroundColor: "#955275",
                color: "#fff",
                border: "1px solid #955275",
              }}
            >
              {intl.formatMessage({ id: "back-to-home" })} <span>{intl.formatMessage({ id: "page" })}</span>
            </button>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default ThankyouPage;
