import React from "react";
import MdCard from "../common/MdCard";
import Bar from "../../components/charts/Bar";
import { useIntl } from "react-intl";

function LocationVsAll(props) {
  const intl = useIntl();
  const { locations = [], allLocations = [] } = props.data;
  const labels = [];
  const dataSet1 = [];
  let dataSet2Sum = 0;

  locations.forEach((location) => {
    labels.push(location.name);
    dataSet1.push(location.count);
    const existing = allLocations.find(
      ({ _id }) => _id.toString() === location._id.toString()
    );
    if (existing) {
      dataSet2Sum += location.count;
    }
  });
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: "location",
        data: dataSet1,
        barThickness: 30,
        maxBarThickness: 25,
      },
      {
        data: Array.from(
          { length: dataSet1.length },
          () => dataSet2Sum,
        ),
        label: "all location",
        barThickness: 30,
        maxBarThickness: 25,
      },
    ],
  };

  return (
    <MdCard title={intl.formatMessage({ id: "your-location-vs-all-location" })}>
      <div
        style={{
          height: "90%",
          width: "90%",
          margin: "auto",
        }}
      >
        {locations.length ? <Bar data={data} /> : null}
      </div>
    </MdCard>
  );
}

export default LocationVsAll;
