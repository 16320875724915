import React from "react";
import "./question.css";
import ReactHtmlParser from "react-html-parser";
import user_image from "../../../../assets/images/mobile_emoji/lady.jpg";

function SingleQuestion({ question, survey_theme, ...props }) {
  return (
    <>
      <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
        <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
          <img
            className="object-cover w-full h-full"
            src={user_image}
            alt="user_demo"
          />
        </div>
        <div
          className="ms-2 text-white rounded-bl-none rounded-2xl"
          style={{ backgroundColor: survey_theme?.primaryColor }}
        >
          <div
            style={{
              padding: "0.60rem 1rem",
            }}
          >
            <span className="leading-tight">
              {question?.text ? ReactHtmlParser(question?.text) : ""}
            </span>
            {question?.unit ? (
              <span className="ms-1">({question.unit})</span>
            ) : null}
          </div>
        </div>
      </div>
      {question?.images?.length ? (
        <div className="mt-2 answerImage">
          {question.images?.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`file-${index}`} />
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}

export default SingleQuestion;
