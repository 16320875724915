import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { commonPageMakeStyles } from "../../Survey/common/styles/commonPageStyles";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import Background from "../../Survey/common/components/Background";
import PaperContainer from "../../Survey/common/components/PaperContainer";
import { useHistory } from "react-router-dom";
import LocationCard from "../../Survey/pages/Location/LocationCard";
import { setFrontratingAnsweres } from "../../redux/frontWebRating/actions";
import {
  getLatLong,
  LOCATION_STATUS,
} from "../../Survey/helpers/navigatorHelpers";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { postData } from "../../components/FetchServices";
import { setSurveyTheme } from "../../redux/survey/actions";
import { ThemeUpdateContext } from "../../App";
import { useTheme } from "@material-ui/styles";

function RatingLocations(props) {
  const intl = useIntl();
  const [locations, setLocations] = useState([]);
  const [ALLlocations, setALLLocations] = useState([]);
  const [latLong, setlatLong] = useState({ lat: "", long: "" });
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [logoLoading, setLogoLoading] = useState(false);
  const theme = useTheme();

  const [logo, setLogo] = useState("/images/webRating/serve-first.png");
  const commonPageCss = commonPageMakeStyles();
  const { changeTheme } = useContext(ThemeUpdateContext);
  const history = useHistory();

  const [showAllLocations, setShowAllLocation] = useState(false);
  const { UNKNOWN, ALLOWED, BLOCKED } = LOCATION_STATUS;
  const [locationStatus, setLocationStatus] = useState(UNKNOWN);
  const [commpanyDetails, setCompanyDetails] = useState();
  const setBrandDetails = async ({ company_id, tag_id }) => {
    setLogoLoading(true);
    const result = await postData("api/getBrandDetail", {
      company_id,
      tag_id,
    });
    if (result?.status === 200) {
      setCompanyDetails(result);
      setLogo(
        result?.data?.logo
          ? result?.data?.logo
          : "/images/webRating/serve-first.png"
      );
      localStorage.setItem("logo", result?.data?.logo);
      props.setSurveyTheme_({
        primaryColor:
          result?.data?.primaryColor !== "undefined"
            ? result?.data?.primaryColor
            : "#36473F",
        secondaryColor:
          result?.data?.secondaryColor !== "undefined"
            ? result?.data?.secondaryColor
            : "#8FA280",
        backgroundColor:
          result?.data?.backgroundColor !== "undefined"
            ? result?.data?.backgroundColor
            : "#36473F",
        highlightColor:
          result?.data?.highightColor !== "undefined"
            ? result?.data?.highightColor
            : "#8FA280",
        fontColor:
          result?.data?.fontColor !== "undefined"
            ? result?.data?.fontColor
            : "#3a3a3a",
      });
      changeTheme({
        primaryColor:
          result?.data?.primaryColor !== "undefined"
            ? result?.data?.primaryColor
            : "#36473F",
        secondaryColor:
          result?.data?.secondaryColor !== "undefined"
            ? result?.data?.secondaryColor
            : "#8FA280",
        backgroundColor:
          result?.data?.backgroundColor !== "undefined"
            ? result?.data?.backgroundColor
            : "#36473F",
        highlightColor:
          result?.data?.highightColor !== "undefined"
            ? result?.data?.highightColor
            : "#8FA280",
        fontColor:
          result?.data?.fontColor !== "undefined"
            ? result?.data?.fontColor
            : "#3a3a3a",
      });
      setLogoLoading(false);
    }
  };

  const onClickLocation = (id) => {
    localStorage.setItem("location_id", id);

    if (isMobile) {
      history.push("/front/mobile-home");
    } else {
      history.push("/frontRating");
    }
  };
  const getLocations = async () => {
    setLoading(true);
    const id = localStorage.getItem("company_id");
    const tagId = localStorage.getItem("tag_id");

    const body = {
      tagId: tagId,
      companyId: id,
      lat: latLong.lat,
      long: latLong.long,
      api_version: "v2",
      source: "web",
    };

    if (locationStatus === BLOCKED) {
      body.locationBlocked = true;
    }
    const result = await postData("front/getLocations", body);

    if (result?.success === "true") {
      setLoading(false);
      setALLLocations(result.locations);

      const newResult = result.locations;
      setLocations(newResult);
    } else {
      toast.error(intl.formatMessage({ id: "fetch-details-failed" }));
    }
  };
  useEffect(async () => {
    getLatLong(setlatLong, setLocationStatus, setShowAllLocation);
    const companyId = localStorage.getItem("company_id") ?? null;
    const tagId = localStorage.getItem("tag_id") ?? null;
    await setBrandDetails({ company_id: companyId, tag_id: tagId });
  }, []);
  useEffect(async () => {
    if (locationStatus !== UNKNOWN) await getLocations();
  }, [locationStatus]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = ALLlocations.filter((item) => {
        const filteredObj = {
          name: item.name,
          address_1: item.address_1,
          address_2: item.address_2,
        };
        return Object.values(filteredObj)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setFilteredResults(filteredData);
    } else {
      setFilteredResults(locations);
    }
  };
  return (
    <>
      {/* <CssBaseline/> */}
      <LoadingOverlay
        active={loading || logoLoading}
        spinner
        styles={{
          content: {
            height: "100vh",
            display: "flex",
            width: "100vw",
          },
        }}
      >
        <Background>
          <div className={commonPageCss.logo}></div>

          {/* pop up work */}
          {!showAllLocations ? (
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 ">
              <div class="mb-4 card">
                <div class="card-body">
                  <div class="text-center">
                    {locationStatus === UNKNOWN || loading || logoLoading ? (
                      <>
                        <div class="mb-1 card-subtitle">
                          {" "}
                          <h2 class="mt-4">
                            {intl.formatMessage({ id: "please-wait" })}
                          </h2>
                        </div>
                        <p class="text-muted text-small my-4 pt-2 card-text">
                          {intl.formatMessage({ id: "loading-data" })}
                        </p>
                      </>
                    ) : (
                      ""
                    )}

                    {locationStatus === ALLOWED && !(loading || logoLoading) ? (
                      <>
                        <div class="mb-1 card-subtitle">
                          {" "}
                          <h4 className="mt-4">
                            {intl.formatMessage({ id: "is-this-your-location" })}
                          </h4>
                        </div>
                        <div className="m-4">
                          <LocationCard
                            title={locations[0]?.name}
                            address1={locations[0]?.address_1}
                            address2={locations[0]?.address_2}
                          />
                        </div>

                        <div className="m-2 mt-4 row d-felx justify-content-between">
                          <div className="col-6  ">
                            <button
                              style={{
                                float: "left",
                                padding: "8px 45px",
                                background: commpanyDetails?.data?.primaryColor,
                                borderRadius: "5px",
                                border: `2px solid ${commpanyDetails?.data?.primaryColor}`,
                              }}
                              className="btn-success"
                              onClick={() => {
                                onClickLocation(locations[0]?._id);
                                props.setFrontratingAnsweres_({
                                  key: "location_id",
                                  value: locations[0]?._id,
                                });
                              }}
                            >
                              {intl.formatMessage({ id: "yes" })}
                            </button>
                          </div>
                          <div
                            className="col-6 "
                            style={{ width: "auto !important" }}
                          >
                            <button
                              style={{
                                float: "right",
                                padding: "8px 45px",
                                color: commpanyDetails?.data?.primaryColor,
                                border: `2px solid ${commpanyDetails?.data?.primaryColor}`,
                                borderRadius: "5px",
                              }}
                              onClick={() => setShowAllLocation(true)}
                            >
                              {intl.formatMessage({ id: "no" })}
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {showAllLocations && locationStatus !== UNKNOWN ? (
            <PaperContainer logo={logo}>
              <Box style={{ paddingLeft: 5, paddingTop: 5 }}>
                <Typography variant="h4" className={commonPageCss.pageTitle}>
                  {intl.formatMessage({ id: "location" })}
                </Typography>
                <Typography className={commonPageCss.pageDesc}>
                  {intl.formatMessage({ id: "select-loction-to-ratings" })}
                </Typography>
              </Box>
              <Box>
                <input
                  style={{
                    border: `2px solid ${theme.palette.primary.main}`,
                    borderRadius: "1rem",
                    marginBottom: "15px",
                    padding: "0px 20px",
                    height: "52px",
                    width: "100%",
                  }}
                  fullWidth
                  icon="search"
                  placeholder={`${intl.formatMessage({ id: "search" })}...`}
                  onChange={(e) => searchItems(e.target.value)}
                />
              </Box>
              {searchInput.length > 1 ? (
                <Grid container spacing={2} style={{ padding: 5 }}>
                  {filteredResults.map((item) => {
                    return (
                      <Grid
                        key={Math.random()}
                        item
                        xs={12}
                        md={6}
                        onClick={() => {
                          onClickLocation(item._id);
                          props.setFrontratingAnsweres_({
                            key: "location_id",
                            value: item._id,
                          });
                        }}
                      >
                        <LocationCard
                          title={item.name}
                          distance={item.distance}
                          address1={item.address_1}
                          address2={item.address_2}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Grid container spacing={2} style={{ padding: 5 }}>
                  {locations.map((item) => {
                    return (
                      <Grid
                        key={Math.random()}
                        item
                        xs={12}
                        md={6}
                        className="asish"
                        onClick={() => {
                          onClickLocation(item._id);
                          props.setFrontratingAnsweres_({
                            key: "location_id",
                            value: item._id,
                          });
                        }}
                      >
                        <LocationCard
                          title={item.name}
                          distance={item.distance}
                          address1={item.address_1}
                          address2={item.address_2}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </PaperContainer>
          ) : (
            " "
          )}
        </Background>
      </LoadingOverlay>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    tags: state.survey.tags,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    setSurveyTheme_: (data) => dispatch(setSurveyTheme(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RatingLocations);
