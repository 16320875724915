import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import * as reportStyles from "./Reportstyle.module.css";
import reportStyles from "./Reportstyle.module.css";
import StatsCard from "./common/StatsCard";
import PerformanceCard from "./common/LgCard/PerformanceCard";
import ActionPlanCard from "./common/LgCard/ActionPlanCard";
import PerformanceFocusCard from "./common/LgCard/PerformanceFocusCard";
import TrendResults from "./components/TrendResults";
import CustomerFeedBack from "./components/CustomerFeedBack";
import TrendedNps from "./components/TrendedNps";
import LocationVsAll from "./components/LocationVsAll";
import ResponseBreakdown from "./components/ResponseBreakdown";
import LoadingOverlay from "react-loading-overlay";
import AiSuggestions from "./components/AiSuggestions";
import {
  fetchActionPlanData,
  fetchAiSuggestions,
  fetchAttributeData,
  fetchLatestReviews,
  fetchLocationData,
  fetchNegativeSkills,
  fetchNpsChart,
  fetchParams,
  fetchPrimaryStats,
  fetchRatingChart,
  fetchRatingDistribution,
  fetchReportDetails,
} from "./requests";
import { toast } from "react-toastify";
import { ReactComponent as NpsSvg } from "../assets/icons/nps.svg";
import { ReactComponent as ScorePeriodSvg } from "../assets/icons/score_during_period.svg";
import { ReactComponent as TotalResponseSvg } from "../assets/icons/total_responses.svg";
import { ReactComponent as YtdSvg } from "../assets/icons/ytd_score.svg";
import { useIntl } from "react-intl";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const NewReport = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [aiLoading, setAiLoading] = useState(true);

  const [reportData, setReportData] = useState({
    allLocations: [],
    logo: "",
    color: "",
    topNegativeSkill: { name: "None", count: 0 },
    startDate: "",
    endDate: "",
    dateDiff: 0,
    npsScore: { current: 0, previous: 0 },
    totalResponses: { current: 0, previous: 0 },
    responseAverage: { current: 0, previous: 0 },
    ytd: 0,
    latestReviews: [{ feedback: "", createdAt: "" }],
    npsGraph: [],
    ratingDistribution: { distribution: [], countDistribution: [] },
    locationVSallLocation: [],
    actionPlan: [{ title: [], description: [], createdAt: [] }],
    attributeData: { SkillCount: [], SkillName: [], skillComment: [] },
    ratingGraph: [{ count: [], date: [] }],
    locationCategoryData: [],
    aiSuggestions: null,
    userName: null,
  });
  const [reportDataFetched, setReportDataFetched] = useState(false);

  const query = useQuery();
  const getSearchParams = () => {
    let user_id = query.get("id") ?? "";
    let dates = query.get("date")?.split("/") ?? [];
    let start_date = dates.length > 0 ? dates[0] : "";
    let end_date = dates.length > 1 ? dates[1] : "";
    return { user_id, start_date, end_date };
  };

  const updateReportData = ({ key = null, value = null }) => {
    if (key === null) {
      return;
    }
    setReportData((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const getAllData = async () => {
      const body = getSearchParams();
      const params = await fetchParams(body);

      if (params) {
        setLoading(true);

        Promise.all([
          fetchReportDetails(params, updateReportData),
          fetchNegativeSkills(params, updateReportData),
          fetchPrimaryStats(params, updateReportData),
          fetchRatingChart(params, updateReportData),
          fetchLatestReviews(params, updateReportData),
          fetchNpsChart(params, updateReportData),
          fetchRatingDistribution(params, updateReportData),
          fetchLocationData(params, updateReportData),
          fetchAttributeData(params, updateReportData),
          fetchActionPlanData(params, updateReportData),
        ]).then(() => {
          setLoading(false);
          setReportDataFetched(true);
        });
      } else {
        toast.error(intl.formatMessage({ id: "error-fetching-params" }));
      }
    };

    getAllData();

    return () => {
      setReportDataFetched(false);
    };
  }, []);

  useEffect(() => {
    const getAiData = async () => {
      const body = getSearchParams();
      const params = await fetchParams(body);

      if (params) {
        const aiParams = { ...params, data: reportData };
        fetchAiSuggestions(aiParams, updateReportData).then(() =>
          setAiLoading(false)
        );
      }
    };

    const timeoutId = setTimeout(() => {
      if (reportDataFetched === true) {
        getAiData();
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [reportDataFetched]);

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{ content: { height: "100vh", display: "flex", width: "100vw" } }}
    >
      <div class={reportStyles.container}>
        {/* Brand Logo */}
        {/* <div class={reportStyles.divrow}>
          <div class={reportStyles.logoimg}>
            <img src={reportData.logo} alt="Brand Logo" />
          </div>
        </div> */}

        <div class={reportStyles.grid}>
          {/* Brand Logo */}
          <div class={reportStyles.logoCard}>
            <div class={reportStyles.logoimg}>
              <img src={reportData.logo} alt="Brand Logo" />
            </div>
          </div>

          {/* Focus of the month */}
          <div class={reportStyles.divbackground1}>
            <i
              class={`fa-solid fa-magnifying-glass-chart ${reportStyles.iconGrid}`}
              style={{ color: reportData.color }}
            ></i>
            <div>
              <h3 class={reportStyles.heading}>
                {intl.formatMessage({ id: "focus-for-the-month" })}
              </h3>
              <ul className="ml-3">
                {reportData.topNegativeSkill.name ? (
                  <>
                    <li className={reportStyles.keyPoints}>
                      {reportData.topNegativeSkill.name}
                    </li>
                    <li className={reportStyles.keyPoints}>
                      {reportData.topNegativeSkill.count}{" "}
                      {intl.formatMessage({
                        id: "customers-have-stated-this-as-an-opportunity",
                      })}
                    </li>
                  </>
                ) : (
                  "-"
                )}
              </ul>
            </div>
          </div>

          {/* Duration */}
          <div class={reportStyles.dateCard}>
            <i
              class={`fa-regular fa-calendar-days ${reportStyles.iconGrid}`}
              style={{ color: reportData.color }}
            ></i>
            <div>
              <h3 class={reportStyles.heading} style={{ margin: "0" }}>
                {intl.formatMessage({ id: "duration" })}
              </h3>
              <p class={reportStyles.datePara}>
                {reportData.startDate} - {reportData.endDate}
              </p>
            </div>
          </div>
        </div>

        {/* Stats */}
        <div class={reportStyles.grid1}>
          <StatsCard
            icon={NpsSvg}
            title={intl.formatMessage({ id: "net-promoter-score" })}
            percent={`${Math.round(reportData.npsScore.current * 10) / 10}%`}
            para={intl.formatMessage({
              id: "previous-days-was",
              days: reportData.dateDiff,
              score: reportData.npsScore.previous,
            })}
            color={reportData.color}
          />

          <StatsCard
            icon={TotalResponseSvg}
            title={intl.formatMessage({ id: "total-responses" })}
            percent={Math.round(reportData.totalResponses.current * 10) / 10}
            para={intl.formatMessage({
              id: "previous-days-was",
              days: reportData.dateDiff,
              score: reportData.totalResponses.previous,
            })}
            color={reportData.color}
          />

          <StatsCard
            icon={ScorePeriodSvg}
            title={intl.formatMessage({ id: "score-during-period" })}
            percent={Math.round(reportData.responseAverage.current * 10) / 10}
            para={intl.formatMessage({
              id: "previous-days-was",
              days: reportData.dateDiff,
              score: reportData.responseAverage.previous,
            })}
            color={reportData.color}
          />

          <StatsCard
            icon={YtdSvg}
            title={intl.formatMessage({ id: "ytd-score" })}
            percent={`${Math.round(reportData.ytd * 10) / 10}%`}
            color={reportData.color}
          />
        </div>

        {/* Ai Suggestion */}
        {reportDataFetched === true ? (
          <div class={reportStyles.divbackground5}>
            <AiSuggestions
              loading={aiLoading}
              aiSuggestions={reportData.aiSuggestions}
              userName={reportData.userName}
            />
          </div>
        ) : null}

        {/* Chart Section 1 */}
        <div class={reportStyles.grid2}>
          <TrendResults data={reportData.ratingGraph} />
          <CustomerFeedBack
            data={reportData.latestReviews}
            color={reportData.color}
          />
        </div>

        {/* Did you know */}
        <div class={reportStyles.divbackground5}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                paddingRight: "10px",
              }}
            >
              <i
                class={`fa-regular fa-lightbulb ${reportStyles.iconGrid}`}
                style={{ color: reportData.color }}
              ></i>
            </div>
            <div>
              <h3 class={reportStyles.heading2}>
                {intl.formatMessage({ id: "did-you-know" })}
              </h3>

              <p class={reportStyles.para2}>
                <span>
                  {intl.formatMessage({ id: "net-promoter-score-info" })}
                </span>{" "}
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a href="#" style={{ color: reportData.color }}>
                    {intl.formatMessage({ id: "link" })}
                  </a>
                }
              </p>
            </div>
          </div>
        </div>

        {/* Performance Section */}
        <div style={{ marginTop: "50px", marginBottom: "10px" }}>
          <div class={reportStyles.divbackground4}>
            <h3 class={reportStyles.onlyheading}>
              {intl.formatMessage({ id: "performance" })}
            </h3>
          </div>
        </div>

        {/* NPS chart */}
        <div class={reportStyles.grid2}>
          <TrendedNps data={reportData.npsGraph} />
          <ResponseBreakdown data={reportData.ratingDistribution} />
        </div>

        {/* Rating Distribution */}
        <div class={reportStyles.gridFull}>
          <LocationVsAll
            data={{
              locations: reportData.locationVSallLocation,
              allLocations: reportData.allLocations,
            }}
          />
        </div>

        {/* Performance Card */}
        <div class={reportStyles.grid3}>
          <PerformanceCard
            mainTitle={intl.formatMessage({ id: "performance" })}
            color={reportData.color}
            data={reportData.attributeData}
          />
        </div>

        <ActionPlanCard
          mainTitle={intl.formatMessage({ id: "action-plan" })}
          color={reportData.color}
          data={reportData.actionPlan}
        />

        <div class={reportStyles.grid3}>
          <PerformanceFocusCard
            mainTitle={intl.formatMessage({ id: "performance" })}
            color={reportData.color}
            data={reportData.locationCategoryData}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default NewReport;
