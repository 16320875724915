module.exports = {
  // Common words
  enter: "Girmek",
  next: "İleri",
  previous: "Geri",
  finish: "Bitir",
  returnToWebsite: "Web sitesine dön",
  complete: "Tamamla",
  submit: "Gönder",
  cancel: "İptal",
  yes: "Evet",
  no: "Hayır",
  skip: "Atla",
  close: "Kapat",
  discard: "Atla",
  save: "Kaydet",
  "thank-you": "Teşekkürler",
  select: "Seç",
  hi: "Merhaba",
  hello: "Merhaba",
  link: "Link",
  back: "Geri",
  view: "Görüntüle",
  continue: "Devam Et",

  // Client Survey Main
  "client-survey-main-thanks-note":
    "Zaman ayırdığınız için teşekkürler, geri bildiriminiz şirketimizin geleceği için bir plan yapmamızı sağlıyor.",
  "client-survey-how-likely-recommend":
    "Diğerlerine bize tavsiye eder misiniz?",
  "client-survey-how-likely-note":
    "0 ile 10 arasında bir skalada, 0'a yakın olma olasılığınız 10'a yakın olma olasılığından daha azdır.",
  "client-survey-how-likely-recommend-question":
    "Bizimle ilgili daha fazla tavsiye etmeye yönelik bir şeyler yapabilir miyiz?",
  "step-of": "{step}. Adım / {total} Adım",
  step: "{step}. Adım",
  "client-survey-how-satisfied-with-service":
    "Hizmetinizin ne kadar memnuniyet verdiğini düşünüyorsunuz?",
  "client-survey-how-satisfied-with-service-question":
    "Bizimle ilgili daha fazla tavsiye etmeye yönelik bir şeyler yapabilir miyiz?",
  "client-survey-how-satisfied-with-food":
    "Hizmetinizin ne kadar memnuniyet verdiğini düşünüyorsunuz?",
  "client-survey-how-satisfied-with-food-question":
    "Bizimle ilgili daha fazla tavsiye etmeye yönelik bir şeyler yapabilir miyiz?",
  "client-survey-what-is-the-best-thing-about-working-with-us":
    "İlgili daha fazla tavsiye etmeye yönelik bir şeyler yapabilir miyiz?",
  "management-team": "Yönetim Ekibi",
  "the-front-line-operations-team": "İlk Satır Operasyon Ekibi",
  "food-offering": "Yemek Teklif",
  innovation: "İnovasyon",
  communication: "İletişim",
  "flexibility-and-responsiveness": "Esneklik ve Cevap Verilme",
  billing: "Faturalama",
  "health-and-hygiene-standards": "Sağlık ve Temizlik Standartları",
  "if-other-please-specify": "Diğer ise belirtin...",
  "based-on-the-service-we-provide-where-can-we-improve-the-most-please-select-all-that-matters":
    "Bizimle ilgili daha fazla tavsiye etmeye yönelik bir şeyler yapabilir miyiz?",
  "please-can-we-have-some-more-detail": "Lütfen daha fazla ayrıntı veriniz.",
  "to-help-us-understand-your-csr-priorities-please-order-in-rank-of-importance-the-following":
    "CSR önceliklerinizi anlamamıza yardımcı olmak için lütfen aşağıdaki öncelik sırasına göre sıralayınız:",
  "sustainable-ingredients": "Sürdürülebilir İçerikler",
  "food-waste": "Yemek Atıkları",
  "local-sourcing": "Yerel Kaynaklama",
  "people-and-communities": "İnsanlar ve Topluluklar",
  "energy-reduction": "Enerji Azaltma",
  "working-with-local-communities": "Yerel Topluluklarla Çalışma",
  "environmental-choices": "Çevresel Seçimler",
  "meat-free": "Et Yok",
  "less-plastic": "Daha Az Plastik",
  "would-you-like-to-be-contacted-about-your-feedback":
    "Geri bildiriminize ilişkin bilgi almak ister misiniz?",
  "full-name": "Tam Adınız",
  "email-address": "Email Adresiniz",
  "phone-number": "Telefon Numaranız",
  feedback: "Geri Bildirim",
  "view-our-privacy-policy": "Gizlilik Politikamızı Görüntüle",
  "thank-you-for-taking-time-to-complete-the-survey":
    "Geri bildiriminize ilişkin bilgi almak ister misiniz?",

  // 404 Not Found
  "404-not-found": "404 Bulunamadı",

  // Privacy Policy
  "privacy-policy": "Gizlilik Politikamız",

  // Employee Web Rating Feedback Step 1
  "employee-web-rating-feedback-step-1-positive":
    "Mutlu olduğunuzu duyuyoruz, neden olduğunu merak ediyoruz.",
  "employee-web-rating-feedback-step-1-negative":
    "Ne yapabiliriz ki mutlu olmanıza yardımcı olabiliriz?",

  // Employee Web Rating Feedback Step 2
  "employee-web-rating-feedback-step-2-heading":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",
  "employee-web-rating-feedback-step-2-information":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",
  "employee-web-rating-feedback-step-2-placeholder":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",

  // Employee Web Rating Feedback Step 3
  "employee-web-rating-feedback-step-3-heading":
    "Kullanıcılarınıza teşekkür etmek ister misiniz?",
  "no-employees-found": "Çalışan bulunamadı",

  // Employee Web Rating Home
  "choose-location": "Konum Seç",
  "search-location": "Konum Ara",
  "feedback-anonymous": "Geri bildiriminizi gizlemek ister misiniz?",
  "feedback-feeling-today": "Bugün nasıl hissettiğinizi merak ediyoruz.",

  // Employee Web Rating Thankyou Page
  "thank-you-for-your-feedback": "Geri bildiriminize teşekkürler!",
  "back-to-home": "Ana Sayfaya Dön",
  page: "Sayfa",

  // Feedback Once Alert
  "feedback-once-alert": "Geri bildirim bir kere verildi.",

  // Front Customer Audit
  "please-answer-above-question": "Lütfen yukarıdaki soruyu cevaplayınız.",
  "add-comments": "Yorum Ekle",
  "enter-answer": "Cevap Giriniz",
  "add-upload-files": "Dosya Ekle",

  // Alphega Questions
  "did-you-buy-medicine-in-the-pharmacy-today":
    "Bugün eczanede ilaç aldınız mı?",
  "were-you-asked-any-of-the-following":
    "Aşağıdakilerden biriyle konuşuldu mu:",
  "who-is-the-medicine-for": "İlaç kim için?",
  "what-are-your-symptoms": "Semptomlarınız neler?",
  "how-long-you-had-the-symptoms": "Semptomlarınız ne kadar sürdü?",
  "what-action-you-have-already-taken": "Zaten yaptığınız işlemler neler?",
  "are-you-taking-any-other-medication": "Başka bir ilaç alıyor musunuz?",

  // Front Mobile Home
  "how-was-your-experience-with-us-today":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",

  // Front Mobile Thankyou Page
  "thank-you-for-your-time-and-your-answers":
    "Zaman ayırdığınız için teşekkürler, geri bildiriminiz şirketimizin geleceği için bir plan yapmamızı sağlıyor.",
  "would-also-like-to-rate-us-on-google":
    "Google'da bize değerlendirmek ister misiniz?",
  "rate-us-now": "Şimdi değerlendir",

  // Negative Chat Reply
  "select-skill": "Yetenek Seç",
  "none-selected": "Seçilmedi",
  "can-we-get-in-touch-with-you-about-your-experience-today":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",
  "write-here": "Buraya yazın...",
  "please-can-we-get-your-name": "Adınızı bize söyleyebilir misiniz?",
  "please-enter-your-email": "Email adresinizi bize söyleyebilir misiniz?",
  "enter-your-phone-number": "Telefon numaranızı bize söyleyebilir misiniz?",
  "please-tell-us-a-bit-more-about-your-experience":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",
  "you-have-already-submitted-rating": "Geri bildirim zaten verildi.",
  "sorry-to-hear-you-didn-t-have-the-best-experiance-today-with-us-please-could-you-highlight-what-went-wrong":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",

  // Positive Chat Reply
  "select-employee": "Çalışan Seç",
  "we-d-love-to-know-who-looked-after-you-today":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",
  "what-stood-out-to-you-today":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",
  "did-we-provide-exceptional-service-today":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",
  "do-you-have-any-comments-or-feedback-about-your-experience-with-us-today":
    "Bugün bize bir şeyler paylaşmak ister misiniz?",

  // Client Connect Director
  "message-from-leadership": "Liderlikten Mesaj",
  "message-from-leadership-text":
    "Hizmetlerimizi müşterilerimiz için daha iyi hale getirmek için sürekli yollar arıyorum. Sizin görüşünüze göre hangi alanlarda daha iyi olabiliriz?",
  "client-connect-director-another-note":
    "Başka bir not olarak, ekibimizin ele almasını istediğiniz belirli bir konu varsa, çekinmeden paylaşabilirsiniz. Sadece dinlemekle kalmıyorum, aynı zamanda endişelerinize veya fikirlerinize göre harekete geçiyorum.",
  "your-message-here": "Buraya mesajınızı yazın",
  "visit-information": "Ziyaret Bilgisi",
  "visit-time-out": "Ziyaret Zaman Aşımı",
  "contact-information": "İletişim Bilgileri",
  "client-connect-director-contact-information-text":
    "Daha iyi hizmet verebilmemiz için, adınız, e-posta adresiniz ve iletişim numaranız gibi bilgilerinizi rica ediyoruz. Bu bilgiler, size hızlı bir şekilde yanıt vermemizi ve sizinle iletişime geçmemizi sağlayacaktır.",
  name: "Adınız",
  "enter-your-name": "Adınızı bize söyleyebilir misiniz?",
  email: "Email",
  "enter-your-email": "Email adresinizi bize söyleyebilir misiniz?",
  "contact-number": "Telefon Numarası",
  "please-enter-your-name": "Lütfen adınızı bize söyleyebilir misiniz?",
  "please-enter-your-email-or-phone-number":
    "Lütfen email adresinizi veya telefon numaranızı bize söyleyebilir misiniz?",
  "invalid-email-address": "Geçersiz email adresi",
  "invalid-phone-number-format":
    "Geçersiz telefon numarası formatı. İzin verilen formatlar: +44 sonrası 10 basamak.",
  "please-select-time": "Lütfen zaman seçiniz",
  "you-must-provide-at-least-one-answer-before-submitting-the-survey":
    "En az bir cevap vermeden önce anketi gönderemezsiniz.",

  // Mobile Survey Questions CheckBoxType
  comment: "Yorum",
  "comment-is-required": "Yorum gereklidir!",
  "minimum-selection-is": "Minimum seçim {min_options}",
  "maximum-selection-is": "Maksimum seçim {max_options}",
  "this-question-is-required": "Bu soru gereklidir!",

  // Mobile Survey Questions CommentsAndImage
  "please-select-valid-image-files": "Lütfen geçerli resim dosyası seçiniz.",

  // Mobile Survey Questions EmployeeType
  loading: "Yükleniyor",
  "getting-employee": "Çalışan alınıyor...",

  // Mobile Survey Questions FileUploadType
  "please-select-valid-image-or-video-files":
    "Lütfen geçerli resim veya video dosyası seçiniz.",

  // Mobile Survey Questions NumberType
  "please-enter-a-number-without-any-decimal-points":
    "Lütfen ondalık noktalar olmadan bir sayı giriniz.",
  "please-enter-a-number-less-than-or-equal-to":
    "Lütfen {max_number_value} veya daha küçük bir sayı giriniz.",

  // Mobile Survey Questions SignatureType
  "please-complete-your-signature": "Lütfen imzanızı tamamlayınız.",

  // Mobile Survey Questions TextType
  "please-enter-valid-email-address": "Lütfen geçerli email adresi giriniz.",
  "phone-number-should-be-10-digit": "Telefon numarası 10 basamak olmalıdır.",
  "please-enter-at-least-characters":
    "Lütfen en az {minimum_character} karakter giriniz.",
  "select-date": "Tarih Seç",
  "select-time": "Zaman Seç",
  "select-country": "Ülke Seç",

  // Report Page
  "click-the-link-to-see-how":
    "Linki tıklayarak nasıl görüntüleyeceğinizi görün.",
  "adding-your-teams-to-the-ServeFirst-reporting-platform":
    "Bu platformu kullanarak ekibinizi ekleyerek, ekibinizin performansını görüntüleyebilir, ayrıca ekibinizi yönetme ve genel performansınızı anlamak için ekstra raporlara erişebilirsiniz -",
  "did-you-know": "bildiniz mi..",
  count: "sayı",
  average: "ortalama",
  focus: "odak",
  performance: "Performans",
  "you-can-create-bespoke-action-plans":
    "Kendi ServeFirst hesabınızda oluşturulmuşsa burada doğrudan filtrelenebilen özel eylem planları oluşturabilirsiniz, burada eylemler, takvimler ve sorumluları anında oluşturabilirken performansı takip edebilirsiniz.",
  "no-of-times-raised": "Yükseltildiği Zaman Sayısı",
  action: "Eylem",
  "attribute-name": "Özellik Adı",
  "action-plan": "Eylem Planı",
  "that-by-adding-your-teams-to-the-ServeFirst-reporting-platform":
    "Ekiplerinizi ServeFirst raporlama platformuna ekleyerek onların da giriş yapıp performanslarını görmesini sağlayabilirsiniz, ayrıca ekibinizi yönetme ve genel performansınızı anlamak için ek raporlara erişim sağlama yeteneğiniz de vardır.",
  result: "Sonuç",
  category: "Kategori",
  "your-location-vs-all-location": "Konumunuz vs. Tüm Konumlar",
  "net-promoter-score": "Net Promotör Puanı",
  "responses-breakdown": "Yanıt Dağılımı",
  "trended-net-promoter-score": "Eğilimli Net Promotör Puanı",
  "trended-results": "Eğilimli Sonuçlar",
  "net-promoter-score-info":
    "Net Promotör Puanı, promotörlerin yüzdesi (5/5 puan alanlar) ile detractorlerin yüzdesi (1, 2 veya 3 puan alanlar) arasındaki farkı temel alır, Net Promotör Puanı hakkında daha fazla bilgi edinmek için aşağıdaki bağlantıya tıklayabilirsiniz",
  "customer-feedback": "Müşteri Geri Bildirimi",
  logan: "Logan",
  "top-performer": "En İyi Performans Gösteren",
  "rank-in-the-country": "Ülkedeki Sıralama",
  "ytd-score": "YTD Puanı",
  "score-during-period": "Dönem Boyunca Puan",
  "0-from-last-month": "Geçen Aydan 0",
  "total-responses-for-july-2021": "Temmuz 2021 İçin Toplam Yanıtlar",
  promoter: "Promotör",
  "from-04-sep-2021-to-06-sep-2021": "04 Eyl 2021'den 06 Eyl 2021'e kadar",
  "the-focus-currently-is-on": "Şu anda odaklanılan şey",
  "1-number-of-customers-have-stated-this-as-an-opportunity":
    "1 müşteri bunu bir fırsat olarak belirtmiştir.",
  "focus-for-the-month": "Ayın Odak Noktası",
  "download-pdf": "PDF İndir",

  // Report Page New Report
  "total-responses": "Toplam Yanıtlar",
  duration: "Süre",
  "customers-have-stated-this-as-an-opportunity":
    "Müşteriler bunu bir fırsat olarak belirtmişlerdir",
  "error-fetching-params": "Parametreler alınırken hata oluştu!",
  "previous-days-was": "Önceki {days} gün {score} idi",

  // Report Page Common LgCard
  "raised-on": "# Yükseltildiği Tarih",
  results: "Sonuçlar",

  // Report Page Components AiSuggestions
  "analyzing-data-please-wait": "Veriler analiz ediliyor, lütfen bekleyin...",

  // Report Page Components CustomerFeedBack
  "view-report": "Raporu Görüntüle",
  "received-on": "Alındığı Tarih",

  // Report Page Components ResponseBreakdown
  detractors: "Detraktörler",
  nps: "NPS",

  // Report Page Components TrendResults
  "trend-results": "Eğilim Sonuçları",

  // Survey helpers Utils
  "failed-to-fetch-the-pdf-file": "PDF dosyası alınamadı",
  "error-generating-pdf-thumbnail":
    "PDF küçük resmi oluşturulurken hata oluştu",
  "error-in-thumbnail-generation-function":
    "Küçük resim oluşturma fonksiyonunda hata",
  "failed-to-generate-pdf-thumbnail": "PDF küçük resmi oluşturulamadı",

  // Survey Pages Audition
  "select-audit": "Denetim Seç",

  // Survey Pages ClientConnect
  "select-atleast-min-options": "En az {minOptions} seçenek seçin",
  "select-max-options": "Maksimum {maxOptions} seçenek izin verilmektedir!",
  "error-saving-your-response": "Yanıtınızı kaydederken hata oluştu!",
  "failed-to-load-the-file": "Dosya yüklenemedi!",
  "open-link": "Bağlantıyı aç",
  download: "İndir",
  details: "Detaylar",
  title: "Başlık",
  description: "Açıklama",
  "general-info": "Genel Bilgiler",
  type: "Tür",
  size: "Boyut",
  "uploaded-by": "Yükleyen",

  // Survey Pages ClientConnect Intro
  "thank-you-for-your-time": "Zamanınızı ayırdığınız için teşekkür ederiz!",

  // Survey Pages ClientConnect Content
  "review-content": "İçeriği gözden geçirin",

  // Survey Pages ClientConnect Survey
  "please-give-us-your-feedback": "Lütfen geri bildiriminizi verin",
  "enter-your-response": "Yanıtınızı girin",

  // Survey Pages ClientConnect Director
  "i-am-constantly-seeking-ways-to-better-our-offerings-for-our-clientele":
    "Müşterilerimize sunduğumuz hizmetleri sürekli olarak iyileştirmenin yollarını arıyorum. Görüşünüze göre daha iyi yapabileceğimiz yönler var mı?",
  "on-another-note-if-there-s-a-specific-topic-you-d-like-our-team-to-address-don-t-hesitate-to-share":
    "Başka bir konu olarak, ekibimizin ele almasını istediğiniz özel bir konu varsa, çekinmeden paylaşın. Sadece dinlemek için burada değilim, endişeleriniz veya fikirleriniz üzerinde çalışmak için de buradayım.",
  "to-provide-enhanced-assistance-we-kindly-ask-for-your-details-including-your-name-email-address-and-contact-number":
    "Daha iyi yardımcı olabilmemiz için adınızı, e-posta adresinizi ve iletişim numaranızı içeren bilgilerinizi rica ediyoruz. Bu bilgiler, size hızlı bir şekilde geri dönüş yapmamızı ve sizinle iletişime geçmemizi sağlayacaktır.",

  // Survey Pages Location
  "select-the-location-you-visited": "Ziyaret ettiğiniz yeri seçin",
  locations: "Lokasyonlar",
  "is-this-your-current-location": "Bu, şu anki lokasyonunuz mu?",
  "nearest-location": "En yakın Lokasyon",
  "loading-data": "Veriler yükleniyor...",
  "please-wait": "Lütfen Bekleyin!",

  // Survey Pages Location Area
  "location-area": "Lokasyon Alanı",
  "select-location-area": "Lokasyon Alanı Seçin",

  // Survey Pages Login
  "please-enter-your-password": "Lütfen şifrenizi girin",
  "value-must-be-longer-than-3-characters":
    "Değer 3 karakterden uzun olmalıdır",
  "please-enter-your-email-address": "Lütfen e-posta adresinizi girin",
  password: "Şifre",
  "internal-server-error": "Sunucu Hatası!",
  "sign-in": "Giriş Yap",

  // Survey Pages SameDaySurvey
  "you-have-already-performed-this-survey": "Bu anketi zaten tamamladınız!",

  // Survey Pages SurveyThankYou
  "your-feedback-has-been-submitted":
    "Geri bildiriminiz gönderildi. Bilet numaranız #",
  "a-member-of-the-team-will-be-in-touch-shortly":
    "Bir ekip üyesi kısa süre içinde sizinle iletişime geçecektir.",
  "would-you-also-like-to-rate-us-on-google":
    "Bizi Google'da değerlendirmek ister misiniz?",
  "to-review-our-terms-and-conditions-please-click":
    "Hizmet Şartlarımızı gözden geçirmek için lütfen tıklayın",
  here: "Burada",
  "ticket-number-copied-to-clipboard": "Bilet numarası panoya kopyalandı",

  // Survey Pages Welcome
  "visit-date": "Ziyaret Tarihi",
  "visit-time-in": "Ziyaret Saati",
  "please-select-date": "Lütfen tarih seçin",
  "guest-info": "Misafir Bilgileri",
  "please-select-user": "Lütfen kullanıcı seçin",
  "get-started": "Başlayın",

  // Survey Pages InventoryType
  "enter-value": "Değeri girin",
  "getting-product-list": "Ürün listesi alınıyor...",

  // Survey Pages NotesAndImage
  "upload-image": "Resim yükle",

  // Survey Pages NumberType
  "enter-digit": "Rakamsal değer girin",

  // Survey Pages TextArea
  "enter-your-comment": "Yorumunuzu girin",

  // Survey Pages Upload
  "file-size-should-be-less-than-10-mb":
    "Dosya boyutu 10 MB'dan küçük olmalıdır",
  "drag-you-files-here-file-size-less-than-10-mb-or":
    "Dosyalarınızı buraya sürükleyin, dosya boyutu 10 MB'dan küçük veya",
  "browse-files": "Dosyaları gözden geçirin",

  // Survey Pages Questions
  "next-question": "Sonraki soru",
  "please-enter-at-least": "Lütfen en az {min} karakter girin.",
  "contact-no": "İletişim Numarası",
  "please-select-location": "Lütfen konum seçin!",
  "please-select-survey": "Lütfen anketi seçin!",
  "please-enter-your-email-address-or-phone-number":
    "Lütfen e-posta adresinizi veya telefon numaranızı girin.",

  // Utils CompressFiles
  "compression-error": "Sıkıştırma hatası:",

  // Web Rating FrontLogin
  "enter-location-id": "Konum ID'sini girin",
  "invalid-location-id": "Geçersiz Konum ID'si",
  "location-id-required": "Konum ID'si gereklidir",

  // Web Rating Index
  "please-tell-us-how-your-experience-was-today":
    "Lütfen bugün yaşadığınız deneyimi bize anlatın.",

  // Web Rating Negativefeedback
  "sorry-to-hear-you-didn-t-have-the-best-experience-today-with-us-please-could-you-highlight-what-went-wrong":
    "Bugün bizimle en iyi deneyimi yaşamadığınızı duyduğumuza üzüldük, lütfen neyin yanlış gittiğini belirtebilir misiniz?",
  "enter-valid-email-address": "Geçerli bir e-posta adresi girin.",
  "can-we-get-in-touch-with-you": "Sizinle iletişime geçebilir miyiz?",

  // Web Rating Positivefeedback
  "enter-your-telephone-number": "Telefon numaranızı girin",
  "write-your-feedback": "Geribildiriminizi yazın",

  // Web Rating Positivefeedback2
  "what-made-the-employee-name-stand-out-to-you":
    "{employeeName} sizin için neyiyle öne çıktı?",
  team: "Ekip",

  // Web Rating PositivefeedbackForm
  "please-go-to-previous-step-and-select-employee-without-employee-selection-not-able-to-submit-feedback-form":
    "Lütfen önceki adıma gidin ve bir çalışan seçin, çalışan seçimi olmadan geri bildirim formu gönderilemez",
  "share-us-your-experience-with-us-today":
    "Bugün bizimle deneyiminizi paylaşın...",
  "did-employee-name-provide-exceptional-service":
    "{employeeName} olağanüstü hizmet sundu mu?",
  "did-employee-name-stand-out-for-delivering-exceptional-service":
    "{employeeName} olağanüstü hizmet sunduğu için öne çıktı mı?",

  // Web Rating New apiHelpers loginHelpers
  "please-enter-user-name": "Lütfen kullanıcı adı girin",
  "please-enter-password": "Lütfen şifre girin",

  // Web Rating New RatingLocations
  search: "Ara",
  "select-loction-to-ratings": "Değerlendirmeler için konum seçin",
  location: "Konum",
  "is-this-your-location": "Bu sizin konumunuz mu?",
  "fetch-details-failed": "Detaylar alınamadı",

  // Web Rating New PositiveJourny EmployeDetail
  "click-as-many-as-you-want": "İstediğiniz kadar tıklayın",
  "what-made-employee-name-stand-out-to-you":
    "{employeeName} sizin için neyiyle öne çıktı?",
  "what-made-the-team-stand-out-to-you": "Ekip sizin için neyle öne çıktı?",

  // Web Rating New PositiveJourny EmployeService
  "did-we-stand-out-for-delivering-exceptional-service":
    "Olağanüstü hizmet sunduk mu?",

  // Web Rating New NegativeJourny EmployePersonalDetail
  "leave-an-email-or-phone-number-if-you-want-us-to-get-in-touch-otherwise-just-leave-it-blank-then-press-next":
    "Bizimle iletişime geçmek isterseniz e-posta veya telefon numarası bırakın, aksi takdirde boş bırakın ve sonra ileriye tıklayın",
  "anything-else-you-d-like-to-share":
    "Paylaşmak istediğiniz başka bir şey var mı?",
  "enter-valid-email": "Geçerli bir e-posta adresi girin",
  message: "Mesaj",
  "by-signing-up-the-form-you-agree-to-our-terms-and-privacy":
    "Formu doldurarak, Şartlar ve Gizlilik Politikamıza katıldığınızı kabul etmiş olursunuz.",

  // Web Rating New FrontWebLogin
  login: "Giriş Yap",
  username: "Kullanıcı Adı",

  // Web Rating New FrontRating
  "press-the-facial-expression-that-best-represents-how-you-feel-about-your-experience":
    "Deneyiminizle ilgili hislerinizi en iyi temsil eden yüz ifadesine basın.",
  "getting-employee-list": "Çalışan listesi alınıyor",
  "getting-skill-data": "Beceri verisi alınıyor",
  "getting-screen-saver": "Ekran koruyucu alınıyor",

  // Web Rating New CommonComponents ThankYouModal
  "thank-you-for-contacting-us":
    "Bizimle iletişime geçtiğiniz için teşekkür ederiz.",
  "we-ll-be-in-touch-very-soon": "Çok yakında sizinle iletişime geçeceğiz.",

  // Web Rating New CommonComponents GoogleQrPage
  "you-can-follow-this-button-to-rate-us-on-google":
    "Bizi Google'da değerlendirmek için bu düğmeye tıklayabilirsiniz",
  "rate-us-on-google": "Google'da bizi değerlendirin",

  // Web Rating New CommonComponents ContactUsModal
  "contact-us": "Bize Ulaşın",
  "or-you-may-reach-us-on":
    "...veya bizimle şu şekilde iletişime geçebilirsiniz.",
  "do-you-want-to-learn-more-about-serve-first":
    "Serve First hakkında daha fazla bilgi almak ister misiniz?",

  // Web Rating New CommonComponents BottomBar
  saving: "Kaydediliyor",
  "please-enter-only-digits-for": "Lütfen sadece rakamları girin",
  "please-enter": "Lütfen girin",
  "enter-value-here": "Buraya değer girin",
};
