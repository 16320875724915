module.exports = {
  // Common words
  enter: "Enter",
  next: "Next",
  previous: "Previous",
  finish: "Finish",
  returnToWebsite: "Return to Website",
  complete: "Complete",
  submit: "Submit",
  cancel: "Cancel",
  yes: "Yes",
  no: "No",
  skip: "Skip",
  close: "Close",
  discard: "Discard",
  save: "Save",
  "thank-you": "Thank You",
  select: "Select",
  hi: "Hi",
  hello: "Hello",
  link: "Link",
  back: "Back",
  view: "View",
  continue: "Continue",

  // Client Survey Main
  "client-survey-main-thanks-note":
    "Thanks for taking the time, your feedback shapes our offer <br /> for the future.",
  "client-survey-how-likely-recommend":
    "How likely would you be to recommend us to others?",
  "client-survey-how-likely-note":
    "On a scale 0 - 10, where 0 is not at all likely and 10 is extremely likely.",
  "client-survey-how-likely-recommend-question":
    "What is the one thing that we could to to make you more likely to recommend us?",
  "step-of": "Step {step} of {total}",
  step: "Step {step}",
  "client-survey-how-satisfied-with-service":
    "How satisfied are you with the service",
  "client-survey-how-satisfied-with-service-question":
    "What is the one thing that we could do to improve?",
  "client-survey-how-satisfied-with-food":
    "How satisfied are you with the food you receive from us?",
  "client-survey-how-satisfied-with-food-question":
    "What is the one thing that we could do to improve?",
  "client-survey-what-is-the-best-thing-about-working-with-us":
    "What is the best thing about working with us? Please select all that matters.",
  "management-team": "Management Team",
  "the-front-line-operations-team": "The front line operations team",
  "food-offering": "Food Offering",
  innovation: "Innovation",
  communication: "Communication",
  "flexibility-and-responsiveness": "Flexibility and Responsiveness",
  billing: "Billing",
  "health-and-hygiene-standards": "Health and Hygiene Standards",
  "if-other-please-specify": "If other, please specify...",
  "based-on-the-service-we-provide-where-can-we-improve-the-most-please-select-all-that-matters":
    "Based on the service we provide, where can we improve the most? Please select all that matters.",
  "please-can-we-have-some-more-detail": "Please can we have some more detail?",
  "to-help-us-understand-your-csr-priorities-please-order-in-rank-of-importance-the-following":
    "To help us understand your CSR priorities, please order in rank of importance the following:",
  "sustainable-ingredients": "Sustainable Ingredients",
  "food-waste": "Food Waste",
  "local-sourcing": "Local Sourcing",
  "people-and-communities": "People &amp; Communities",
  "energy-reduction": "Energy Reduction",
  "working-with-local-communities": "Working with local communities",
  "environmental-choices": "Environmental Choices",
  "meat-free": "Meat Free",
  "less-plastic": "Less Plastic",
  "would-you-like-to-be-contacted-about-your-feedback":
    "Would you like to be contacted about your feedback? If so, please leave your contact information below",
  "full-name": "Full Name",
  "email-address": "Email Address",
  "phone-number": "Phone Number",
  feedback: "Feedback",
  "view-our-privacy-policy": "View our Privacy Policy",
  "thank-you-for-taking-time-to-complete-the-survey":
    "Thank you for taking time to complete the survey!",

  // 404 Not Found
  "404-not-found": "404 Not Found",

  // Privacy Policy
  "privacy-policy": "Privacy Policy",

  // Employee Web Rating Feedback Step 1
  "employee-web-rating-feedback-step-1-positive":
    "Glad to hear you're feeling positive, we'd love to know why?",
  "employee-web-rating-feedback-step-1-negative":
    "Sorry to hear you're not feeling the best, please can you tell us why?",

  // Employee Web Rating Feedback Step 2
  "employee-web-rating-feedback-step-2-heading":
    "Is there anything else you'd like to share with us today?",
  "employee-web-rating-feedback-step-2-information":
    "Do you have any comments or feedback about your experience with us today?",
  "employee-web-rating-feedback-step-2-placeholder":
    "Share us your experience with us today...",

  // Employee Web Rating Feedback Step 3
  "employee-web-rating-feedback-step-3-heading":
    "Would you like to give kudos to someone?",
  "no-employees-found": "No Employees Found",

  // Employee Web Rating Home
  "choose-location": "Choose Location",
  "search-location": "Search Location",
  "feedback-anonymous": "Do you want to keep your feedback anonymous?",
  "feedback-feeling-today": "How are you feeling today?",

  // Employee Web Rating Thankyou Page
  "thank-you-for-your-feedback": "Thank You For Your Feedback!",
  "back-to-home": "Back To Home",
  page: "Page",

  // Feedback Once Alert
  "feedback-once-alert": "Feedback has been given once from this URL.",

  // Front Customer Audit
  "please-answer-above-question": "Please answer the above question.",
  "add-comments": "Add Comments",
  "enter-answer": "Enter Answer",
  "add-upload-files": "Add Upload Files",

  // Alphega Questions
  "did-you-buy-medicine-in-the-pharmacy-today":
    "Did you buy medicine in the pharmacy today?",
  "were-you-asked-any-of-the-following": "Were you asked any of the following:",
  "who-is-the-medicine-for": "Who is the medicine for?",
  "what-are-your-symptoms": "What are your symptoms?",
  "how-long-you-had-the-symptoms": "How long you had the symptoms?",
  "what-action-you-have-already-taken": "What action you have already taken?",
  "are-you-taking-any-other-medication": "Are you taking any other medication?",

  // Front Mobile Home
  "how-was-your-experience-with-us-today":
    "How was your experience with us today?",

  // Front Mobile Thankyou Page
  "thank-you-for-your-time-and-your-answers":
    "Thank you for your time and your answers.",
  "would-also-like-to-rate-us-on-google":
    "Would also like to rate us on Google?",
  "rate-us-now": "Rate us now",

  // Negative Chat Reply
  "select-skill": "Select Skill",
  "none-selected": "None Selected",
  "can-we-get-in-touch-with-you-about-your-experience-today":
    "Can we get in touch with you about your experience today?",
  "write-here": "Write here...",
  "please-can-we-get-your-name": "Please can we get your name?",
  "please-enter-your-email": "Please enter your email:",
  "enter-your-phone-number": "Enter your phone number:",
  "please-tell-us-a-bit-more-about-your-experience":
    "Please tell us a bit more about your experience:",
  "you-have-already-submitted-rating": "You have already submitted rating",
  "sorry-to-hear-you-didn-t-have-the-best-experiance-today-with-us-please-could-you-highlight-what-went-wrong":
    "Sorry to hear you didn't have the best experiance today with us, please could you highlight what went wrong?",

  // Positive Chat Reply
  "select-employee": "Select Employee",
  "we-d-love-to-know-who-looked-after-you-today":
    "We'd love to know who looked after you today?",
  "what-stood-out-to-you-today": "What stood out to you today?",
  "did-we-provide-exceptional-service-today":
    "Did we provide exceptional service today?",
  "do-you-have-any-comments-or-feedback-about-your-experience-with-us-today":
    "Do you have any comments or feedback about your experience with us today?",

  // Client Connect Director
  "message-from-leadership": "Message from Leadership",
  "message-from-leadership-text":
    "I am constantly seeking ways to better our offerings for our clientele. Are there aspects we could better in your opinion?",
  "client-connect-director-another-note":
    "On another note, if there's a specific topic you'd like our team to address, don't hesitate to share. I'm not just here to listen, but to engage and act upon your concerns or ideas.",
  "your-message-here": "Your message here",
  "visit-information": "Visit Information",
  "visit-time-out": "Visit Time Out",
  "contact-information": "Contact Information",
  "client-connect-director-contact-information-text":
    "To provide enhanced assistance, we kindly ask for your details, including your name, email address, and contact number. This information will enable us to respond promptly and contact you.",
  name: "Name",
  "enter-your-name": "Enter your name",
  email: "Email",
  "enter-your-email": "Enter your email",
  "contact-number": "Contact Number",
  "please-enter-your-name": "Please enter your name",
  "please-enter-your-email-or-phone-number":
    "Please enter your email or phone number",
  "invalid-email-address": "Invalid email address",
  "invalid-phone-number-format":
    "Invalid phone number format. Allowed formats are: +44 followed by 10 digits.",
  "please-select-time": "Please select time",
  "you-must-provide-at-least-one-answer-before-submitting-the-survey":
    "You must provide at least one answer before submitting the survey.",

  // Mobile Survey Questions CheckBoxType
  comment: "Comment",
  "comment-is-required": "Comment is Required!",
  "minimum-selection-is": "Minimum selection is {min_options}",
  "maximum-selection-is": "Maximum selection is {max_options}",
  "this-question-is-required": "This Question is Required!",

  // Mobile Survey Questions CommentsAndImage
  "please-select-valid-image-files": "Please select valid image files.",

  // Mobile Survey Questions EmployeeType
  loading: "Loading",
  "getting-employee": "Getting employee...",

  // Mobile Survey Questions FileUploadType
  "please-select-valid-image-or-video-files":
    "Please select valid image or video files.",

  // Mobile Survey Questions NumberType
  "please-enter-a-number-without-any-decimal-points":
    "Please enter a number without any decimal points.",
  "please-enter-a-number-less-than-or-equal-to":
    "Please enter a number less than or equal to {max_number_value}.",

  // Mobile Survey Questions SignatureType
  "please-complete-your-signature": "Please complete your signature.",

  // Mobile Survey Questions TextType
  "please-enter-valid-email-address": "Please enter valid email address.",
  "phone-number-should-be-10-digit": "Phone number should be 10 digits.",
  "please-enter-at-least-characters":
    "Please enter at least {minimum_character} characters.",
  "select-date": "Select Date",
  "select-time": "Select Time",
  "select-country": "Select Country",

  // Report Page
  "click-the-link-to-see-how": "Click the link to see how.",
  "adding-your-teams-to-the-ServeFirst-reporting-platform":
    "That by adding your teams to the ServeFirst reporting platform that they can also login to see their performance, also; you have the ability to manage your team and access additional reports to understand how you're performing overall -",
  "did-you-know": "Did you know..",
  count: "count",
  average: "average",
  focus: "focus",
  performance: "Performance",
  "you-can-create-bespoke-action-plans":
    "You can create bespoke action plans that filter directly into here if created within your ServeFirst account, here you can create actions, timelines and owners instantly whilst tracking performance. -",
  "no-of-times-raised": "No. of Times Raised",
  action: "Action",
  "attribute-name": "Attribute Name",
  "action-plan": "Action Plan",
  "that-by-adding-your-teams-to-the-ServeFirst-reporting-platform":
    "That by adding your teams to the ServeFirst reporting platform that they can also login to see their performance, also; you have the ability to manage your team and access additional reports to understand how you're performing overall -",
  result: "Result",
  category: "Category",
  "your-location-vs-all-location": "Your Location vs. All Location",
  "net-promoter-score": "Net Promoter Score",
  "responses-breakdown": "Responses Breakdown",
  "trended-net-promoter-score": "Trended Net Promoter Score",
  "trended-results": "Trended Results",
  "net-promoter-score-info":
    "Net Promoter Score is based around % of promoters (Score a 5/5) - % of Detractors (Score a 1, 2 or 3), you can find out more about Net Promoter Score by clicking on the following",
  "customer-feedback": "Customer Feedback",
  logan: "Logan",
  "top-performer": "Top Performer",
  "rank-in-the-country": "Rank in the Country",
  "ytd-score": "YTD Score",
  "score-during-period": "Score During Period",
  "0-from-last-month": "0 from Last Month",
  "total-responses-for-july-2021": "Total Responses for July 2021",
  promoter: "Promoter",
  "from-04-sep-2021-to-06-sep-2021": "From 04 Sep,2021 To 06 Sep,2021",
  "the-focus-currently-is-on": "The focus currently is on",
  "1-number-of-customers-have-stated-this-as-an-opportunity":
    "1 number of customers have stated this as an opportunity.",
  "focus-for-the-month": "Focus for the Month",
  "download-pdf": "Download pdf",

  // Report Page New Report
  "total-responses": "Total Responses",
  duration: "Duration",
  "customers-have-stated-this-as-an-opportunity":
    "customers have stated this as an opportunity",
  "error-fetching-params": "Error fetching params!",
  "previous-days-was": "Previous {days} days was {score}",

  // Report Page Common LgCard
  "raised-on": "# Raised on",
  results: "Results",

  // Report Page Components AiSuggestions
  "analyzing-data-please-wait": "Analyzing data, please wait...",

  // Report Page Components CustomerFeedBack
  "view-report": "View Report",
  "received-on": "Received on",

  // Report Page Components ResponseBreakdown
  detractors: "Detractors",
  nps: "NPS",

  // Report Page Components TrendResults
  "trend-results": "Trend Results",

  // Survey helpers Utils
  "failed-to-fetch-the-pdf-file": "Failed to fetch the PDF file",
  "error-generating-pdf-thumbnail": "Error generating PDF thumbnail",
  "error-in-thumbnail-generation-function":
    "Error in thumbnail generation function",
  "failed-to-generate-pdf-thumbnail": "Failed to generate PDF thumbnail",

  // Survey Pages Audition
  "select-audit": "Select Audit",

  // Survey Pages ClientConnect
  "select-atleast-min-options": "Select atleast {minOptions} options",
  "select-max-options": "Maximum {maxOptions} options allowed!",
  "error-saving-your-response": "Error saving your response!",
  "failed-to-load-the-file": "Failed to load the file!",
  "open-link": "Open Link",
  download: "Download",
  details: "Details",
  title: "Title",
  description: "Description",
  "general-info": "General Info",
  type: "Type",
  size: "Size",
  "uploaded-by": "Uploaded by",

  // Survey Pages ClientConnect Intro
  "thank-you-for-your-time": "Thank you for your time!",

  // Survey Pages ClientConnect Content
  "review-content": "Review content",

  // Survey Pages ClientConnect Survey
  "please-give-us-your-feedback": "Please give us your feedback",
  "enter-your-response": "Enter your response",

  // Survey Pages ClientConnect Director
  "i-am-constantly-seeking-ways-to-better-our-offerings-for-our-clientele":
    "I am constantly seeking ways to better our offerings for our clientele. Are there aspects we could better in your opinion?",
  "on-another-note-if-there-s-a-specific-topic-you-d-like-our-team-to-address-don-t-hesitate-to-share":
    "On another note, if there's a specific topic you'd like our team to address, don't hesitate to share. I'm not just here to listen, but to engage and act upon your concerns or ideas.",
  "to-provide-enhanced-assistance-we-kindly-ask-for-your-details-including-your-name-email-address-and-contact-number":
    "To provide enhanced assistance, we kindly ask for your details, including your name, email address, and contact number. This information will enable us to respond promptly and contact you.",

  // Survey Pages Location
  "select-the-location-you-visited": "Select the location you visited",
  locations: "Locations",
  "is-this-your-current-location": "Is this your current location?",
  "nearest-location": "Nearest Location",
  "loading-data": "Loading data...",
  "please-wait": "Please Wait!",

  // Survey Pages Location Area
  "location-area": "Location Area",
  "select-location-area": "Select Location Area",

  // Survey Pages Login
  "please-enter-your-password": "Please enter your password",
  "value-must-be-longer-than-3-characters":
    "Value must be longer than 3 characters",
  "please-enter-your-email-address": "Please enter your email address",
  password: "Password",
  "internal-server-error": "Internal Server Error !",
  "sign-in": "Sign In",

  // Survey Pages SameDaySurvey
  "you-have-already-performed-this-survey":
    "You have already performed this survey !",

  // Survey Pages SurveyThankYou
  "your-feedback-has-been-submitted":
    "Your feedback has been submitted. Your ticket number is #",
  "a-member-of-the-team-will-be-in-touch-shortly":
    "A member of the team will be in touch shortly.",
  "would-you-also-like-to-rate-us-on-google":
    "Would you also like to rate us on Google?",
  "to-review-our-terms-and-conditions-please-click":
    "To review our Terms and Conditions, please click",
  here: "Here",
  "ticket-number-copied-to-clipboard": "Ticket number copied to clipboard",

  // Survey Pages Welcome
  "visit-date": "Visit Date",
  "visit-time-in": "Visit Time In",
  "please-select-date": "Please Select Date",
  "guest-info": "Guest Info",
  "please-select-user": "Please Select User",
  "get-started": "Get Started",

  // Survey Pages InventoryType
  "enter-value": "Enter value",
  "getting-product-list": "Getting Product List...",

  // Survey Pages NotesAndImage
  "upload-image": "Upload Image",

  // Survey Pages NumberType
  "enter-digit": "Enter digit",

  // Survey Pages TextArea
  "enter-your-comment": "Enter your comment",

  // Survey Pages Upload
  "file-size-should-be-less-than-10-mb": "File size should be less than 10 MB",
  "drag-you-files-here-file-size-less-than-10-mb-or":
    "Drag you files here, file size less than 10 MB or",
  "browse-files": "Browse Files",

  // Survey Pages Questions
  "next-question": "Next Question",
  "please-enter-at-least": "Please enter at least {min} characters.",
  "contact-no": "Contact No",
  "please-select-location": "Please Select Location!",
  "please-select-survey": "Please Select Survey!",
  "please-enter-your-email-address-or-phone-number":
    "Please enter your email address or phone number.",

  // Utils CompressFiles
  "compression-error": "Compression error:",

  // Web Rating FrontLogin
  "enter-location-id": "Enter Location ID",
  "invalid-location-id": "Invalid Locaiton ID",
  "location-id-required": "Location id required",

  // Web Rating Index
  "please-tell-us-how-your-experience-was-today":
    "Please tell us how your experience was today.",

  // Web Rating Negativefeedback
  "sorry-to-hear-you-didn-t-have-the-best-experience-today-with-us-please-could-you-highlight-what-went-wrong":
    "Sorry to hear you didn't have the best experience today with us, please could you highlight what went wrong?",
  "enter-valid-email-address": "Enter valid email address.",
  "can-we-get-in-touch-with-you": "Can we get in touch with you?",

  // Web Rating Positivefeedback
  "enter-your-telephone-number": "Enter your telephone number",
  "write-your-feedback": "Write your feedback",

  // Web Rating Positivefeedback2
  "what-made-the-employee-name-stand-out-to-you":
    "What made the {employeeName} stand out to you?",
  team: "Team",

  // Web Rating PositivefeedbackForm
  "please-go-to-previous-step-and-select-employee-without-employee-selection-not-able-to-submit-feedback-form":
    "Please go to previous step and select employee, without employee selection not able to submit feedback form",
  "share-us-your-experience-with-us-today":
    "Share us your experience with us today...",
  "did-employee-name-provide-exceptional-service":
    "Did {employeeName} provide exceptional service?",
  "did-employee-name-stand-out-for-delivering-exceptional-service":
    "Did {employeeName} stand out for delivering exceptional service?",

  // Web Rating New apiHelpers loginHelpers
  "please-enter-user-name": "Please enter user name",
  "please-enter-password": "Please enter password",

  // Web Rating New RatingLocations
  search: "Search",
  "select-loction-to-ratings": "Select loction to Ratings",
  location: "Location",
  "is-this-your-location": "Is this your Location?",
  "fetch-details-failed": "Fetch details failed",

  // Web Rating New PositiveJourny EmployeDetail
  "click-as-many-as-you-want": "Click as many as you want",
  "what-made-employee-name-stand-out-to-you":
    "What made {employeeName} stand out to you?",
  "what-made-the-team-stand-out-to-you": "What made the team stand out to you?",

  // Web Rating New PositiveJourny EmployeService
  "did-we-stand-out-for-delivering-exceptional-service":
    "Did we stand out for delivering exceptional service?",

  // Web Rating New NegativeJourny EmployePersonalDetail
  "leave-an-email-or-phone-number-if-you-want-us-to-get-in-touch-otherwise-just-leave-it-blank-then-press-next":
    "Leave an email or phone number if you want us to get in touch, otherwise just leave it blank then press next",
  "anything-else-you-d-like-to-share": "Anything else you’d like to share?",
  "enter-valid-email": "Enter valid email",
  message: "Message",
  "by-signing-up-the-form-you-agree-to-our-terms-and-privacy":
    "By signing up the form, you agree to our Terms and Privacy.",

  // Web Rating New FrontWebLogin
  login: "Login",
  username: "Username",

  // Web Rating New FrontRating
  "press-the-facial-expression-that-best-represents-how-you-feel-about-your-experience":
    "Press the facial expression that best represents how you feel about your experience.",
  "getting-employee-list": "Getting employee list",
  "getting-skill-data": "Getting skill data",
  "getting-screen-saver": "Getting screen saver",

  // Web Rating New CommonComponents ThankYouModal
  "thank-you-for-contacting-us": "Thank You for contacting us.",
  "we-ll-be-in-touch-very-soon": "We’ll be in touch very soon.",

  // Web Rating New CommonComponents GoogleQrPage
  "you-can-follow-this-button-to-rate-us-on-google":
    "You can follow this button to rate us on Google",
  "rate-us-on-google": "Rate us on Google",

  // Web Rating New CommonComponents ContactUsModal
  "contact-us": "Contact Us",
  "or-you-may-reach-us-on": "...or you may reach us on.",
  "do-you-want-to-learn-more-about-serve-first":
    "Do you want to learn more about Serve First?",

  // Web Rating New CommonComponents BottomBar
  saving: "Saving",
  "please-enter-only-digits-for": "Please enter only digits for",
  "please-enter": "Please enter",
  "enter-value-here": "Enter value here",
};
