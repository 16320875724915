import React from "react";
// import * as reportStyles from "../../../ReportPage/Reportstyle.module.css";
import reportStyles from "../../../ReportPage/Reportstyle.module.css";
import { useIntl } from "react-intl";

const PerformanceCard = (props) => {
  const intl = useIntl();
  const { mainTitle = "", data } = props;

  let { SkillName = [], SkillCount = [], SkillComment = [] } = data;
  //   console.log(Object.entries(data));
  let tableData = Object.entries(SkillCount).map((ele, i) => {
    return {
      title: ele[1],
      value: SkillName[i],
      comment: SkillComment?.[i] ?? "",
    };
  });

  return (
    <>
      <div class={reportStyles.divbackground6}>
        {/* Table heading */}
        <h3 class={reportStyles.heading1}>{mainTitle}</h3>

        {/* Table */}
        <div className="table-responsive-sm">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th style={{ maxWidth: "10%" }}>#</th>
                <th style={{ maxWidth: "30%" }}>
                  {intl.formatMessage({ id: "category" })}
                </th>
                <th style={{ maxWidth: "20%" }}>
                  {intl.formatMessage({ id: "results" })}
                </th>
                <th style={{ maxWidth: "40%" }}>
                  {intl.formatMessage({ id: "comment" })}
                </th>
              </tr>
            </thead>

            <tbody>
              {tableData.length
                ? tableData.map((item, i) => (
                    <tr
                      style={{
                        background: i % 2 !== 0 ? "#f4f4f4" : "",
                      }}
                    >
                      <td
                        style={{ maxWidth: "10%" }}
                        className="font-weight-bold"
                      >
                        {i + 1}
                      </td>
                      <td style={{ maxWidth: "30%" }}>{item.value}</td>
                      <td style={{ maxWidth: "20%" }}>
                        {parseFloat(item.title).toFixed(1)}
                      </td>
                      <td style={{ maxWidth: "40%" }}>
                        {item?.comment || "-"}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      </div>

      <div class={reportStyles.divbackground5}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              paddingRight: "10px",
            }}
          >
            <i
              class={`fa-regular fa-lightbulb ${reportStyles.iconGrid}`}
              style={{ color: props.color }}
            ></i>
          </div>
          <div>
            <h3 class={reportStyles.heading2}>
              {intl.formatMessage({ id: "did-you-know" })}
            </h3>

            <p class={reportStyles.para2}>
              <span>
                {intl.formatMessage({ id: "net-promoter-score-info" })}
              </span>{" "}
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" style={{ color: props.color }}>
                  {intl.formatMessage({ id: "link" })}
                </a>
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceCard;
