import moment from "moment";
import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { postData } from "../../../../helpers/fetch_services";
import { useIntl } from "react-intl";

export default function ClientIntro({ survey, theme }) {
  const intl = useIntl();
  const { clientConnect } = survey;

  const updateRecipientStatus = async () => {
    if (
      localStorage.getItem("client_connect_id") &&
      localStorage.getItem("email")
    ) {
      await postData("survey_sent/client_connect/updateRecipientsStatus", {
        client_connect_id: localStorage.getItem("client_connect_id"),
        email: localStorage.getItem("email"),
      });
    }
  };

  useEffect(() => {
    updateRecipientStatus();
  }, []);

  const logo = localStorage.getItem("logo");

  return (
    <div className="question-card d-flex flex-column flex-lg-row justify-content-between align-items-start">
      <div
        className="intro-left px-2 px-md-4 py-4 py-md-5"
        style={{ backgroundColor: theme.primaryColor }}
      >
        <h3 className="font-weight-bold text-white mt-5 mt-lg-0">
          <i>{intl.formatMessage({ id: "thank-you-for-your-time" })}</i>
        </h3>

        <div className="text-center">
          <h5 className="font-weight-bold text-white mt-5 mt-lg-0">
            {survey.name}
          </h5>

          <h5 className="fw-normal text-white mt-4 mt-lg-0">
            {moment().format("DD MMM YYYY")}
          </h5>
        </div>

        <div className="px-4 mx-md-5 mx-lg-2 mt-5 mt-lg-0">
          <video controls disablePictureInPicture controlsList="nodownload">
            <source src={clientConnect?.introduction?.video} />
          </video>
        </div>
      </div>

      <div className="intro-right py-3 py-md-5 px-2 px-md-4">
        <div className="mx-4 mx-lg-2">
          <h5 className="mt-4">
            {intl.formatMessage({ id: "hi" })} {clientConnect?.username}
          </h5>

          <div className="mt-4 pt-3 intro-message">
            {ReactHtmlParser(clientConnect?.introduction?.message)}
          </div>
        </div>

        <div className="d-flex align-items-center mx-4 mx-lg-2 pb-5 pb-md-0 pt-5 py-lg-4">
          <img
            src={clientConnect?.user?.image}
            alt="scheduler"
            className="avatar"
          />

          <div className="ms-3 pl-2">
            <h5 className="mb-0">
              {clientConnect?.user?.name || "Schedulers Name"}
            </h5>
            <div className="small">
              {clientConnect?.user?.email || "scheduler@email.com"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
