import React, { useEffect, useRef, useState } from "react";
import { styled } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Fab } from "@material-ui/core";
import { toast } from "react-toastify";
import { FaCloudUploadAlt, FaPlus } from "react-icons/fa";
import { addImage, addNote } from "../../../../redux/survey/actions";
import { allowedMimeTypes } from "../../../../Survey/helpers/mimeTypes";
import { compressFiles } from "../../../../utils/compressFiles";
import { useIntl } from "react-intl";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CommentAndImage(props) {
  const intl = useIntl();
  const {
    question,
    answeres,
    isGreyOut,
    addImage_,
    addNote_,
    onContentCallBack,
    locations,
    survey,
  } = props;
  const fileInputRef = useRef(null);
  const handleFabClick = () => {
    // Trigger a click on the hidden input when the Fab is clicked
    fileInputRef.current.click();
  };

  const [showNote, setShowNote] = useState(false);
  const [files, setFiles] = useState([]);
  const [notes, setNotes] = useState();

  const handleImageUpload = (e) => {
    const selectedFiles = e?.target?.files;
    if (selectedFiles && selectedFiles.length > 0) {
      // Check if all selected files are images
      const allFilesAreAllowed = Array.from(selectedFiles).every((file) =>
        allowedMimeTypes.includes(file.type)
      );

      if (allFilesAreAllowed) {
        // compress large image files
        compressFiles(selectedFiles, intl).then((compressedFiles) => {
          setFiles([...compressedFiles]);
          addImage_({
            id: question._id,
            image: [...compressedFiles],
            isFile: true,
          });
          onContentCallBack(compressedFiles?.length);
        });
      } else {
        toast.error(intl.formatMessage({ id: "please-select-valid-image-files" }));
      }
    }
  };
  const loadData = () => {
    setFiles([]);
    setNotes("");
    setShowNote(false);
  };

  useEffect(() => {
    loadData();
  }, [question]);

  const handleText = (text) => {
    setNotes(text.target.value);
    addNote_({ id: question._id, note: text.target.value, type: "text" });
  };

  const onToggleNotes = () => {
    setShowNote(!showNote);
    onContentCallBack(!showNote);
  };

  const selectedLocationId = localStorage.getItem("location") || "";
  const selectedLocation = locations.find(
    (location) => location._id === selectedLocationId
  );

  let mediaSecurity = false;
  if (locations?.length) {
    mediaSecurity = selectedLocation?.mediaSecurity;
  } else {
    mediaSecurity = survey?.survey_without_login?.mediaSecurity;
  }

  return (
    <div className="mt-2">
      {question?.allow_comment ? (
        <button
          onClick={() => !isGreyOut && onToggleNotes()}
          type="button"
          className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600 mr-1"
        >
          <FaPlus sx={{ mr: 1 }} className="mr-1" />
          {intl.formatMessage({ id: "comment" })}
        </button>
      ) : null}
      {question?.allow_file_upload && !mediaSecurity ? (
        <Fab
          onClick={handleFabClick}
          variant="extended"
          className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600 text-capitalize comment-btn"
        >
          <FaCloudUploadAlt sx={{ mr: 1 }} className="mr-1" />
          <VisuallyHiddenInput
            type="file"
            ref={fileInputRef}
            onChange={(e) => handleImageUpload(e)}
            accept={allowedMimeTypes?.join(",")}
            multiple
          />
          Image
        </Fab>
      ) : null}
      {showNote ? (
        <input
          type="text"
          value={notes}
          onChange={handleText}
          id="feedback"
          placeholder={intl.formatMessage({ id: "write-here" })}
          className="mt-1 block w-full px-4 py-3 text-base text-black transition-all duration-200 bg-white border border-transparent shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
          autoComplete="off"
          style={{ borderRadius: "10px" }}
        />
      ) : null}
      {files?.length ? (
        <div className="row ms-0 me-0 mt-2">
          {files?.map((f, i) => {
            return (
              <div
                style={{
                  border: "1px solid grey",
                  padding: "3px",
                  marginRight: "2px",
                  marginBottom: "2px",
                }}
                key={i}
              >
                {f.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(f)}
                    alt="question"
                    style={{
                      width: "85px",
                      height: "80px",
                      objectFit: "contain",
                    }}
                  />
                ) : f.type.startsWith("video/") ? (
                  <video
                    controls
                    style={{ maxWidth: "190px", height: "100px" }}
                  >
                    <source src={URL.createObjectURL(f)} type="video/mp4" />
                  </video>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
    locations: state.survey.locations,
    survey: state.survey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // addAnswer_: (data) => dispatch(addAnswer(data)),
    addImage_: (data) => dispatch(addImage(data)),
    addNote_: (data) => dispatch(addNote(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommentAndImage);
