import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addAnswer,
  addNote,
  addWriteIn,
} from "../../../../redux/survey/actions";
import { toast } from "react-toastify";
import { DatePicker, Input, InputGroup, SelectPicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import {
  countries,
  generateCountryOptions,
} from "../../../../Survey/helpers/country";
import "rsuite/dist/styles/rsuite-default.css";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  "rs-picker-menu-custom": {
    top: "auto !important",
    bottom: "85px",
  },
}));

function TextType(props) {
  const intl = useIntl();
  // Destructure props to extract necessary values
  const {
    question,
    answeres,
    addAnswer_,
    addNote_,
    addWriteIn_,
    type,
    survey_theme,
    onSend,
    allQuestions,
  } = props;

  const classes = useStyles();
  const [text, setText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  let answer = answeres[question._id] ?? { value: text };

  const now = new Date();
  let maxDate = now;
  // check if the question has a date_type_question_index and it is not undefined
  if (
    question?.date_type_question_index !== undefined &&
    question?.date_type_question_index !== null
  ) {
    const dateQuestion = allQuestions[question.date_type_question_index];
    if (dateQuestion?.textType === "date") {
      const dateAnswer = answeres[dateQuestion._id];
      if (dateAnswer?.value) {
        // Convert DD/MM/YYYY format to YYYY-MM-DD for proper Date parsing
        const [day, month, year] = dateAnswer.value.split("/");
        maxDate = new Date(year, month - 1, day); // month is 0-indexed

        // If maxDate is today, set it to current time
        const today = new Date();
        if (
          maxDate.getDate() === today.getDate() &&
          maxDate.getMonth() === today.getMonth() &&
          maxDate.getFullYear() === today.getFullYear()
        ) {
          maxDate = now;
        }
      }
    }
  }

  // Define the function to submit an answer
  const submitAnswer = () => {
    // Check if the question is required and the answer is empty
    if (question?.required && !text) {
      toast.error(intl.formatMessage({ id: "this-question-is-required" }));
      return;
    } else {
      if (type === "note") {
        addNote_({ id: question._id, note: text, type: "text" });
      } else if (type === "write_in") {
        addWriteIn_({
          id: question._id,
          write_in: text,
          type: "text",
        });
      } else {
        // Handle different text types
        if (question?.textType === "date" && selectedDate) {
          // Add answer for date type
          addAnswer_({
            id: question._id,
            value: moment(selectedDate).format("DD/MM/YYYY"),
            type: "text",
          });
          onSend(moment(selectedDate).format("DD/MM/YYYY"));
        } else if (question?.textType === "time" && selectedTime) {
          // Add answer for time type
          addAnswer_({
            id: question._id,
            value: moment(selectedTime).format("HH:mm"),
            type: "text",
          });
          onSend(moment(selectedTime).format("HH:mm"));
        } else if (question?.textType === "email") {
          // Validate email address
          var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );
          if (text && !pattern.test(text)) {
            toast.error(
              intl.formatMessage({ id: "please-enter-valid-email-address" })
            );
            return;
          }
          onSend(answer?.value);
          addAnswer_({ id: question._id, value: text, type: "text" });
        } else if (question?.textType === "phone") {
          // Validate phone number
          if (text && text?.length !== 10) {
            toast.error(
              intl.formatMessage({ id: "phone-number-should-be-10-digit" })
            );
            return;
          }
          const selectedCode =
            countries.find((c) => c?.code === selectedCountry)?.phone || "";
          onSend(`+${selectedCode}${answer?.value}`);
          addAnswer_({
            id: question._id,
            value: text,
            type: "text",
            code: selectedCode,
          });
        } else {
          // Check minimum character requirement
          if (text && text?.trim()?.length < question?.minimum_character) {
            toast.error(
              intl.formatMessage(
                { id: "please-enter-at-least-characters" },
                { minimum_character: question?.minimum_character }
              )
            );
            return;
          }
          onSend(answer?.value);
          addAnswer_({ id: question._id, value: text, type: "text" });
        }
      }
      setText("");
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    // Add answer for date type
    addAnswer_({
      id: question._id,
      value: moment(date).format("DD/MM/YYYY"),
      type: "text",
    });
    onSend(moment(date).format("DD/MM/YYYY"));
  };
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    // Add answer for time type
    addAnswer_({
      id: question._id,
      value: moment(time).format("HH:mm"),
      type: "text",
    });
    onSend(moment(time).format("HH:mm"));
  };

  const renderValue = (value, item) => {
    // Customize the display of the selected value
    return (
      <div>
        <span style={{ marginLeft: "5px" }}>{item.phone}</span>
      </div>
    );
  };
  const [selectedCountry, setSelectedCountry] = useState("GB");
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  return (
    <div className="px-2 bg-white border-t border-gray-200">
      <div className="flex items-center justify-center space-x-3 h-18 px-2">
        {question?.textType === "date" ? (
          <DatePicker
            format="DD/MM/YYYY"
            ranges={[]}
            value={selectedDate}
            onChange={handleDateSelect}
            className="p-0 border-0 form-control"
            block
            placement="topStart"
            style={{ color: "darkgray", fontSize: "14px" }}
            menuClassName={classes["rs-picker-menu-custom"]}
            disabledDate={(date) => date && date > new Date()}
            placeholder={
              question?.placeholderText ??
              intl.formatMessage({ id: "select-date" })
            }
          />
        ) : question?.textType === "time" ? (
          <DatePicker
            format="HH:mm"
            placeholder={
              question?.placeholderText ??
              intl.formatMessage({ id: "select-time" })
            }
            ranges={[]}
            value={selectedTime}
            onChange={handleTimeSelect}
            className="form-control p-0 border-0"
            block
            style={{ color: "darkgray", fontSize: "14px" }}
            placement="topStart"
            menuClassName={classes["rs-picker-menu-custom"]}
            disabledDate={(date) => {
              if (maxDate && maxDate < now) {
                return false; // enable all hours for past dates
              }
              return date && date > now;
            }}
            disabledHours={(hour) => {
              if (maxDate && maxDate < now) {
                return false; // enable all hours for past dates
              }
              return hour > now.getHours(); // disable future hours for today/future dates
            }}
            disabledMinutes={(minutes, selectedHours) => {
              if (maxDate && maxDate < now) {
                return false; // enable all minutes for past dates
              }
              // disable future minutes for today/future dates
              return (
                selectedHours === now.getHours() && minutes > now.getMinutes()
              );
            }}
          />
        ) : question?.textType === "phone" && type !== "note" ? (
          <InputGroup>
            <SelectPicker
              data={generateCountryOptions(countries)}
              placeholder={intl.formatMessage({ id: "select-country" })}
              value={selectedCountry}
              onChange={handleCountryChange}
              renderValue={renderValue}
              placement="topStart"
              cleanable={false}
            />
            <Input
              placeholder={
                question?.placeholderText ??
                intl.formatMessage({ id: "phone-number" })
              }
              value={text}
              onChange={(value) => setText(value)}
              type="number"
            />
          </InputGroup>
        ) : (
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e?.target?.value)}
            id="feedback"
            placeholder={
              question?.placeholderText ??
              intl.formatMessage({ id: "write-here" })
            }
            className="block w-full px-4 py-3 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
            autoComplete="off"
          />
        )}

        {question?.textType !== "date" && question?.textType !== "time" && (
          <button
            onClick={submitAnswer}
            type="submit"
            className="flex items-end justify-center flex-shrink-0 p-3 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
            style={{
              backgroundColor: survey_theme?.primaryColor,
            }}
          >
            <svg
              className="w-6 h-6 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}
// Redux mapping function to map state and dispatch to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
  };
};
// Connect SignatureType component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(TextType);
