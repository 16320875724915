import { Button } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ThemeUpdateContext } from "../../../App";
import {
  clearSurvey,
  setGoogleRatingsDetails,
  setSurvey,
} from "../../../redux/survey/actions";
import "./welcome.css";
import { isCompanyLogin } from "../../helpers/Utils";
import { postData } from "../../helpers/fetch_services";
import { DatePicker } from "rsuite";
import moment from "moment";
import { useIntl } from "react-intl";

/**
 * Welcome component to display the initial page of a survey.
 * Connects to Redux state to access and update survey data.
 */

const emptyError = {
  visitDate: false,
  visitTimeIn: false,
};

function Welcome({
  surveyTheme,
  survey,
  setSurvey_,
  setGoogleRatingsDetails_,
  clearSurveyWithoutLogin,
}) {
  const intl = useIntl();
  // Retrieves the logo URL from local storage.
  const logo = localStorage.getItem("logo");

  // useContext hook to access and change the theme.
  const { changeTheme } = useContext(ThemeUpdateContext);

  // useHistory hook for navigation.
  const history = useHistory();

  // Local state for managing the current survey and loading state.
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeIn, setSelectedTimeIn] = useState(null);
  const [guest, setGuest] = useState("");
  const [error, setError] = useState(emptyError);

  /**
   * Function to start the survey.
   * Redirects to the survey questions page.
   * @param {object|null} survey - The survey to start (defaults to currentSurvey).
   */
  const startSurvey = (survey = null) => {
    if (currentSurvey?.visit_information) {
      // Initial error state
      const newErrorState = { ...emptyError };

      if (!selectedDate) {
        newErrorState.visitDate = true;
      }
      if (!selectedTimeIn) {
        newErrorState.visitTimeIn = true;
      }

      setError(newErrorState);

      // If any error exists, return early
      if (newErrorState.visitDate || newErrorState.visitTimeIn) {
        return;
      }
    }

    history.push({
      pathname: "/survey/questions",
      state: {
        surveyWithoutLogin: survey || currentSurvey,
        visitDate: selectedDate || null,
        visitTimeIn: selectedTimeIn
          ? moment(selectedTimeIn).format("HH:mm")
          : null,
        guest_info: guest || null,
      },
    });
  };
  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  // Function to handle time selection
  const handleTimeSelect = (time, value) => {
    if (value === "timein") {
      setSelectedTimeIn(time);
    }
  };

  const onBackToAudit = () => {
    clearSurveyWithoutLogin();
    history.push({
      pathname: "/survey/audition",
      state: { surveyWithoutLogin: survey },
    });
  };

  const getSurveyDetail = async () => {
    const survey_id = localStorage.getItem("survey");
    const location = localStorage.getItem('location');
    setLoading(true);
    const result = await postData("frontEmployeeFeedback/getSurvey", {
      survey_id,
      location_id:location
    });

    if (result?.success) {
      setSurvey_(result?.data);
      setCurrentSurvey(result?.data);
      setGoogleRatingsDetails_({
        locationGoogleReviewUrl: result?.data?.locationGoogleReviewUrl,
        locationGoogleQrImage: result?.data?.locationGoogleQrImage,
      });
      if (
        !result?.data.description &&
        !result?.data.image &&
        !result?.data.visit_information &&
        !result?.data.guest_info
      ) {
        startSurvey(result?.data);
      }
    }
    setLoading(false);
  };
  /**
   * useEffect hook to set the theme and load the survey on component mount.
   */
  useEffect(() => {
    // Change the theme based on the survey's theme.
    changeTheme(surveyTheme);

    const { survey_without_login } = survey;

    // Check if there's a survey available without login.
    if (Object.keys(survey_without_login).length) {
      if (
        !survey_without_login.description &&
        !survey_without_login.image &&
        !survey_without_login?.visit_information &&
        !survey_without_login?.guest_info
      ) {
        startSurvey(survey_without_login);
      }
      setCurrentSurvey(survey_without_login);
    } else {
      // Fetch survey from local storage if available.
      getSurveyDetail();
    }
  }, []);

  const surveyCount = localStorage.getItem("total_location_survey")
    ? parseInt(localStorage.getItem("total_location_survey"))
    : 0;

  // Render the component with LoadingOverlay, brand logo, and start button.
  return (
    <>
      <div
        className={`${
          !currentSurvey?.description ||
          !currentSurvey?.image ||
          !currentSurvey?.visit_information ||
          !currentSurvey?.guest_info
            ? "no-description-logo"
            : "w-50"
        }`}
        style={{ marginLeft: "auto" }}
      >
        <div className="logo-container">
          <img src={logo} alt="Brand logo" style={{ objectFit: "contain" }} />
        </div>
      </div>
      {loading ? (
        <div className="col-md-12 text-center mt-4">
          <img
            src="/images/survey-loader.gif"
            alt="loading survey"
            className="loading-image"
          />
        </div>
      ) : (
        <div
          className={`${
            !currentSurvey?.description ||
            !currentSurvey?.image ||
            !currentSurvey?.visit_information
              ? "no-description-logo"
              : "welcome-container"
          }`}
        >
          {currentSurvey?.image ? (
            <div className="left-section">
              <img
                src={currentSurvey?.image}
                alt="Company Logo"
                style={{ objectFit: "contain" }}
              />
            </div>
          ) : null}
          {currentSurvey?.visit_information ? (
            <div className="col-md-4  mt-4">
              {/* Date,TimeIn ,Time Out  */}
              <label>{intl.formatMessage({ id: "visit-date" })} *:</label>
              <DatePicker
                format="DD/MM/YYYY"
                ranges={[]}
                value={selectedDate}
                onChange={handleDateSelect}
                className="form-control p-0 mb-2"
                block
                style={{ color: "darkgray", fontSize: "14px" }}
                disabledDate={(date) => date && date > new Date()}
              />
              {error && error?.visitDate ? (
                <p className="small text-danger pb-1">
                  {intl.formatMessage({ id: "please-select-date" })}
                </p>
              ) : null}
              <label>{intl.formatMessage({ id: "visit-time-in" })} *:</label>

              <DatePicker
                format="HH:mm"
                ranges={[]}
                value={selectedTimeIn}
                onChange={(time) => handleTimeSelect(time, "timein")}
                className="form-control p-0 mb-2"
                block
                style={{ color: "darkgray", fontSize: "14px" }}
                disabledDate={(date) => date && date > new Date()}
                disabledHours={(hour) => hour > new Date().getHours()}
              />
              {error && error?.visitTimeIn ? (
                <p className="small text-danger pb-1">
                  {intl.formatMessage({ id: "please-select-time" })}
                </p>
              ) : null}
            </div>
          ) : null}
          {currentSurvey?.guest_info ? (
            <div className="col-md-4  mt-4">
              {/* Date,TimeIn ,Time Out  */}
              <label>{intl.formatMessage({ id: "guest-info" })}:</label>
              <select
                value={guest}
                onChange={(e) => setGuest(e?.target?.value)}
                className="form-control"
              >
                <option value="">
                  {intl.formatMessage({ id: "please-select-user" })}
                </option>
                {currentSurvey?.guestInformation?.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ) : null}
          <div className="right-section">
            <div className="description-text">
              {ReactHtmlParser(currentSurvey?.description)}
            </div>
            <div className="start-button">
              {isCompanyLogin() && surveyCount > 1 ? (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBack />}
                  onClick={() => onBackToAudit()}
                  className="mr-2"
                  disabled={loading}
                >
                  {intl.formatMessage({ id: "back" })}
                </Button>
              ) : null}
              <Button
                size="large"
                variant="contained"
                color="primary"
                endIcon={<ArrowForward />}
                onClick={() => startSurvey()}
                disabled={loading}
              >
                {intl.formatMessage({ id: "get-started" })}
              </Button>
            </div>
            {currentSurvey?.termAndCondition_url ? (
              <div className={"termAndCondition"}>
                <span>
                  {intl.formatMessage({
                    id: "to-review-our-terms-and-conditions-please-click",
                  })}
                </span>
                <a
                  href={currentSurvey?.termAndCondition_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textTransform: "uppercase" }}
                >
                  {intl.formatMessage({ id: "here" })}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

/**
 * Maps Redux state to component props.
 */
const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    surveyTheme: state.survey.survey_theme,
  };
};

/**
 * Maps dispatch actions to component props.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    setSurvey_: (data) => dispatch(setSurvey(data)),
    setGoogleRatingsDetails_: (data) => dispatch(setGoogleRatingsDetails(data)),
    clearSurveyWithoutLogin: () => dispatch(clearSurvey()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
