import React, { useState } from "react";

import { Formik, Form, Field } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./style";
import { connect } from "react-redux";
import {
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../../redux/frontWebRating/actions";
import {
  loginHandler,
  loadCustomSettings,
  validatePassword,
  validateUsername,
} from "../apiHelpers/loginHelpers";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function FrontWebLogin(props) {
  const intl = useIntl();
  const classes = useStyles();
  const [username] = useState("");
  const [password] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, deleteCookie] = useCookies(["rating"]);

  const history = useHistory();
  const { setFrontratingAnsweres_, resetFrontratingAnsweres_ } = props;
  let query = useQuery();

  useEffect(() => {
    loadCustomSettings(
      query,
      history,
      setFrontratingAnsweres_,
      resetFrontratingAnsweres_
    );
  }, []);
  const initialValues = { username, password };
  return (
    <div className="wrapper" style={{ height: "500px" }}>
      <section className={`login-wrapper ${classes.backgroundImg}`}>
        <div className="container">
          <div className={classes.loginImage}>
            <img src="/images/webRating/serve-first.png" alt="logo" />
          </div>
          <div
            className="login-inner"
            style={{ marginTop: "2%", padding: "63px 55px" }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                deleteCookie();
                loginHandler(values, props, history, resetFrontratingAnsweres_);
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <Field
                      // type="text"
                      className="form-control"
                      placeholder={intl.formatMessage({ id: "username" })}
                      name="username"
                      validate={(value) => validateUsername(value, intl)}
                      id="locationId"
                    />
                    {/* <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                ></span> */}
                    {errors.username && touched.username && (
                      <div
                        className="invalid-feedback d-block text-left"
                        style={{ fontSize: "1rem" }}
                      >
                        {errors.username}
                      </div>
                    )}
                    <Field
                      type="password"
                      className="form-control"
                      placeholder={intl.formatMessage({ id: "password" })}
                      name="password"
                      id="locationId"
                      validate={(value) => validatePassword(value, intl)}
                      style={{ marginTop: "4%" }}
                    />
                    {/* <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                ></span> */}
                    {errors.password && touched.password && (
                      <div
                        className="invalid-feedback d-block text-left"
                        style={{ fontSize: "1rem" }}
                      >
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn"
                    disabled={isSubmitting}
                    style={{
                      backgroundColor: "#6E247F",
                      color: "#fff",
                      border: "1px solid #955275",
                      borderRadius: "10px",
                      marginTop: "6%",
                    }}
                  >
                    {intl.formatMessage({ id: "login" })}
                    {isSubmitting ? (
                      <Spinner
                        size="sm"
                        animation="border"
                        variant="light"
                        className="ml-2"
                      />
                    ) : null}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontWebLogin);
