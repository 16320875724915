import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "../../../common/components/Button";
import { useStyles } from "./style";
import { withStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { addAnswer, addImage } from "../../../../redux/survey/actions";

import BackupIcon from "@material-ui/icons/Backup";
import { allowedMimeTypes } from "../../../helpers/mimeTypes";
import { compressFiles } from "../../../../utils/compressFiles";
import { useIntl } from "react-intl";

function Upload(props) {
  const intl = useIntl();
  const classes = useStyles(props);
  // Destructure props for easier access
  const { question, answeres, addAnswer_, addImage_, type, isGreyOut } = props;
  const [files, setFiles] = useState([]);
  // Access theme
  const theme = useTheme();
  // Check if screen size is mobile
  const isMob = useMediaQuery(theme.breakpoints.down("sm"));

  const onDrop = useCallback((accFiles, rejFiles) => {
    compressFiles(accFiles, intl).then((compressedFiles) => {
      setFiles((curr) => [...curr, ...compressedFiles]);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: allowedMimeTypes,
    onDrop,
  });

  const loadData = () => {
    let prevAnswer;
    if (type === "note") {
      prevAnswer = answeres[question._id] ?? { image: [] };
    } else {
      prevAnswer = answeres[question._id] ?? { value: [] };
    }
    let file =
      type === "note" ? prevAnswer?.image ?? [] : prevAnswer?.value ?? [];

    setFiles(file);
  };
  useEffect(() => {
    loadData();
  }, []);

  const setData = () => {
    if (type === "note") {
      addImage_({
        id: question._id,
        image: files,
        isFile: true,
      });
    } else {
      addAnswer_({
        id: question._id,
        value: files,
        isFile: true,
      });
    }
  };
  useEffect(() => {
    setData();
  }, [files]);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      // borderRadius: 5,
      backgroundColor: theme.palette.primary.dark,
    },
  }))(LinearProgress);
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <>
      {/* <CssBaseline/> */}

      <Box
        style={{ cursor: isGreyOut && "not-allowed" }}
        className={classes.uploadBox}
        {...getRootProps()}
      >
        <BackupIcon className={classes.uploadBoxIcon} />
        <input {...getInputProps()} />

        <Typography className={classes.dragTitle}>
          {isMob
            ? intl.formatMessage({ id: "file-size-should-be-less-than-10-mb" })
            : intl.formatMessage({ id: "drag-you-files-here-file-size-less-than-10-mb-or" })}
        </Typography>
        <div style={{ margin: "12px 0" }}>
          <Button title={intl.formatMessage({ id: "browse-files" })} />
        </div>
        {/* <Button variant="contained" size="large" className={classes.Button}>
          Browse File
        </Button> */}
      </Box>

      {files.length
        ? files.map((filewrapper, i) => {
            return (
              <div className={classes.ProgressContainer}>
                <div style={{ flex: "1 1 auto", marginRight: "10px" }}>
                  <div className={classes.progressDesc}>
                    <Typography className={classes.progressDescText}>
                      {filewrapper.name}
                    </Typography>
                    <Typography className={classes.progressDescText}>
                      {formatBytes(filewrapper.size)}
                    </Typography>
                  </div>
                  <BorderLinearProgress variant="determinate" value={100} />
                </div>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFiles((prev) => prev.filter((item, id) => id !== i));
                  }}
                />
              </div>
            );
          })
        : null}
    </>
  );
}
// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Map Redux dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addImage_: (data) => dispatch(addImage(data)),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Upload);
