import moment from "moment";
import React from "react";
// import * as reportStyles from "../../../ReportPage/Reportstyle.module.css";
import reportStyles from "../../../ReportPage/Reportstyle.module.css";
import { useIntl } from "react-intl";

const ActionPlanCard = (props) => {
  const intl = useIntl();
  const { mainTitle = "", data } = props;

  let tableData = data.map(({ title, description, createdAt }) => {
    return {
      title: title,
      description: description,
      createdAt: createdAt,
    };
  });

  return (
    <>
      <div class={reportStyles.divbackground6}>
        {/* Table heading */}
        <h3 class={reportStyles.heading1}>{mainTitle}</h3>

        {/* Table */}
        <div className="table-responsive-sm">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>#</th>
                <th style={{ width: "30%" }}>
                  {intl.formatMessage({ id: "attribute-name" })}
                </th>
                <th style={{ width: "20%" }}>
                  {intl.formatMessage({ id: "action" })}
                </th>
                <th style={{ width: "40%" }}>
                  {intl.formatMessage({ id: "raised-on" })}
                </th>
              </tr>
            </thead>

            <tbody>
              {tableData.length
                ? tableData.map((item, i) => (
                    <tr
                      style={{
                        background: i % 2 !== 0 ? "#f4f4f4" : "",
                      }}
                    >
                      <td style={{ width: "10%" }} className="font-weight-bold">
                        {i + 1}
                      </td>
                      <td style={{ width: "30%" }}> {item.title}</td>
                      <td style={{ width: "20%" }}>{item.description}</td>
                      <td style={{ width: "40%" }}>
                        {" "}
                        {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      </div>

      <div class={reportStyles.divbackground5}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              paddingRight: "10px",
            }}
          >
            <i
              class={`fa-regular fa-lightbulb ${reportStyles.iconGrid}`}
              style={{ color: props.color }}
            ></i>
          </div>
          <div>
            <h3 class={reportStyles.heading2}>
              {intl.formatMessage({ id: "did-you-know" })}
            </h3>

            <p class={reportStyles.para2}>
              <span>
                {intl.formatMessage({ id: "net-promoter-score-info" })}
              </span>{" "}
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" style={{ color: props.color }}>
                  {intl.formatMessage({ id: "link" })}
                </a>
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionPlanCard;
