import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { connect } from "react-redux";
import Button from "../../../../common/components/Button";
import Progress from "../../../../common/components/Progress";
import { useIntl } from "react-intl";

const ClientFooter = ({
  currentStep,
  onClickNext,
  onClickPrev,
  survey,
  totalSurveySteps,
  maxSteps,
}) => {
  const intl = useIntl();
  const isComplete =
    survey?.survey_without_login?.clientConnect?.userAuditComplete === true;
  const currentSurvey = survey.survey_without_login;
  const { clientConnect } = currentSurvey;
  const clientContent = clientConnect.client_content;
  return (
    <div className="page-footer w-100">
      <div className="footer-content mx-auto d-flex justify-content-between align-items-center">
        <div style={{ flex: "0 0 auto" }}>
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={onClickPrev}
            isDisable={currentStep === 1}
          />
        </div>

        <div style={{ flex: "1 1 auto", padding: "0 20px" }}>
          {/* <Progress answered={currentStep} total={2 + totalSurveySteps} /> */}
          <Progress
            answered={currentStep}
            total={
              isComplete
                ? 2
                : clientContent?.length &&
                  clientContent?.every((data) => !data?.link && !data?.file)
                ? 2
                : maxSteps
            }
          />
        </div>

        <div style={{ flex: "0 0 auto" }}>
          <Button
            // title={currentStep === 1 ? "Review content" : "Next"}
            title={
              currentStep === 1
                ? intl.formatMessage({ id: "review-content" })
                : currentStep === maxSteps
                ? intl.formatMessage({ id: "submit" })
                : intl.formatMessage({ id: "next" })
            }
            onClick={onClickNext}
            isDisable={isComplete && currentStep > 1}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
  };
};

export default connect(mapStateToProps)(ClientFooter);
