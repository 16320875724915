module.exports = {
  // Common words
  enter: "Entrar",
  next: "Siguiente",
  previous: "Anterior",
  finish: "Finalizar",
  returnToWebsite: "Volver al sitio web",
  complete: "Completar",
  submit: "Enviar",
  cancel: "Cancelar",
  yes: "Sí",
  no: "No",
  skip: "Omitir",
  close: "Cerrar",
  discard: "Descartar",
  save: "Guardar",
  "thank-you": "Gracias",
  select: "Seleccionar",
  hi: "Hola",
  hello: "Hola",
  link: "Enlace",
  back: "Volver",
  view: "Ver",
  continue: "Continuar",

  // Client Survey Main
  "client-survey-main-thanks-note":
    "Gracias por tomar el tiempo, su feedback forma nuestra oferta <br /> para el futuro.",
  "client-survey-how-likely-recommend":
    "¿Qué tan probable es que recomiende nuestros servicios a otros?",
  "client-survey-how-likely-note":
    "En una escala de 0 a 10, donde 0 es nada probable y 10 es extremadamente probable.",
  "client-survey-how-likely-recommend-question":
    "¿Qué es lo que podríamos hacer para que sea más probable que recomiende nuestros servicios?",
  "step-of": "Paso {step} de {total}",
  step: "Paso {step}",
  "client-survey-how-satisfied-with-service":
    "¿Qué tan satisfecho está con el servicio que recibe de nosotros?",
  "client-survey-how-satisfied-with-service-question":
    "¿Qué es lo que podríamos hacer para mejorar?",
  "client-survey-how-satisfied-with-food":
    "¿Qué tan satisfecho está con la comida que recibe de nosotros?",
  "client-survey-how-satisfied-with-food-question":
    "¿Qué es lo que podríamos hacer para mejorar?",
  "client-survey-what-is-the-best-thing-about-working-with-us":
    "¿Qué es lo mejor de trabajar con nosotros? Por favor, seleccione todas las que importan.",
  "management-team": "Equipo de Gestión",
  "the-front-line-operations-team": "Equipo de Operaciones de Primera Línea",
  "food-offering": "Oferta de Comida",
  innovation: "Innovación",
  communication: "Comunicación",
  "flexibility-and-responsiveness": "Flexibilidad y Responsividad",
  billing: "Facturación",
  "health-and-hygiene-standards": "Estándares de Salud y Higiene",
  "if-other-please-specify": "Si es otro, por favor especifique...",
  "based-on-the-service-we-provide-where-can-we-improve-the-most-please-select-all-that-matters":
    "Basado en el servicio que ofrecemos, ¿dónde podemos mejorar lo más posible? Por favor, seleccione todas las que importan.",
  "please-can-we-have-some-more-detail": "¿Podríamos tener más detalles?",
  "to-help-us-understand-your-csr-priorities-please-order-in-rank-of-importance-the-following":
    "Para ayudarnos a entender sus prioridades de CSR, por favor ordene en importancia la siguiente lista:",
  "sustainable-ingredients": "Ingredientes Sostenibles",
  "food-waste": "Desperdicio de Comida",
  "local-sourcing": "Suministro Local",
  "people-and-communities": "Personas y Comunidades",
  "energy-reduction": "Reducción de Energía",
  "working-with-local-communities": "Trabajar con comunidades locales",
  "environmental-choices": "Elecciones Ambientales",
  "meat-free": "Sin Carne",
  "less-plastic": "Menos Plástico",
  "would-you-like-to-be-contacted-about-your-feedback":
    "¿Le gustaría ser contactado sobre su feedback? Si es así, por favor deje su información de contacto a continuación",
  "full-name": "Nombre Completo",
  "email-address": "Dirección de Email",
  "phone-number": "Número de Teléfono",
  feedback: "Feedback",
  "view-our-privacy-policy": "Ver nuestra Política de Privacidad",
  "thank-you-for-taking-time-to-complete-the-survey":
    "¡Gracias por tomar el tiempo para completar el encuesta!",

  // 404 Not Found
  "404-not-found": "404 No Encontrado",

  // Privacy Policy
  "privacy-policy": "Política de Privacidad",

  // Employee Web Rating Feedback Step 1
  "employee-web-rating-feedback-step-1-positive":
    "¡Encantado de saber que estás sintiendo positivo, ¡nos encantaría saber por qué!",
  "employee-web-rating-feedback-step-1-negative":
    "¡Lamento que no estés sintiendo lo mejor, ¿podría decirnos por qué?",

  // Employee Web Rating Feedback Step 2
  "employee-web-rating-feedback-step-2-heading":
    "¿Hay algo más que quisiera compartir con nosotros hoy?",
  "employee-web-rating-feedback-step-2-information":
    "¿Tiene algún comentario o feedback sobre su experiencia con nosotros hoy?",
  "employee-web-rating-feedback-step-2-placeholder":
    "Comparta su experiencia con nosotros hoy...",

  // Employee Web Rating Feedback Step 3
  "employee-web-rating-feedback-step-3-heading":
    "¿Le gustaría agradecer a alguien?",
  "no-employees-found": "No se encontraron empleados",

  // Employee Web Rating Home
  "choose-location": "Elegir Ubicación",
  "search-location": "Buscar Ubicación",
  "feedback-anonymous": "¿Quiere mantener su feedback anónimo?",
  "feedback-feeling-today": "¿Cómo está sintiendo hoy?",

  // Employee Web Rating Thankyou Page
  "thank-you-for-your-feedback": "¡Gracias por su feedback!",
  "back-to-home": "Volver al Inicio",
  page: "Página",

  // Feedback Once Alert
  "feedback-once-alert": "El feedback se ha dado una vez desde esta URL.",

  // Front Customer Audit
  "please-answer-above-question": "Por favor, responda a la pregunta anterior.",
  "add-comments": "Añadir comentarios",
  "enter-answer": "Introduzca su respuesta",
  "add-upload-files": "Añadir archivos",

  // Alphega Questions
  "did-you-buy-medicine-in-the-pharmacy-today":
    "¿Compraste medicamentos en la farmacia hoy?",
  "were-you-asked-any-of-the-following":
    "¿Fue preguntado por alguno de los siguientes:",
  "who-is-the-medicine-for": "¿Quién es el medicamento para?",
  "what-are-your-symptoms": "¿Cuáles son tus síntomas?",
  "how-long-you-had-the-symptoms": "¿Cuánto tiempo has tenido los síntomas?",
  "what-action-you-have-already-taken": "¿Qué acción has tomado ya?",
  "are-you-taking-any-other-medication":
    "¿Estás tomando algún otro medicamento?",

  // Front Mobile Home
  "how-was-your-experience-with-us-today":
    "¿Cómo fue tu experiencia con nosotros hoy?",

  // Front Mobile Thankyou Page
  "thank-you-for-your-time-and-your-answers":
    "¡Gracias por tu tiempo y tus respuestas.",
  "would-also-like-to-rate-us-on-google":
    "¿También te gustaría calificarnos en Google?",
  "rate-us-now": "Calificarnos ahora",

  // Negative Chat Reply
  "select-skill": "Seleccionar Habilidad",
  "none-selected": "Ninguna Seleccionada",
  "can-we-get-in-touch-with-you-about-your-experience-today":
    "¿Quieres que nos contactemos contigo sobre tu experiencia hoy?",
  "write-here": "Escribe aquí...",
  "please-can-we-get-your-name": "¿Podrías decirnos tu nombre?",
  "please-enter-your-email": "¿Podrías decirnos tu email?",
  "enter-your-phone-number": "¿Podrías decirnos tu número de teléfono?",
  "please-tell-us-a-bit-more-about-your-experience":
    "¿Quieres que nos contes algo más sobre tu experiencia?",
  "you-have-already-submitted-rating": "El feedback ya ha sido enviado.",
  "sorry-to-hear-you-didn-t-have-the-best-experiance-today-with-us-please-could-you-highlight-what-went-wrong":
    "¡Lamento que no tengas la mejor experiencia hoy con nosotros, ¿podrías resaltar lo que salió mal?",

  // Positive Chat Reply
  "select-employee": "Seleccionar Empleado",
  "we-d-love-to-know-who-looked-after-you-today":
    "¿Quieres que nos contes quién te cuidó hoy?",
  "what-stood-out-to-you-today": "¿Qué te llamó la atención hoy?",
  "did-we-provide-exceptional-service-today":
    "¿Nosotros proporcionamos un servicio excepcional hoy?",
  "do-you-have-any-comments-or-feedback-about-your-experience-with-us-today":
    "¿Tienes algún comentario o feedback sobre tu experiencia con nosotros hoy?",

  // Client Connect Director
  "message-from-leadership": "Mensaje de Liderazgo",
  "message-from-leadership-text":
    "Siempre estoy buscando formas de mejorar nuestras ofertas para nuestros clientes. ¿Hay aspectos que podríamos mejorar en tu opinión?",
  "client-connect-director-another-note":
    "En otra nota, si hay un tema específico que te gustaría que nuestro equipo abordara, no dudes en compartir. No estoy aquí solo para escuchar, sino para involucrarme y actuar sobre tus preocupaciones o ideas.",
  "your-message-here": "Tu mensaje aquí",
  "visit-information": "Información de la Visita",
  "visit-time-out": "Tiempo de la Visita",
  "contact-information": "Información de Contacto",
  "client-connect-director-contact-information-text":
    "Para proporcionar una asistencia mejorada, le pedimos sus detalles, incluyendo su nombre, dirección de correo electrónico y número de contacto. Esta información nos permitirá responder rápidamente y contactarlo.",
  name: "Nombre",
  "enter-your-name": "Introduce tu nombre",
  email: "Email",
  "enter-your-email": "Introduce tu email",
  "contact-number": "Número de Contacto",
  "please-enter-your-name": "Introduce tu nombre",
  "please-enter-your-email-or-phone-number":
    "Introduce tu email o número de teléfono",
  "invalid-email-address": "Dirección de email inválida",
  "invalid-phone-number-format":
    "Formato de número de teléfono inválido. Los formatos permitidos son: +44 seguido de 10 dígitos.",
  "please-select-time": "Selecciona el tiempo",
  "you-must-provide-at-least-one-answer-before-submitting-the-survey":
    "Debes proporcionar al menos una respuesta antes de enviar la encuesta.",

  // Mobile Survey Questions CheckBoxType
  comment: "Comentario",
  "comment-is-required": "El comentario es requerido!",
  "minimum-selection-is": "Mínima selección es {min_options}",
  "maximum-selection-is": "Máxima selección es {max_options}",
  "this-question-is-required": "Esta pregunta es requerida!",

  // Mobile Survey Questions CommentsAndImage
  "please-select-valid-image-files": "Selecciona archivos de imagen válidos.",

  // Mobile Survey Questions EmployeeType
  loading: "Cargando",
  "getting-employee": "Obteniendo empleado...",

  // Mobile Survey Questions FileUploadType
  "please-select-valid-image-or-video-files":
    "Selecciona archivos de imagen o video válidos.",

  // Mobile Survey Questions NumberType
  "please-enter-a-number-without-any-decimal-points":
    "Introduce un número sin puntos decimales.",
  "please-enter-a-number-less-than-or-equal-to":
    "Introduce un número menor o igual a {max_number_value}.",

  // Mobile Survey Questions SignatureType
  "please-complete-your-signature": "Completa tu firma.",

  // Mobile Survey Questions TextType
  "please-enter-valid-email-address": "Introduce un email válido.",
  "phone-number-should-be-10-digit":
    "El número de teléfono debe tener 10 dígitos.",
  "please-enter-at-least-characters":
    "Introduce al menos {minimum_character} caracteres.",
  "select-date": "Seleccionar Fecha",
  "select-time": "Seleccionar Tiempo",
  "select-country": "Seleccionar País",

  // Report Page
  "click-the-link-to-see-how": "Haga clic en el enlace para ver cómo.",
  "adding-your-teams-to-the-ServeFirst-reporting-platform":
    "Al agregar su equipo a la plataforma de informes de ServeFirst, también pueden iniciar sesión para ver su rendimiento, además; tiene la capacidad de gestionar su equipo y acceder a informes adicionales para comprender cómo está desempeñándose en general -",
  "did-you-know": "¿sabías que..",
  count: "conteo",
  average: "promedio",
  focus: "enfoque",
  performance: "Rendimiento",
  "you-can-create-bespoke-action-plans":
    "Puedes crear planes de acción personalizados que se filtran directamente aquí si se crean dentro de tu cuenta de ServeFirst, aquí puedes crear acciones, cronogramas y responsables al instante mientras sigues el rendimiento.",
  "no-of-times-raised": "Número de veces que se ha planteado",
  action: "Acción",
  "attribute-name": "Nombre del atributo",
  "action-plan": "Plan de acción",
  "that-by-adding-your-teams-to-the-ServeFirst-reporting-platform":
    "Al agregar tus equipos a la plataforma de informes ServeFirst, ellos también pueden iniciar sesión para ver su rendimiento, además; tienes la capacidad de gestionar tu equipo y acceder a informes adicionales para entender cómo estás desempeñándote en general.",
  result: "Resultado",
  category: "Categoría",
  "your-location-vs-all-location": "Tu ubicación vs. Todas las ubicaciones",
  "net-promoter-score": "Puntuación neta del promotor",
  "responses-breakdown": "Desglose de respuestas",
  "trended-net-promoter-score": "Puntuación neta del promotor con tendencia",
  "trended-results": "Resultados con tendencia",
  "net-promoter-score-info":
    "La puntuación neta del promotor se basa en el porcentaje de promotores (puntuación 5/5) menos el porcentaje de detractores (puntuación 1, 2 o 3), puedes obtener más información sobre la puntuación neta del promotor haciendo clic en lo siguiente",
  "customer-feedback": "Comentarios de clientes",
  logan: "Logan",
  "top-performer": "Mejor desempeño",
  "rank-in-the-country": "Clasificación en el país",
  "ytd-score": "Puntuación YTD",
  "score-during-period": "Puntuación durante el período",
  "0-from-last-month": "0 desde el mes pasado",
  "total-responses-for-july-2021": "Total de respuestas para julio de 2021",
  promoter: "Promotor",
  "from-04-sep-2021-to-06-sep-2021":
    "Desde el 04 de septiembre de 2021 hasta el 06 de septiembre de 2021",
  "the-focus-currently-is-on": "El enfoque actualmente está en",
  "1-number-of-customers-have-stated-this-as-an-opportunity":
    "1 número de clientes ha indicado esto como una oportunidad.",
  "focus-for-the-month": "Enfoque para el mes",
  "download-pdf": "Descargar pdf",

  // Report Page New Report
  "total-responses": "Total de Respuestas",
  duration: "Duración",
  "customers-have-stated-this-as-an-opportunity":
    "Los clientes han indicado esto como una oportunidad",
  "error-fetching-params": "¡Error al obtener los parámetros!",
  "previous-days-was": "Anterior {days} días fue {score}",

  // Report Page Common LgCard
  "raised-on": "# Elevado el",
  results: "Resultados",

  // Report Page Components AiSuggestions
  "analyzing-data-please-wait": "Analizando los datos, por favor espere...",

  // Report Page Components CustomerFeedBack
  "view-report": "Ver Informe",
  "received-on": "Recibido el",

  // Report Page Components ResponseBreakdown
  detractors: "Detractores",
  nps: "NPS",

  // Report Page Components TrendResults
  "trend-results": "Resultados con tendencia",

  // Survey helpers Utils
  "failed-to-fetch-the-pdf-file": "Error al obtener el archivo PDF",
  "error-generating-pdf-thumbnail": "Error al generar la miniatura del PDF",
  "error-in-thumbnail-generation-function":
    "Error en la función de generación de miniaturas",
  "failed-to-generate-pdf-thumbnail": "Error al generar la miniatura del PDF",

  // Survey Pages Audition
  "select-audit": "Seleccionar auditoría",

  // Survey Pages ClientConnect
  "select-atleast-min-options": "Seleccione al menos {minOptions} opciones",
  "select-max-options": "¡Se permiten un máximo de {maxOptions} opciones!",
  "error-saving-your-response": "¡Error al guardar su respuesta!",
  "failed-to-load-the-file": "¡Error al cargar el archivo!",
  "open-link": "Abrir enlace",
  download: "Descargar",
  details: "Detalles",
  title: "Título",
  description: "Descripción",
  "general-info": "Información general",
  type: "Tipo",
  size: "Tamaño",
  "uploaded-by": "Subido por",

  // Survey Pages ClientConnect Intro
  "thank-you-for-your-time": "¡Gracias por su tiempo!",

  // Survey Pages ClientConnect Content
  "review-content": "Revisar contenido",

  // Survey Pages ClientConnect Survey
  "please-give-us-your-feedback": "Por favor, danos tu opinión",
  "enter-your-response": "Ingrese su respuesta",

  // Survey Pages ClientConnect Director
  "i-am-constantly-seeking-ways-to-better-our-offerings-for-our-clientele":
    "Estoy buscando constantemente formas de mejorar nuestras ofertas para nuestra clientela. ¿Hay aspectos que podríamos mejorar en su opinión?",
  "on-another-note-if-there-s-a-specific-topic-you-d-like-our-team-to-address-don-t-hesitate-to-share":
    "Por otro lado, si hay un tema específico que le gustaría que nuestro equipo tratara, no dude en compartirlo. No estoy aquí solo para escuchar, sino para involucrarme y actuar sobre sus preocupaciones o ideas.",
  "to-provide-enhanced-assistance-we-kindly-ask-for-your-details-including-your-name-email-address-and-contact-number":
    "Para proporcionar asistencia mejorada, le pedimos amablemente que nos proporcione sus datos, incluidos su nombre, dirección de correo electrónico y número de contacto. Esta información nos permitirá responder de manera rápida y ponernos en contacto con usted.",

  // Survey Pages Location
  "select-the-location-you-visited": "Seleccione la ubicación que visitó",
  locations: "Ubicaciones",
  "is-this-your-current-location": "¿Es esta su ubicación actual?",
  "nearest-location": "Ubicación más cercana",
  "loading-data": "Cargando datos...",
  "please-wait": "¡Por favor, espere!",

  // Survey Pages Location Area
  "location-area": "Área de ubicación",
  "select-location-area": "Seleccionar área de ubicación",

  // Survey Pages Login
  "please-enter-your-password": "Por favor, ingrese su contraseña",
  "value-must-be-longer-than-3-characters":
    "El valor debe tener más de 3 caracteres",
  "please-enter-your-email-address":
    "Por favor, ingrese su dirección de correo electrónico",
  password: "Contraseña",
  "internal-server-error": "¡Error interno del servidor!",
  "sign-in": "Iniciar sesión",

  // Survey Pages SameDaySurvey
  "you-have-already-performed-this-survey": "¡Ya ha realizado esta encuesta!",

  // Survey Pages SurveyThankYou
  "your-feedback-has-been-submitted":
    "Su retroalimentación ha sido enviada. Su número de ticket es #",
  "a-member-of-the-team-will-be-in-touch-shortly":
    "Un miembro del equipo se pondrá en contacto con usted en breve.",
  "would-you-also-like-to-rate-us-on-google":
    "¿Le gustaría calificar nuestros servicios en Google?",
  "to-review-our-terms-and-conditions-please-click":
    "Para revisar nuestros Términos y Condiciones, haga clic en",
  here: "Aquí",
  "ticket-number-copied-to-clipboard":
    "Número de ticket copiado al portapapeles",

  // Survey Pages Welcome
  "visit-date": "Fecha de visita",
  "visit-time-in": "Hora de entrada",
  "please-select-date": "Por favor, seleccione la fecha",
  "guest-info": "Información del huésped",
  "please-select-user": "Por favor, seleccione el usuario",
  "get-started": "Comenzar",

  // Survey Pages InventoryType
  "enter-value": "Ingrese el valor",
  "getting-product-list": "Obteniendo lista de productos...",

  // Survey Pages NotesAndImage
  "upload-image": "Subir imagen",

  // Survey Pages NumberType
  "enter-digit": "Ingrese un número",

  // Survey Pages TextArea
  "enter-your-comment": "Ingrese su comentario",

  // Survey Pages Upload
  "file-size-should-be-less-than-10-mb":
    "El tamaño del archivo debe ser menor a 10 MB",
  "drag-you-files-here-file-size-less-than-10-mb-or":
    "Arrastre sus archivos aquí, tamaño de archivo menor a 10 MB o",
  "browse-files": "Explorar archivos",

  // Survey Pages Questions
  "next-question": "Siguiente pregunta",
  "please-enter-at-least": "Por favor ingrese al menos {min} caracteres.",
  "contact-no": "Número de contacto",
  "please-select-location": "¡Por favor seleccione la ubicación!",
  "please-select-survey": "¡Por favor seleccione la encuesta!",
  "please-enter-your-email-address-or-phone-number":
    "Por favor ingrese su dirección de correo electrónico o número de teléfono.",

  // Utils CompressFiles
  "compression-error": "Error de compresión:",

  // Web Rating FrontLogin
  "enter-location-id": "Ingrese el ID de ubicación",
  "invalid-location-id": "ID de ubicación no válida",
  "location-id-required": "ID de ubicación requerida",

  // Web Rating Index
  "please-tell-us-how-your-experience-was-today":
    "Por favor, cuéntenos cómo fue su experiencia hoy.",

  // Web Rating Negativefeedback
  "sorry-to-hear-you-didn-t-have-the-best-experience-today-with-us-please-could-you-highlight-what-went-wrong":
    "Lamentamos escuchar que no tuvo la mejor experiencia hoy con nosotros, ¿podría señalar qué salió mal?",
  "enter-valid-email-address":
    "Ingrese una dirección de correo electrónico válida.",
  "can-we-get-in-touch-with-you": "¿Podemos ponernos en contacto con usted?",

  // Web Rating Positivefeedback
  "enter-your-telephone-number": "Ingrese su número de teléfono",
  "write-your-feedback": "Escriba sus comentarios",

  // Web Rating Positivefeedback2
  "what-made-the-employee-name-stand-out-to-you":
    "¿Qué hizo que {employeeName} se destacara para usted?",
  team: "Equipo",

  // Web Rating PositivefeedbackForm
  "please-go-to-previous-step-and-select-employee-without-employee-selection-not-able-to-submit-feedback-form":
    "Por favor, regrese al paso anterior y seleccione un empleado, sin selección de empleado no puede enviar el formulario de comentarios",
  "share-us-your-experience-with-us-today":
    "Comparta su experiencia con nosotros hoy...",
  "did-employee-name-provide-exceptional-service":
    "¿Proporcionó {employeeName} un servicio excepcional?",
  "did-employee-name-stand-out-for-delivering-exceptional-service":
    "¿Se destacó {employeeName} por ofrecer un servicio excepcional?",

  // Web Rating New apiHelpers loginHelpers
  "please-enter-user-name": "Por favor, ingrese el nombre de usuario",
  "please-enter-password": "Por favor, ingrese la contraseña",

  // Web Rating New RatingLocations
  search: "Buscar",
  "select-loction-to-ratings": "Seleccione la ubicación para las valoraciones",
  location: "Ubicación",
  "is-this-your-location": "¿Es esta su ubicación?",
  "fetch-details-failed": "Error al obtener los detalles",

  // Web Rating New PositiveJourny EmployeDetail
  "click-as-many-as-you-want": "Haga clic tantas veces como desee",
  "what-made-employee-name-stand-out-to-you":
    "¿Qué hizo que {employeeName} se destacara para usted?",
  "what-made-the-team-stand-out-to-you":
    "¿Qué hizo que el equipo se destacara para usted?",

  // Web Rating New PositiveJourny EmployeService
  "did-we-stand-out-for-delivering-exceptional-service":
    "¿Nos destacamos por ofrecer un servicio excepcional?",

  // Web Rating New NegativeJourny EmployePersonalDetail
  "leave-an-email-or-phone-number-if-you-want-us-to-get-in-touch-otherwise-just-leave-it-blank-then-press-next":
    "Deje un correo electrónico o número de teléfono si desea que nos pongamos en contacto, de lo contrario, déjelo en blanco y presione siguiente",
  "anything-else-you-d-like-to-share": "¿Algo más que le gustaría compartir?",
  "enter-valid-email": "Ingrese un correo electrónico válido",
  message: "Mensaje",
  "by-signing-up-the-form-you-agree-to-our-terms-and-privacy":
    "Al registrarse en el formulario, acepta nuestros Términos y Condiciones y Política de Privacidad.",

  // Web Rating New FrontWebLogin
  login: "Iniciar sesión",
  username: "Nombre de usuario",

  // Web Rating New FrontRating
  "press-the-facial-expression-that-best-represents-how-you-feel-about-your-experience":
    "Presione la expresión facial que mejor representa cómo se siente acerca de su experiencia.",
  "getting-employee-list": "Obteniendo lista de empleados",
  "getting-skill-data": "Obteniendo datos de habilidades",
  "getting-screen-saver": "Obteniendo protector de pantalla",

  // Web Rating New CommonComponents ThankYouModal
  "thank-you-for-contacting-us": "Gracias por contactarnos.",
  "we-ll-be-in-touch-very-soon": "Nos pondremos en contacto muy pronto.",

  // Web Rating New CommonComponents GoogleQrPage
  "you-can-follow-this-button-to-rate-us-on-google":
    "Puede seguir este botón para calificarnos en Google",
  "rate-us-on-google": "Califíquenos en Google",

  // Web Rating New CommonComponents ContactUsModal
  "contact-us": "Contáctenos",
  "or-you-may-reach-us-on": "...o puede contactarnos en.",
  "do-you-want-to-learn-more-about-serve-first":
    "¿Quiere aprender más sobre Serve First?",

  // Web Rating New CommonComponents BottomBar
  saving: "Guardando",
  "please-enter-only-digits-for": "Por favor, ingrese solo dígitos para",
  "please-enter": "Por favor, ingrese",
  "enter-value-here": "Ingrese valor aquí",
};
