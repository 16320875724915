import React, { useEffect, useState } from "react";
import { addAnswer } from "../../../../redux/survey/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1%",
    gridGap: "15px",
    margin: "calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));",
  },
  card: {
    width: "150px",
    border: "1px solid #64748b",
    borderRadius: "5px",
    padding: "12px 20px",
    cursor: "pointer",
    color: "#334155",
    "&:hover": {
      backgroundColor: "#f1f5f9",
      borderColor: "#64748b",
    },
    transition: ".15s ease",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "250px",
      padding: "8px",
    },
  },
  image: {
    width: "120px",
    objectFit: "scale-down",
    borderRadius: "4px",
    marginBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2px",
      width: "100%",
      height: "200px",
    },
  },
}));

function EmployeeSelect(props) {
  const intl = useIntl();
  const { question, answeres, addAnswer_, loading } = props;
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [employeesOption, setEmployeesOption] = useState(
    localStorage.getItem("employees")
      ? JSON.parse(localStorage.getItem("employees"))
      : []
  );
  let answer = answeres[question._id] ?? "";

  const handleChange = (value) => {
    addAnswer_({ id: question._id, value: value, type: "id" });
    // headTonext(null);
  };

  useEffect(() => {
    const empData = localStorage.getItem("employees")
      ? JSON.parse(localStorage.getItem("employees"))
      : [];
    setEmployeesOption(empData);
  }, [loading]);

  return (
    <Box className={classes.container}>
      {!loading ? (
        employeesOption.length ? (
          employeesOption.map((item) => (
            <Box
              key={Math.random()}
              onClick={() => handleChange(item.id)}
              className={classes.card}
              style={{
                backgroundColor: item.id === answer?.value && "#e2e8f0",
                borderColor: item.id === answer?.value && "#e2e8f0",
              }}
            >
              <div>
                <img
                  src={item.image}
                  alt="employee"
                  className={classes.image}
                />
              </div>
              <Typography
                style={{
                  textAlign: "center",
                  marginTop: "2px",
                  fontWeight: item.id === answer?.value && 500,
                }}
              >
                {item.name}
              </Typography>
            </Box>
          ))
        ) : null
      ) : (
        <div>{intl.formatMessage({ id: "getting-employee" })}</div>
      )}
    </Box>
  );
}
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSelect);
