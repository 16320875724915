import React from 'react'
import { useIntl } from 'react-intl';

export default function FeedbackOnceAlert(props){
    const intl = useIntl();
    return (
        <section className="thankyou-wrapper">
        <div className="container">
            <h1>{intl.formatMessage({ id: "feedback-once-alert" })}</h1>
            
        </div>
        </section>
      
    );
}