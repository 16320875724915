import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../ContactUsModal/style.css";
import { useIntl } from "react-intl";

const ThankYouModal = ({ isVisible, closeModal }) => {
  const intl = useIntl();
  return (
    <>
      <Modal
        center
        open={isVisible}
        classNames={{
          overlay: "react-responsive-modal-overlay-a",
          modal: "react-responsive-modal-modal-a",
        }}
        showCloseIcon={false}
        onClose={closeModal}
      >
        <div
          style={{
            height: "65vh",
            width: "50vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src="images/webRating/black.png"
            style={{ color: "white" }}
            alt="logo"
          />

          <h4 style={{ textAlign: "center", fontWeight: 400, margintop: "2%" }}>
            {intl.formatMessage({ id: "thank-you-for-contacting-us" })}
            <br /> {intl.formatMessage({ id: "we-ll-be-in-touch-very-soon" })}
          </h4>
        </div>
      </Modal>
    </>
  );
};
export default ThankYouModal;
