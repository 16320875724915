import React, { useState } from "react";
import SelectedEmoji from "./SelectedEmoji";

import NegativeChatReply from "./NegativeChatReply";
import PositiveChatReply from "./PositiveChatReply";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setFrontratingData,
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
  toggleAlphegaActiveState,
} from "../redux/frontWebRating/actions";
import { useContext } from "react";
import { ThemeUpdateContext } from "../App";
import { useTheme } from "@material-ui/core";

import "../assets/css/MobileRatingMainStyles.css";
import "../assets/css/MobileRatingCustomStyles.css";

//load images
import emoji_hate_image from "../assets/images/mobile_emoji/hate.png";
import emoji_disappointed_image from "../assets/images/mobile_emoji/disappointed.png";
import emoji_neutral_image from "../assets/images/mobile_emoji/neutral.png";
import emoji_like_image from "../assets/images/mobile_emoji/like.png";
import emoji_love_image from "../assets/images/mobile_emoji/love.png";
import user_image from "../assets/images/mobile_emoji/lady.jpg";
import image_serve_first from "../assets/images/mobile_emoji/serve-first.png";
import { getDetails } from "../webRatingNew/apiHelpers/frontRating";
import AlphegaQuestions from "./alphega-questions";
import LoadingOverlay from "react-loading-overlay";
import { useIntl } from "react-intl";

function FrontMobileHome(props) {
  const intl = useIntl();
  const { changeTheme } = useContext(ThemeUpdateContext);
  const theme = useTheme();

  const history = useHistory();
  const [isSelectedEmojiDisplay, setisSelectedEmojiDisplay] = useState(false);
  const [isDispNegativeReply, setisDispNegativeReply] = React.useState(false);
  const [isDispPositiveReply, setisDispPositiveReply] = React.useState(false);
  const location = localStorage.getItem("location_id") || "";

  const [loading, setLoading] = useState(false);

  const {
    alphegaActive,
    webRatingData,
    webRatingAnsweres,
    setFrontratingData_,
    toggleAlphegaActiveState_,
  } = props;

  const getFeedback = (emojiImagePath, rating, customMode) => {
    localStorage.setItem("front-mobile-chatbox-emoji-path", emojiImagePath);
    localStorage.setItem("front-mobile-chatbox-rating", rating);
    setisSelectedEmojiDisplay(true);
    !customMode && props.resetFrontratingAnsweres_();
    props.setFrontratingAnsweres_({
      key: "journey",
      value:
        localStorage.getItem("front-mobile-chatbox-rating") > 3 ? "pos" : "neg",
    });
    props.setFrontratingAnsweres_({
      key: "rating",
      value: rating,
    });
    if (localStorage.getItem("front-mobile-chatbox-rating") < 4) {
      setisDispNegativeReply(true);
    } else if (localStorage.getItem("front-mobile-chatbox-rating") > 3) {
      setisDispPositiveReply(true);
    }
  };

  const handleResize = () => {
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 1170) {
      history.push("/front/mobile-home");
    } else {
    }
  };

  React.useEffect(async () => {
    setLoading(true);
    let details = await getDetails(location);
    if (details) {
      setFrontratingData_(details);

      changeTheme({
        primaryColor: details?.app_color,
        secondaryColor: details?.app_color,
      });

      if (details?.is_alphiga) {
        toggleAlphegaActiveState_(true);
      }
    }

    localStorage.removeItem("front-mobile-chatbox-emoji-path");
    localStorage.removeItem("front-mobile-chatbox-rating");
    setisSelectedEmojiDisplay(false);
    if (webRatingAnsweres.rating) {
      const customMode = true;
      switch (webRatingAnsweres.rating) {
        case "1":
          getFeedback(emoji_hate_image, 1, customMode);
          break;

        case "2":
          getFeedback(emoji_disappointed_image, 2, customMode);
          break;

        case "3":
          getFeedback(emoji_neutral_image, 3, customMode);
          break;

        case "4":
          getFeedback(emoji_like_image, 4, customMode);
          break;

        case "5":
          getFeedback(emoji_love_image, 5, customMode);
          break;
        default:
          break;
      }
    }
    handleResize();
    setLoading(false);
  }, []);

  const resetToIntitialChatDisp = () => {
    setisSelectedEmojiDisplay(false);
    setisDispNegativeReply(false);
    setisDispPositiveReply(false);
  };

  const [list, setList] = useState([]);
  const updateList = async () => {
    setList([...list, 1]);
  };

  React.useEffect(() => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, [list]);

  const initialChatDisplay = () => {
    if (isSelectedEmojiDisplay) {
      return <SelectedEmoji />;
    } else {
      return (
        <div className="fixed z-10 w-full max-w-lg px-3 mx-auto -translate-x-1/2 left-1/2 bottom-5 ">
          <div className="p-3 overflow-hidden bg-white rounded-full shadow">
            <div className="flex items-center justify-center space-x-5">
              <button type="button">
                <img
                  className="w-10 h-10 mx-auto"
                  src={emoji_hate_image}
                  alt="emoji-1"
                  onClick={() => getFeedback(emoji_hate_image, 1)}
                />
              </button>
              <button type="button">
                <img
                  className="w-10 h-10 mx-auto"
                  src={emoji_disappointed_image}
                  alt="emoji-2"
                  onClick={() => getFeedback(emoji_disappointed_image, 2)}
                />
              </button>
              <button type="button">
                <img
                  className="w-10 h-10 mx-auto"
                  src={emoji_neutral_image}
                  alt="emoji-3"
                  onClick={() => getFeedback(emoji_neutral_image, 3)}
                />
              </button>
              <button type="button">
                <img
                  className="w-10 h-10 mx-auto"
                  src={emoji_like_image}
                  alt="emoji-4"
                  onClick={() => getFeedback(emoji_like_image, 4)}
                />
              </button>
              <button type="button">
                <img
                  className="w-10 h-10 mx-auto"
                  src={emoji_love_image}
                  alt="emoji-5"
                  onClick={() => getFeedback(emoji_love_image, 5)}
                />
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      className="overflow-hidden"
      styles={{
        content: { height: "100vh", display: "flex", width: "100vw" },
      }}
    >
      {!loading ? (
        <div
          className="mobile-view-rating-app min-h-screen font-sans antialiased text-black bg-gray-100 selection:text-white selection:bg-purple-600"
          id="scroll-rating-mobile-view"
        >
          <div className="relative max-w-lg min-h-screen mx-auto border-l border-r border-gray-200 pb-18">
            <div className="sticky top-0 left-0 z-10 w-full px-4 py-3 text-center bg-white border-b border-gray-100 shadow">
              <img
                className="w-auto h-8 mx-auto"
                src={
                  webRatingData.company_logo
                    ? webRatingData.company_logo
                    : image_serve_first
                }
                alt="first-serve-img"
              />
            </div>
            <div
              className="space-y-5 whole-page"
              style={{ padding: "1.25rem" }}
            >
              <div className="flex items-end justify-start pr-8 transition-all duration-200">
                <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full ">
                  <img
                    className="object-cover w-full h-full"
                    src={user_image}
                    alt="logo"
                  />
                </div>
                <div
                  className="ml-3 text-white rounded-bl-none rounded-2xl shadow"
                  style={{ backgroundColor: theme.palette.primary.main }}
                >
                  <div
                    style={{
                      paddingTop: " 0.75rem",
                      paddingBottom: " 0.75rem",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <span className="block leading-tight">
                      {intl.formatMessage({ id: "how-was-your-experience-with-us-today" })}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ajax-div-question-one">
                {initialChatDisplay()}

                {webRatingData?.is_alphiga ? (
                  <>
                    {isDispNegativeReply || isDispPositiveReply ? (
                      <AlphegaQuestions />
                    ) : null}
                  </>
                ) : null}

                {isDispNegativeReply === true && !alphegaActive ? (
                  <NegativeChatReply
                    skillData={webRatingData.skill_negative}
                    resetToIntitialChatDisp={resetToIntitialChatDisp}
                    updateList={updateList}
                  />
                ) : null}

                {isDispPositiveReply === true && !alphegaActive ? (
                  <PositiveChatReply
                    skillData={webRatingData.skill_positive}
                    employeeData={webRatingData?.employee}
                    resetToIntitialChatDisp={resetToIntitialChatDisp}
                    updateList={updateList}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="vw-100 vh-100" />
      )}
    </LoadingOverlay>
  );
}

const mapStateToProps = (state) => {
  return {
    alphegaActive: state.frontWebRatingDataReducer.alphegaActive,
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingData_: (data) => dispatch(setFrontratingData(data)),
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
    toggleAlphegaActiveState_: (data) =>
      dispatch(toggleAlphegaActiveState(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontMobileHome);
