import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeUpdateContext } from "../../App";
import { setFrontratingData } from "../../redux/frontWebRating/actions";
import ContactUsModal from "../CommonComponents/ContactUsModal";
import { getDetails } from "../apiHelpers/frontRating";
import { useStyles } from "./style";
import { useIntl } from "react-intl";

import {
  resetFrontratingAnsweres,
  setFrontratingAnsweres,
} from "../../redux/frontWebRating/actions";

import moment from "moment";
import { postData } from "../../components/FetchServices";
import ThankYouModal from "../CommonComponents/ThankYouModal";
function FrontRating(props) {
  const intl = useIntl();
  const { changeTheme } = useContext(ThemeUpdateContext);
  const classes = useStyles();
  const history = useHistory();
  const { webRatingData, webRatingAnsweres } = props;

  useEffect(() => {
    const refreshMins = 4;

    const refreshImageUrls = async (userIds) => {
      const result = await postData("api/refreshRatingImageUrls", {
        user_id: userIds.join(","),
      });
      if (result.success) {
        props.setFrontratingData_({ ...webRatingData, employee: result.data });
      }
    };

    const intervalId = setInterval(() => {
      const detailsFetchOn = localStorage.getItem("detailsFetchedOn") ?? null;
      if (detailsFetchOn) {
        const diffInMins = Math.abs(
          moment(detailsFetchOn).diff(moment(), "minute")
        );
        if (
          webRatingData?.employee &&
          webRatingData.employee.length &&
          diffInMins >= refreshMins
        ) {
          const userIds = webRatingData.employee.map(({ id }) => id);
          refreshImageUrls(userIds);
        }
      }
    }, 60000 * refreshMins);

    return () => {
      clearInterval(intervalId);
    };
  });

  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useState(
    localStorage.getItem("location_id") || ""
  );
  const [loading, setLoading] = useState(true);
  const [loadingState, setLoadingState] = useState(0);

  const [contactUsModal, setContactUsModal] = useState(false);
  const [thankYouModal, setThankYouModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(["rating"]);
  useEffect(async () => {
    let details = await getDetails(location);
    localStorage.setItem("detailsFetchedOn", new Date());
    if (details.allow_frequent_ratings === true) {
      localStorage.setItem(
        "is_frequent_rating",
        details.allow_frequent_ratings
      );
    } else {
      localStorage.removeItem("is_frequent_rating");
    }

    localStorage.setItem("app_color", details?.app_color);
    changeTheme({
      primaryColor: details?.app_color,
      secondaryColor: details?.app_color,
    });
    if (details) {
      setTimeout(() => {
        setLoadingState(1);
      }, 400);
      setTimeout(() => {
        setLoadingState(2);
      }, 800);
      setTimeout(() => {
        setLoadingState(3);
      }, 1000);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }

    details && props.setFrontratingData_(details);
    if (webRatingAnsweres.rating) {
      const ratingCookie = cookies.rating;
      if (
        ratingCookie?.location === props.webRatingAnsweres.location_id &&
        !localStorage.getItem("is_frequent_rating")
      ) {
        toast.error(
          intl.formatMessage({ id: "you-have-already-submitted-rating" })
        );
        return;
      }
      webRatingAnsweres.journey === "pos" && history.push("/positiveJourny");
      webRatingAnsweres.journey === "neg" && history.push("/negativeJourny");
    }
  }, [location]);

  // const handleChangeLocation = (event) => {
  //   setLocation(event.target.value);
  //   props.setFrontratingAnsweres_({
  //     key: "location_id",
  //     value: event.target.value,
  //   });
  //   localStorage.setItem("location_id", event.target.value);
  // };
  const selectJourny = (e) => {
    let ratingCookie = cookies.rating;
    if (
      ratingCookie?.location === props.webRatingAnsweres.location_id &&
      !localStorage.getItem("is_frequent_rating")
    ) {
      toast.error(
        intl.formatMessage({ id: "you-have-already-submitted-rating" })
      );
      return;
    }
    let journey = e.target.name;
    props.resetFrontratingAnsweres_();
    props.setFrontratingAnsweres_({
      key: "journey",
      value: journey === "positive" ? "pos" : "neg",
    });
    props.setFrontratingAnsweres_({
      key: "rating",
      value: e.target.id,
    });
    journey === "positive" && history.push("/positiveJourny");
    journey === "negative" && history.push("/negativeJourny");
  };

  useEffect(() => {
    changeTheme({
      primaryColor: webRatingData.app_color,
      secondaryColor: webRatingData.app_color,
    });
  }, []);
  return (
    <>
      <LoadingOverlay
        active={loading}
        // spinner={<FadeLoader />}
        spinner
        text={
          !loadingState ? (
            <p>
              {intl.formatMessage({ id: "getting-employee-list" })}... <br />
              {intl.formatMessage({ id: "getting-skill-data" })}... <br />
              {intl.formatMessage({ id: "getting-screen-saver" })}...
            </p>
          ) : loadingState === 1 ? (
            <p>
              {intl.formatMessage({ id: "getting-employee-list" })} ✓ <br />
              {intl.formatMessage({ id: "getting-skill-data" })}... <br />
              {intl.formatMessage({ id: "getting-screen-saver" })}...
            </p>
          ) : loadingState === 2 ? (
            <p>
              {intl.formatMessage({ id: "getting-employee-list" })} ✓ <br />
              {intl.formatMessage({ id: "getting-skill-data" })} ✓ <br />
              {intl.formatMessage({ id: "getting-screen-saver" })}...
            </p>
          ) : loadingState === 3 ? (
            <p>
              {intl.formatMessage({ id: "getting-employee-list" })} ✓ <br />
              {intl.formatMessage({ id: "getting-skill-data" })} ✓ <br />
              {intl.formatMessage({ id: "getting-screen-saver" })} ✓
            </p>
          ) : null
        }
      >
        <div className={classes.mainContainer}>
          <ContactUsModal
            isVisible={contactUsModal}
            closeModal={() => setContactUsModal(false)}
            openThankYouModal={() => setThankYouModal(true)}
          />

          <ThankYouModal
            isVisible={thankYouModal}
            closeModal={() => setThankYouModal(false)}
          />

          <div className={classes.topContainer}>
            {/* {webRatingData.is_multilocation ? (
            <div className={classes.topDropdown}>
              <select
                value={location}
                handleChangeLocation={handleChangeLocation}
              >
                <option>Sheffield Secondary Schools</option>
                <option>Sheffield Secondary Schools</option>
                <option>Sheffield Secondary Schools</option>
              </select>
            </div>
          ) : null} */}
          </div>
          <div className={classes.midContainer}>
            <div className={classes.midTitle}>
              <h1 className={classes.midTitleHeading}>
                {intl.formatMessage({
                  id: "please-tell-us-how-your-experience-was-today",
                })}
              </h1>
              <h3 className={classes.midTitleDesc}>
                {intl.formatMessage({
                  id: "press-the-facial-expression-that-best-represents-how-you-feel-about-your-experience",
                })}
              </h3>
            </div>
            <div className={classes.midEmoji} style={{ cursor: "pointer" }}>
              <img
                className={classes.emoji}
                src="images/webRating/emoji_1.gif"
                name="negative"
                id="1"
                onClick={selectJourny}
                alt="neg1"
              />
              <img
                className={classes.emoji}
                src="images/webRating/emoji_2.gif"
                name="negative"
                id="2"
                onClick={selectJourny}
                alt="neg2"
              />
              <img
                className={classes.emoji}
                src="images/webRating/emoji_3.gif"
                name="negative"
                id="3"
                onClick={selectJourny}
                alt="neg3"
              />
              <img
                className={classes.emoji}
                src="images/webRating/emoji_4.gif"
                name="positive"
                id="4"
                onClick={selectJourny}
                alt="pos4"
              />
              <img
                className={classes.emoji}
                src="images/webRating/emoji_5.gif"
                name="positive"
                id="5"
                onClick={selectJourny}
                alt="pos5"
              />
            </div>
          </div>
          <div className={classes.bottomContainer}>
            {/* <Divider /> */}

            <img
              src={
                webRatingData.company_logo
                  ? webRatingData.company_logo
                  : "images/webRating/black.png"
              }
              style={{ height: "50px", cursor: "pointer" }}
              onClick={() => {
                setContactUsModal(true);
              }}
              alt="logo"
            />
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingData_: (data) => dispatch(setFrontratingData(data)),
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontRating);
