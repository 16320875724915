import { Box, Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThemeUpdateContext } from "../../../App";
import { commonPageMakeStyles } from "../../common/styles/commonPageStyles";

import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearAnsweres,
  clearAnswersAndLocations,
  setLocations,
} from "../../../redux/survey/actions";
import Background from "../../common/components/Background";
import PaperContainer from "../../common/components/PaperContainer";
import { postData } from "../../helpers/fetch_services";
import { LOCATION_STATUS, getLatLong } from "../../helpers/navigatorHelpers";
import LocationCard from "./LocationCard";
import { isCompanyLogin } from "../../helpers/Utils";

// Import Framer Motion
import { motion } from "framer-motion";
import { useIntl } from "react-intl";
import { updateLocale } from "../../../lang";

// Main Location component
function Location(props) {
  const intl = useIntl();
  // Destructure props
  const {
    locations,
    setLocations_,
    clearAnsweres_,
    clearAnswersAndLocations_,
    survey_theme,
    isSurveyWithoutLogin,
    survey_without_login,
  } = props;

  // Initialize states and hooks
  const history = useHistory();

  const [logo, setLogo] = useState("/images/webRating/serve-first.png");
  const { changeTheme } = useContext(ThemeUpdateContext);
  const [loading, setLoading] = useState(true);
  const commonPageCss = commonPageMakeStyles();

  const [latLong, setlatLong] = useState({ lat: null, long: null });
  const { UNKNOWN, ALLOWED } = LOCATION_STATUS;
  const [locationStatus, setLocationStatus] = useState(UNKNOWN);
  const [showAllLocations, setShowAllLocation] = useState(false);
  //Searchbox state
  const [searchText, setSearchText] = useState("");

  // Function to handle location selection
  const onClickLocation = (item) => {
    localStorage.setItem("location", item._id);
    if (isCompanyLogin()) {
      localStorage.setItem("total_location_survey", item?.surveyCount || 0);
    }
    history.push({
      pathname: "/survey/audition",
      state: { surveyWithoutLogin: isSurveyWithoutLogin },
    });
  };

  // Function to fetch survey locations based on tags
  const getTagLocationSurvey = async () => {
    const result = await postData("frontEmployeeFeedback/getNearByLocations", {
      lat: latLong.lat || 0,
      long: latLong.long || 0,
      user_id: localStorage.getItem("user_id"),
      company_id: localStorage.getItem("company_id"),
      tag_id: localStorage.getItem("tag_id"),
      survey_id: localStorage.getItem("survey"),
    });

    if (result.success && result?.data?.length) {
      const locations = result.data;
      setLocations_({ locations });
      clearAnsweres_();
      setLoading(false);
    } else {
      localStorage.clear();
      clearAnswersAndLocations_();
      toast.error(result.message);
      history.push("/survey/login");
    }
  };

  // useEffect for fetching and setting data
  useEffect(() => {
    if (locationStatus === UNKNOWN) {
      getLatLong(setlatLong, setLocationStatus, setShowAllLocation);
    } else {
      getTagLocationSurvey();
    }

    const logoLocal = localStorage.getItem("logo");
    if (logoLocal) setLogo(logoLocal);

    changeTheme({
      primaryColor: props.survey_theme.primaryColor,
      secondaryColor: props.survey_theme.secondaryColor,
      backgroundColor: props.survey_theme.backgroundColor,
      highlightColor: props.survey_theme.highlightColor,
      fontColor: props.survey_theme.fontColor,
    });
  }, [locationStatus]);

  // Framer Motion variants for animation
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.15 } },
  };

  // View Rendering
  return (
    <>
      <LoadingOverlay active={loading} spinner>
        <Background>
          <div className="mt-3"></div>

          {locationStatus !== UNKNOWN && loading ? (
            <PaperContainer logo={logo}>
              <Box style={{ paddingLeft: 5, paddingTop: 5 }} className="mt-4">
                <Typography variant="h4" className="text-center mt-2">
                  {intl.formatMessage({ id: "please-wait" })}
                </Typography>
                <Typography variant="h6" className="text-center mt-3">
                  {intl.formatMessage({ id: "loading-data" })}
                </Typography>
              </Box>
            </PaperContainer>
          ) : null}

          {locationStatus === ALLOWED && !showAllLocations && !loading ? (
            <PaperContainer logo={logo}>
              <Box style={{ paddingLeft: 5, paddingTop: 5 }} className="mt-4">
                <Typography variant="h4" className={commonPageCss.nearestTitle}>
                  {intl.formatMessage({ id: "nearest-location" })}
                </Typography>
                <Typography
                  variant="h6"
                  className={commonPageCss.nearestSubTitle}
                >
                  {intl.formatMessage({ id: "is-this-your-current-location" })}
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                style={{ padding: 5 }}
              >
                {locations.length
                  ? [locations[0]].map((item) => (
                      <Grid item xs={12} md={6} key={Math.random()}>
                        <motion.div
                          variants={itemVariants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                        >
                          <LocationCard
                            title={item.name}
                            distance={Number(item?.distance) ?? 0}
                            address1={item.address}
                            // address2={item.address_2}
                            isFirstLocation={true}
                          />
                        </motion.div>
                        <div className="mt-4 pt-1">
                          <Grid container justifyContent="space-between">
                            <button
                              style={{
                                float: "right",
                                padding: "6px 40px",
                                border: `2px solid ${survey_theme.primaryColor}`,
                                borderRadius: "5px",
                                background: survey_theme.primaryColor,
                              }}
                              className="font-weight-bold btn-success uppercase"
                              onClick={() => {
                                setShowAllLocation(true);
                                localStorage.setItem("showAllLocation", true);
                              }}
                            >
                              {intl.formatMessage({ id: "no" })}
                            </button>

                            <button
                              style={{
                                float: "left",
                                padding: "6px 40px",
                                background: survey_theme.primaryColor,
                                borderRadius: "5px",
                                border: `2px solid ${survey_theme.primaryColor}`,
                              }}
                              className="btn-success font-weight-bold uppercase"
                              onClick={() => onClickLocation(item)}
                            >
                              {intl.formatMessage({ id: "yes" })}
                            </button>
                          </Grid>
                        </div>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </PaperContainer>
          ) : (
            ""
          )}

          {locationStatus !== UNKNOWN && showAllLocations && !loading ? (
            <PaperContainer logo={logo}>
              <Box style={{ paddingLeft: 5, paddingTop: 5, marginBottom: 20 }}>
                <Typography variant="h4" className={commonPageCss.pageTitle}>
                  {intl.formatMessage({ id: "locations" })}
                </Typography>
                <Typography>
                  {survey_without_login?.location_text
                    ? survey_without_login?.location_text
                    : intl.formatMessage({
                        id: "select-the-location-you-visited",
                      })}
                </Typography>
              </Box>

              {/* Add Search box in Grid */}
              <Grid style={{ padding: 5, marginBottom: 10, marginTop: "2%" }}>
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder={intl.formatMessage({ id: "search-location" })}
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    fontSize: "25px",
                  }}
                />
              </Grid>

              <Grid container spacing={2} style={{ padding: 5 }}>
                {locations
                  .filter(
                    (item) =>
                      item?.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase()) ||
                      (item?.address || "")
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                  )
                  .map((item) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      onClick={() => onClickLocation(item)}
                      key={Math.random()}
                    >
                      <motion.div
                        variants={itemVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                        <LocationCard
                          title={item.name}
                          distance={Number(item?.distance) ?? 0}
                          address1={item.address || ""}
                        />
                      </motion.div>
                    </Grid>
                  ))}
              </Grid>
            </PaperContainer>
          ) : (
            ""
          )}
        </Background>
      </LoadingOverlay>
    </>
  );
}

// Redux mapStateToProps and mapDispatchToProps
const mapStateToProps = (state) => {
  return {
    locations: state.survey.locations,
    survey_theme: state.survey.survey_theme,
    isSurveyWithoutLogin: state.survey.isSurveyWithoutLogin,
    survey_without_login: state.survey.survey_without_login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocations_: (data) => dispatch(setLocations(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
    clearAnswersAndLocations_: () => dispatch(clearAnswersAndLocations()),
  };
};
// Connect to the redux store
export default connect(mapStateToProps, mapDispatchToProps)(Location);
