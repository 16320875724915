import React from "react";
// import * as reportStyles from "../Reportstyle.module.css";
import reportStyles from "../Reportstyle.module.css";
import moment from "moment";
import { useIntl } from "react-intl";

const ADMIN_URL = process.env.REACT_APP_URL;

function CustomerFeedBack(props) {
  const intl = useIntl();
  const reviews = props.data;

  const redirectToAdminPanel = () => {
    window.open(`${ADMIN_URL}/app/dashboard/customer`, "_blank");
  };

  return (
    <div class={reportStyles.divbackground4}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 class={reportStyles.heading2}>
          {intl.formatMessage({ id: "customer-feedback" })}
        </h3>

        <button
          onClick={redirectToAdminPanel}
          class={reportStyles.button}
          style={{ backgroundColor: props.color }}
        >
          {intl.formatMessage({ id: "view-report" })}
        </button>
      </div>
      <div className="table-responsive-sm">
        <table className="table  table-borderless mt-2">
          <thead>
            <tr>
              <th className="pl-2">#</th>
              <th style={{ maxWidth: "70%" }}>
                {intl.formatMessage({ id: "feedback" })}  
              </th>
              <th>
                {intl.formatMessage({ id: "received-on" })}
              </th>
            </tr>
          </thead>
          <tbody>
            {reviews.length
              ? reviews.map(({ feedback, createdAt }, i) => (
                  <tr
                    style={{
                      background: i % 2 !== 0 ? "#f4f4f4" : "",
                    }}
                  >
                    <td className="pl-2 pt-2">{i + 1}</td>
                    <td style={{ maxWidth: "70%" }} className="pt-2">
                      {feedback}
                    </td>
                    <td className="pt-2">
                      {moment(createdAt).format("DD MMM YYYY")}
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerFeedBack;
