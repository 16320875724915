import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import GoogleLogo from "../../assets/images/google-logo.png";
import { useStyles } from "../FrontWebLogin/style";

function ThankYou() {
  const intl = useIntl();
  const backgroundClasses = useStyles();
  const history = useHistory();
  const showGoogle = localStorage.getItem("showGoogle") === "true";
  const googleLink = localStorage.getItem("googleLink") || "";
  const lastRatingJourny = localStorage.getItem("lastRatingJourny");

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push("/frontRating");
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="wrapper">
      <section className={`login-wrapper ${backgroundClasses.backgroundImg}`}>
        <div className={backgroundClasses.container}>
          <img src="/images/webRating/check.png" style={{ width: "10vw" }} alt="check" />
          <h3 className={backgroundClasses.thankyouText}>
            {intl.formatMessage({ id: "thank-you-for-your-feedback" })}
          </h3>

          {showGoogle && lastRatingJourny !== "neg" ? (
            <>
              <h2 className={backgroundClasses.googleText}>
                {intl.formatMessage({ id: "would-you-also-like-to-rate-us-on-google" })}
              </h2>

              <div className={backgroundClasses.googleReviewContainer}>
                <a href={googleLink} target="_blank" rel="noreferrer" className={backgroundClasses.googleReviewButton}>
                  <img src={GoogleLogo} alt="google-logo" width="50px" style={{ marginRight: "5px" }} />
                  {intl.formatMessage({ id: "rate-us-now" })}
                </a>
              </div>
            </>
          ) : null}
        </div>
        <img src="/images/webRating/powerbywhite.png" style={{ position: "absolute", bottom: 9 }} alt="powerby" />
      </section>
    </div>
  );
}

export default ThankYou;
