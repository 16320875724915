import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../components/question.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  addAnswer,
  addWriteIn,
  clearAnsweres,
} from "../../../../redux/survey/actions";
import { postData } from "../../../../components/FetchServicesWithoutJwtToken";
import { useIntl } from "react-intl";

const InventoryType = (props) => {
  const intl = useIntl();
  const { onSend } = props;
  // Destructuring props
  const { question, addAnswer_, survey_theme } = props;

  // State variables
  const [open, setOpen] = useState(false);

  // Function to handle radio button change
  const [productAnswers, setProductAnswers] = useState([]); // State to store multiple productAnswers
  const [productTextAnswers, setProductTextAnswers] = useState([]);

  // Function that handles onChange event of the TextField component
  const handleText = (text, product) => {
    // Regular expression to validate a number (with or without decimal)
    const value = text;

    // Update the productAnswers array with the new input value for the given productId
    const updatedAnswers = productAnswers?.filter(
      (ans) => ans.id !== product?._id
    ); // Remove the old answer for this productId if exists

    setProductAnswers([...updatedAnswers, { id: product?._id, value }]);

    const { serial_no } = product; // Destructure serial_no from the product

    // Update the productTextAnswers array with the new input value for the given serial_no
    const updatedTextAnswers = productTextAnswers?.filter(
      (ans) => ans.product !== serial_no
    ); // Remove the old answer for this serial_no if it exists

    setProductTextAnswers([
      ...updatedTextAnswers,
      { product: serial_no, value: value },
    ]);
  };

  const onSubmitAnswer = () => {
    if (question?.required && !productAnswers?.length) {
      toast.error(intl.formatMessage({ id: "this-question-is-required" }));
      return;
    } else {
      addAnswer_({
        id: question._id,
        value: productAnswers,
        type: "text",
      });
      onSend(productTextAnswers);
      setOpen(false);
    }
  };

  const onSkipQuestion = () => {
    onSend(productTextAnswers);
    setOpen(false);
  };

  const locationId = localStorage.getItem("location") || "";
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const getProductList = async () => {
    setLoading(true);
    const result = await postData("inventory/product-list-type-location", {
      location_id: locationId,
      product_type_id: question?.productType,
    });
    if (result?.success) {
      setProducts(result?.data || []);
    }
    setLoading(false);
  };
  useEffect(() => {
    getProductList();
  }, []);

  return (
    <>
      <div className="px-4 bg-white border-t border-gray-200">
        <div className="flex items-center space-x-3 h-18">
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            style={{ color: survey_theme?.primaryColor }}
            data-toggle="modal"
            data-target="#selectSkills"
          >
            <svg
              className="w-5 height-1 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
              <path
                fillRule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clipRule="evenodd"
              />
            </svg>
            {intl.formatMessage({ id: "select" })}
          </button>
          {!question?.required ? (
            <button
              onClick={() => onSkipQuestion()}
              type="button"
              className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            >
              {intl.formatMessage({ id: "skip" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {open ? (
        <div>
          <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="mobile-view-modal"
            id="mobile-view-modal"
          >
            <DialogTitle id="mobile-view-modal-title">
              <div className="modal-header">
                <h5 className="mr-12 text-lg font-bold truncate">
                  {intl.formatMessage({ id: "select" })}
                </h5>
                {/* close button */}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setOpen(false)}
                  aria-label="Close"
                >
                  <span className="sr-only">
                    {intl.formatMessage({ id: "close" })}
                  </span>
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </DialogTitle>

            <DialogContent id="mobile-view-modal-body">
              <div className="divide-y divide-gray-200">
                {!loading
                  ? products?.map((product, k) => {
                      const currentAnswer = productAnswers.find(
                        (ans) => ans.id === product?._id
                      ) || { value: "" };
                      return (
                        <div
                          key={k}
                          className="flex items-cente justify-between px-3 py-3 bg-white flex-column"
                        >
                          <label
                            htmlFor={`skill-${k}`}
                            className="flex-1 block text-base font-medium"
                          >
                            {product?.serial_no}
                          </label>
                          <input
                            type="number"
                            value={currentAnswer?.value}
                            onChange={(e) =>
                              handleText(e?.target?.value, product)
                            }
                            id="feedback"
                            placeholder={intl.formatMessage({ id: "write-here" })}
                            className="block w-full px-3 py-3 text-base text-black transition-all duration-200 bg-white border border-transparent shadow-lg"
                            autoComplete="off"
                            style={{ borderRadius: "6px" }}
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
            </DialogContent>
            <DialogActions id="mobile-view-modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  style={{
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => setOpen(false)}
                  className="flex items-center justify-center flex-1 w-full  text-base font-semibold text-gray-600 transition-all duration-200 bg-gray-300 rounded-full shadow-lg hover:text-white focus:text-white hover:bg-gray-600 focus:bg-gray-600"
                >
                  {intl.formatMessage({ id: "discard" })}
                </button>
                <button
                  style={{
                    backgroundColor: survey_theme?.primaryColor,
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => onSubmitAnswer()}
                  className="flex items-center justify-center flex-1 w-full text-base font-semibold text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                >
                  {intl.formatMessage({ id: "save" })}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
// Redux mapping function to map state to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connects the InventoryType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(InventoryType);
