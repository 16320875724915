import React from "react";
import { useCookies } from "react-cookie";
import { useIdleTimer } from "react-idle-timer";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import GoogleLogo from "../../../assets/images/google-logo.png";
import { resetFrontratingAnsweres } from "../../../redux/frontWebRating/actions";
import { saveDetails } from "../../apiHelpers/saveDetails";
import { useStyles } from "./styles";
import { useIntl } from "react-intl";

function GoogleQrPage(props) {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["rating"]);

  const { webRatingAnsweres, webRatingData, resetFrontratingAnsweres_ } = props;

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();
      let ratingCookie = cookies.rating;
      if (
        ratingCookie?.location !== webRatingAnsweres.location_id ||
        localStorage.getItem("is_frequent_rating")
      ) {
        const response = await saveDetails({
          ...webRatingAnsweres,
          page: 2,
          dropout_page: "google_qr",
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );
          resetFrontratingAnsweres_();
          history.push("/thankYou");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error(
          intl.formatMessage({ id: "you-have-already-submitted-rating" })
        );
      }
    },

    debounce: 500,
  });

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.headingBox}>
          <h1 className={classes.heading}>
            {intl.formatMessage({ id: "rate-us-on-google" })}
          </h1>
          <p className={classes.subHeading}>
            {/* You can scan this QR Code to rate us on Google */}
            {intl.formatMessage({ id: "you-can-follow-this-button-to-rate-us-on-google" })}
          </p>
        </div>

        <div className={classes.root}>
          <div className={classes.buttonContainer}>
            <a
              href={webRatingData?.googleReviewUrl}
              target="_blank"
              rel="noreferrer"
              className={classes.googleReviewButton}
            >
              <img
                src={GoogleLogo}
                alt="google-logo"
                width="30px"
                style={{ marginRight: "5px" }}
              />
              {intl.formatMessage({ id: "rate-us-now" })}
            </a>
          </div>
          {/* <div className={classes.qrContainer}>
            <img
              src={webRatingData?.googleQrImage}
              alt="google-qr"
              width="300px"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleQrPage);
