import React, { useEffect, useState } from "react";
import {
  addAnswer,
  addNote,
  addWriteIn,
} from "../../../../redux/survey/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import "rsuite/dist/styles/rsuite-default.css";
import "rsuite/dist/styles/rsuite-default.css";
import { postData } from "../../../helpers/fetch_services";
import { useIntl } from "react-intl";
//  style for the text field component
const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    borderBlockColor: theme.palette.primary.light,
  },
  input1: {
    borderColor: theme.palette.primary.light,
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

function InventoryType(props) {
  const intl = useIntl();
  // Destructing  props for easy access to properties
  const { question, answeres, addAnswer_, isGreyOut, styles } = props;
  const theme = useTheme(); // Accessing the current theme
  const isMob = useMediaQuery(theme.breakpoints.down("sm")); // Check if device is mobile or not
  const classes = useStyles();

  const [answers, setAnswers] = useState([]); // State to store multiple answers

  // Function that handles onChange event of the TextField component
  const handleText = (text, productId) => {
    // Regular expression to validate a number (with or without decimal)
    const value = text.target.value ? text.target.value : null;

    // Update the answers array with the new input value for the given productId
    const updatedAnswers = answers?.filter((ans) => ans.id !== productId); // Remove the old answer for this productId if exists

    setAnswers([...updatedAnswers, { id: productId, value }]);

    // Dispatch the updated answers array to Redux
    addAnswer_({
      id: question._id,
      value: [...updatedAnswers, { id: productId, value }],
      type: "text",
    });
  };

  const locationId = localStorage.getItem("location") || "";
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const getProductList = async () => {
    setLoading(true);
    const result = await postData("inventory/product-list-type-location", {
      location_id: locationId,
      product_type_id: question?.productType,
    });
    if (result?.success) {
      setProducts(result?.data || []);
    }
    setLoading(false);
  };
  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    if (question._id in answeres) {
      let prevAnswer = answeres[question._id] ?? { value: [] };
      setAnswers(prevAnswer.value);
    } else {
      setAnswers([]);
    }
  }, [question._id in answeres]);

  return (
    <>
      <Box style={{ cursor: isGreyOut && "not-allowed" }}>
        {!loading ? (
          products?.length ? (
            products?.map((product) => {
              const currentAnswer = answers.find(
                (ans) => ans.id === product?._id
              ) || { value: "" };
              return (
                <div className="d-flex align-items-center">
                  <div className="mr-3" style={{ width: "10%" }}>
                    {product?.serial_no || ""}
                  </div>
                  <div className="mb-2" style={{ width: "90%" }}>
                    <TextField
                      disabled={isGreyOut}
                      style={styles}
                      className={classes.textfield}
                      id="outlined-basic"
                      value={currentAnswer?.value}
                      onChange={(text) => handleText(text, product?._id)}
                      size={isMob ? "small" : "medium"}
                      label={intl.formatMessage({ id: "enter-value" })}
                      placeholder={intl.formatMessage({ id: "enter-value" })}
                      variant="outlined"
                      InputProps={{ classes: { input: classes.input1 } }}
                      InputLabelProps={{
                        style: {
                          color: "darkgray",
                          fontSize: isMob && "14px",
                        },
                      }}
                      type="number"
                    />
                  </div>
                </div>
              );
            })
          ) : (
            ""
          )
        ) : (
          <div>{intl.formatMessage({ id: "getting-product-list" })}</div>
        )}
      </Box>
    </>
  );
}
// Function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Function that binds the dispatch to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
  };
};
// Connect Redux Store with this component
export default connect(mapStateToProps, mapDispatchToProps)(InventoryType);
