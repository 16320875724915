import React, { useState } from "react";
import "./App.css";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Route } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import { FrontWebProtectedRoute } from "./Survey/helpers/authHelper";
import { getTheme } from "./theme";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import WebRatingHome from "./webRating/Index";
import Negativefeedback from "./webRating/Negativefeedback";
import Negativefeedbackform from "./webRating/Negativefeedbackform";
import Positivefeedback1 from "./webRating/Positivefeedback1";
import Positivefeedback2 from "./webRating/Positivefeedback2";
import PositivefeedbackForm from "./webRating/PositivefeedbackForm";

import ThankyouPage from "./webRating/ThankyouPage";
import SurveyThankYou from "../src/Survey/pages/SurveyThankYou";

import MobileRatingHome from "./mobileRating/FrontMobileHome";

import FrontCustomerAudit from "./FrontCustomerAudit/FrontCustomerAudit";
import FeedbackOnceAlert from "./FrontCustomerAudit/FeedbackOnceAlert";

// import Dashboard from "./components/Home/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import ClientServeyMain from "./ClientSurvey/ClientSurveyMain";
import NotFoundPage from "./Common/NotFoundPage";
import PrivacyPolicyPage from "./Common/PrivacyPolicyPage";

import ReportPage from "./ReportPage/ReportPage";
import NewReport from "./ReportPage/NewReport";

//Employee Feedback Files
import EmployeeRatingHome from "./EmployeeWebRating/Home";
import EmployeeFeedbackStep1 from "./EmployeeWebRating/EmployeeFeedbackStep1";
import EmployeeFeedbackStep2 from "./EmployeeWebRating/EmployeeFeedbackStep2";
import EmployeeFeedbackStep3 from "./EmployeeWebRating/EmployeeFeedbackStep3";
import EmployeeFeedbackThankyou from "./EmployeeWebRating/ThankyouPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Employee Feedback Files
import "./assets/css/AdminpanelGeneralStyles.css";
import Survey from "./Survey";
import FrontWebLogin from "./webRatingNew/FrontWebLogin";
import FrontRating from "./webRatingNew/FrontRating";

import PositiveJourny from "./webRatingNew/PositiveJourny";
import NegativeJourny from "./webRatingNew/NegativeJourny";
import ThankYou from "./webRatingNew/ThankYou";
import RatingLocations from "./webRatingNew/RatingLocations";
import SameDaySurvey from "./Survey/pages/SameDaySurvey";
import ThankYouModal from "./webRatingNew/CommonComponents/ThankYouModal";
import MobileRatingThankYou from "./mobileRating/MobileRatingThankYou";
import MobileSurvey from "./mobileSurvey";
import { IntlProvider } from "react-intl";
import AppLocale, { getLocale } from "./lang";

export const ThemeUpdateContext = React.createContext();

function App(props) {
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [highlightColor, setHighlightColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [currentLocale, setCurrentLocale] = useState(getLocale());

  // Add effect to listen for localStorage changes
  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentLocale(getLocale());
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const setThemeData = (
    data = {
      primaryColor: "",
      secondaryColor: "",
      backgroundColor: "",
      highlightColor: "",
      fontColor: "",
    }
  ) => {
    setPrimaryColor(data.primaryColor);
    setSecondaryColor(data.secondaryColor);
    setBackgroundColor(data.backgroundColor);
    setHighlightColor(data.highlightColor);
    setFontColor(data.fontColor);
  };

  const currentAppLocale = AppLocale[currentLocale];

  return (
    <React.Fragment>
      <CookiesProvider>
        <ThemeUpdateContext.Provider
          value={{ changeTheme: (data) => setThemeData(data) }}
        >
          <ThemeProvider
            theme={getTheme(
              primaryColor,
              secondaryColor,
              backgroundColor,
              highlightColor,
              fontColor
            )}
          >
            <Provider store={store}>
              <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
                defaultLocale="en"
              >
                <PersistGate persistor={persistor}>
                  <ToastContainer />
                  <BrowserRouter>
                    <Route path="/" exact component={FrontWebLogin}></Route>
                    {/* <Route path="/" exact component={FrontLogin}></Route> */}
                    <FrontWebProtectedRoute
                      path="/frontRating"
                      exact
                      component={FrontRating}
                    />
                    <FrontWebProtectedRoute
                      path="/positiveJourny"
                      exact
                      component={PositiveJourny}
                    ></FrontWebProtectedRoute>
                    <FrontWebProtectedRoute
                      path="/negativeJourny"
                      exact
                      component={NegativeJourny}
                    ></FrontWebProtectedRoute>
                    <FrontWebProtectedRoute
                      path="/thankYou"
                      exact
                      component={ThankYou}
                    ></FrontWebProtectedRoute>
                    <Route
                      path="/surveyThankYou"
                      exact
                      component={SurveyThankYou}
                    ></Route>
                    <Route
                      path="/sameDaySurvey"
                      exact
                      component={SameDaySurvey}
                    ></Route>

                    <Route
                      path="/ratingLocations"
                      exact
                      component={RatingLocations}
                    ></Route>
                    <Route
                      path="/front/mobile-home"
                      exact
                      component={MobileRatingHome}
                    ></Route>
                    <Route
                      path="/front/home"
                      exact
                      component={WebRatingHome}
                    ></Route>

                    <Route
                      path="/front/customer-audit/:id"
                      exact
                      component={FrontCustomerAudit}
                    ></Route>
                    <Route
                      path="/front/customer-audit-form-already-submited"
                      exact
                      component={FeedbackOnceAlert}
                    ></Route>

                    <Route
                      path="/front/Ratting/negative-feedback-step-1"
                      exact
                      component={Negativefeedback}
                    ></Route>
                    <Route
                      path="/front/Ratting/negative-feedback-step-2"
                      exact
                      component={Negativefeedbackform}
                    ></Route>
                    <Route
                      path="/front/Ratting/positive-feedback-step-1"
                      exact
                      component={Positivefeedback1}
                    ></Route>
                    <Route
                      path="/front/Ratting/positive-feedback-step-2"
                      exact
                      component={Positivefeedback2}
                    ></Route>
                    <Route
                      path="/front/Ratting/positive-feedback-step-3"
                      exact
                      component={PositivefeedbackForm}
                    ></Route>
                    <Route
                      path="/front/Ratting/thank-you-for-feedback"
                      exact
                      component={ThankyouPage}
                    ></Route>
                    <Route
                      path="/front/Ratting/new-thank-you-page"
                      exact
                      component={MobileRatingThankYou}
                    ></Route>

                    <Route
                      path="/client-survey"
                      exact
                      component={ClientServeyMain}
                    ></Route>
                    <Route
                      path="/404-not-found"
                      exact
                      component={NotFoundPage}
                    ></Route>

                    <Route
                      path="/old-reporting-page"
                      exact
                      component={ReportPage}
                    ></Route>
                    <Route
                      path="/reporting-page"
                      exact
                      component={NewReport}
                    ></Route>

                    {/*empoyee feedback routes  */}
                    <Route
                      path="/employee-feedback-home"
                      exact
                      component={EmployeeRatingHome}
                    ></Route>
                    <Route
                      path="/employee-feedback-step-1"
                      exact
                      component={EmployeeFeedbackStep1}
                    ></Route>
                    <Route
                      path="/employee-feedback-step-2"
                      exact
                      component={EmployeeFeedbackStep2}
                    ></Route>
                    <Route
                      path="/employee-feedback-step-3"
                      exact
                      component={EmployeeFeedbackStep3}
                    ></Route>
                    <Route
                      path="/employee-feedback-thank-you"
                      exact
                      component={EmployeeFeedbackThankyou}
                    ></Route>
                    <Route
                      path="/privacy-policy"
                      exact
                      component={PrivacyPolicyPage}
                    ></Route>
                    {/*empoyee feedback routes  */}
                    <Route
                      path="/survey"
                      render={(props) => <Survey {...props} />}
                    ></Route>
                    <Route
                      path="/mobile-survey"
                      render={(props) => <MobileSurvey {...props} />}
                    ></Route>
                  </BrowserRouter>
                </PersistGate>
              </IntlProvider>
            </Provider>
          </ThemeProvider>
        </ThemeUpdateContext.Provider>
      </CookiesProvider>
    </React.Fragment>
  );
}

export default App;
// For all Api
// -- If api response with false status  still it it returns null data AND data add in listing.
// -- After edit, numbering of records should not change.
// Currently if i edit 1st record in list, then after edit it comes to last
